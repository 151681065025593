import React/*, { useState }*/ from 'react';
import { Create, Edit, SimpleForm, required, TextInput,
//  List, Datagrid, TextField, EditButton
  } from 'react-admin';
//import Move from '../lib/Move';
import { getGlobalData } from '../lib/globalData';

const ListForm = (props) => {
/*  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }*/
  return (
  <>
    <iframe title="Events" src={"https://"+getGlobalData('token')+".emkembed22.alixon.ch?page=seiten-100_adressen-550_events"} style={{height:'800px'}} />
  </>
  )
}

const CEForm = (props) => {
  return (
    <SimpleForm {...props}>
        <TextInput source="bezeichnung" validate={required()} />
    </SimpleForm>
  )
};

const eventEvent = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),

  create: (props) => (
    <Create {...props} title={'ew.event.event.titleCreate'} redirect="list">
      <CEForm {...props}/>
    </Create>
  ),

  edit: (props) => (
    <Edit title={'ew.event.event.titleEdit'} mutationMode="optimistic" {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default eventEvent
