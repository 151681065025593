import React from 'react';
import { useListContext, useCreateContext, useTranslate, useNotify, CreateBase, SimpleForm, Toolbar, SaveButton, ReferenceInput, AutocompleteInput } from 'react-admin';
import { DialogContent } from '@mui/material';
 
const VerknuepfCreateForm = ({count, manyIds}) => {
  const { filterValues } = useListContext();
  const { save } = useCreateContext();
  
  const transformAction = (filterValues) => data => ({
    ...data, filter: filterValues
  });
  
  const VerknuepfToolbar = ({count}) => {
    const ta = useTranslate();      
    return(
      <Toolbar>
          <SaveButton type="button" label={ta('ew.act.verknuepf.exec', {smart_count:count})} transform={transformAction(filterValues)} />
      </Toolbar>
    );
  };
  return (
    <SimpleForm onSubmit={save} toolbar={<VerknuepfToolbar count={count} />}>
      <ReferenceInput source="id_bezirk" reference="setEinheit" perPage={1000}>
          <AutocompleteInput optionText="bezeichnung" fullWidth />
      </ReferenceInput>
    </SimpleForm>
  )  
}

const ActVerknuepf = (props) => {
  const ta = useTranslate();      
  const notify = useNotify();
  const {count, manyIds, closeDialog } = props;

  const onSuccess = (data) => {
    if (data.id > 0) {
      notify(ta('ew.act.verknuepf.done'));
      closeDialog();
    }
  };

  return (
    <DialogContent>
      <div style={{fontWeight:'bold', fontSize:'1.2rem'}}>{ta('ew.act.verknuepf.titel')}</div>
      <p>{ta('ew.act.verknuepf.text')}</p>
      <CreateBase resource="addrAktion/verknuepfen" record={{id: manyIds}} mutationOptions={{ onSuccess }} >
        <VerknuepfCreateForm count={count} manyIds={manyIds} />
      </CreateBase>
    </DialogContent>
  );
}

export default ActVerknuepf
