import React, { useState } from 'react';
import { useRedirect, useRefresh, useNotify, Confirm, useUpdate, useDataProvider, useListContext, useTranslate } from 'react-admin';
import { checkLevel, getGlobalData, getInfoValue } from '../lib/globalData';
import SubMenu from '../lib/SubMenu';
import ActEdit from './ActEdit';
import ActPrint from './ActPrint';
import ActMail from './ActMail';
import ActHistory from './ActHistory';
import ActMutation from './ActMutation';
import ActHandlung from './ActHandlung';
import ActMsgAdm from './ActMsgAdm';
import ActCompare from './ActCompare';
import ActBirthday from './ActBirthday';
import ActVerknuepf from './ActVerknuepf';
import ActSperren from './ActSperren';
import ActVerknDisp from './ActVerknDisp';

import { Button, Dialog, DialogTitle } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Close, Delete, DeleteForever, RestoreFromTrash, Email, Print, History, TransferWithinAStation, ImportContacts
  ,Send, Compare, Cake, Lock, ArrowRightAlt, AddLink, Link, ContentPaste } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';

const ActionsDialog = (props) => {
  const { actDialogState, closeActionDialog } = props;
  const ta = useTranslate();
  const [dialog, setDialog] = useState(false);
  const [openConf1, setOpenConf1] = useState(false);
  const [openConf2, setOpenConf2] = useState(false);
  const [submenu, setSubMenu] = useState(false);
  const [update, { isLoading }] = useUpdate();  
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { filterValues } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();

//  console.log(['ds',actDialogState])
//  console.log(['dialog',dialog])
  
  var manyIds = 0;
  var target = '';
  var count = 0;
  var infoEmpf = false
  var eigen = true;
  var mitteilung = true;
  const info = getGlobalData('info');
  if (actDialogState.sel.total) {
    manyIds = -1;
    count = actDialogState.sel.total;
    target = ta('ew.act.target.all', {smart_count: count});
  }
  else if (Array.isArray(actDialogState.sel)) {
    manyIds = actDialogState.sel.join();
    target = 
    count = actDialogState.sel.length;
    target = ta('ew.act.target.sel', {smart_count: count});
  } 
  else if (typeof actDialogState.sel.id==='number') {
    manyIds = actDialogState.sel.id;
    target = actDialogState.sel.vorname + ' ' + actDialogState.sel.name;
    infoEmpf = actDialogState.sel.info;
    console.log(actDialogState.sel.id_bezirk+'==='+info.id_bezirk)
    eigen = actDialogState.sel.id_bezirk===info.id_bezirk;
    if (actDialogState.sel.id_bezirk===info.id_bezirk) mitteilung = false;
    count = 1;
  } 

//, justifyContent:'left', textAlign:'left'  
  const ActionButton = (props) => {
    const ta = useTranslate();
    return (
      <Grid item xs={4}>
        <Button variant="outlined" sx={{width:'100%', justifyContent:'left', textAlign:'left', textTransform:'none', whiteSpace: 'nowrap'}} onClick={props.onClick}><props.icon sx={{marginRight:'5px',top:'-1px', position:'relative'}} /> {ta(props.label)}</Button>
      </Grid>
    )
  }
  
  const closeDialog = () => {
    setDialog(false);
    setSubMenu(false);
  }
  const closeSubMenu = () => {
    setSubMenu(false);
  }
  const clickMenu = (action) => () => {
    closeActionDialog();
    setDialog(action);
  }
  const clickSubMenu = ({action, id}) => {
    closeSubMenu();
    closeActionDialog();
    if (action==='clipboard') {
      dataProvider.create('addrClipboard', {data: {action:id, filter:filterValues, id:manyIds}}).then(response => {
        setSubMenu(false);
        notify('Clipboard aktion asgeführt.');
        refresh();
      });
    }
    else {
      setDialog(action + '#' + id);
    }
  }
  
  const acEdit = () => { 
    if (count===1) {
      redirect('/addrPerson/'+manyIds);  
    }
    else {
      closeActionDialog();
      setDialog('edit');
    }
  }
  const SetInaktiv = (v) => {
    update('addrPerson/multiset', {id: manyIds, data: {feld:'setInaktiv', wert:v, filter:filterValues}},
      {
        mutationMode:'pessimistic',
        onSuccess: () => {
          setOpenConf1(false);
          closeActionDialog();
          refresh();
        },
        onError: (error) => {
          console.log('error')
          notify(`Fehler beim speichern: ${error.message}`, { type: 'warning' });
        },
      }
    );
  }

  const SwitchBezirk = () => {
    console.log(['SwitchBezirk',manyIds])
    dataProvider.update('authSwitch', { id: parseInt(manyIds), data: {action: 'switchuserbez' }})
      .then(res  => {
        if (res.data.info) {
          localStorage.setItem('info', JSON.stringify(res.data.info));
        }
        window.location.href = '/';
      })
      .catch(response => {
        window.location.hash = '/login';
      });

  };
  
  const acDelete1 = () => { setOpenConf1(true); }
  const acDelete2 = () => { setOpenConf2(true); }
  const acVerkndel = () => { SetInaktiv(2) }
  const handleCloseConf1 = () => setOpenConf1(false);
  const handleCloseConf2 = () => setOpenConf2(false);
  const handleConfirm1 = () => { SetInaktiv(1); };
  const acReactivate = () => { SetInaktiv(0); };
  const handleConfirm2 = () => {
    update('addrPerson/multidelete', {id: manyIds, data: {filter:filterValues}},
      {
        mutationMode:'pessimistic',
        onSuccess: () => {
          setOpenConf2(false);
          closeActionDialog();
          refresh();
        },
        onError: (error) => {
          console.log('error')
          notify(`Fehler beim speichern: ${error.message}`, { type: 'warning' });
        },
      }
    );
  };
  const acMessage = () => { 
    let from = getInfoValue('email');
    if (!from) from = 'ich';
    dataProvider.getList('addrPerson/mailaddr', { filter: {...filterValues, id: manyIds}, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(response => {
        if (response.data) {
          setSubMenu({title:'ew.act.nachricht.send', action:'mail', list:[
            {id:'html', text:'ew.act.nachricht.sendapp'},
            {id:'to', text:'ew.act.nachricht.sendto', href:"mailto:"+response.data.reduce((iv, cv) => iv===''?cv.id:iv+';'+cv.id, '')},
            {id:'bcc', text:'ew.act.nachricht.sendbcc', href:"mailto:"+from+"?bcc="+response.data.reduce((iv, cv) => iv===''?cv.id:iv+';'+cv.id, '')},
            {id:'cb', text: 'ew.act.nachricht.clipMail', clipboard:response.data.reduce((iv, cv) => iv===''?cv.id:(cv.id?iv+';'+cv.id:iv), '')},
            {id:'acb', text: 'ew.act.nachricht.clipAddr', clipboard:response.data.reduce((iv, cv) => iv===''?cv.addr:(cv.addr?iv+'\n'+cv.addr:iv), '')},
          ]})
        }
      });
  }
  // mailto:m.mustermann@domain.de?bcc=max.m@example.org
  const acClipboard = () => { 
    setSubMenu({title:'Zwischenablage', action:'clipboard', list:[
      {id:'add', text:'Personen hinzufügen'},
      {id:'del', text:'Personen entfernen'},
      {id:'empty', text:'Leeren'}
    ]})
  }

  const acPrint = () => { 
      closeActionDialog();
      setDialog('print');
  }
  const acDialog = (act) => () => { 
      closeActionDialog();
      setDialog(act);
  }
  const acMutation = () => { 
    setSubMenu({title:'Mutation', action:'mutation', list:[{id:'trans',text:'ew.act.mutationen.trans'},{id:'quit',text:'ew.act.mutationen.quit'},{id:'delete',text:'ew.act.mutationen.delete'},]})
  }
  
  const acHandlungen = () => { 
    setSubMenu({title:'ew.act.handlung.button', action:'handlung', list:[{id:'seg1',text:'ew.act.handlung.seg1'},{id:'tauf1',text:'ew.act.handlung.tauf1'},
      {id:'seg2',text:'ew.act.handlung.seg2'},{id:'tauf2',text:'ew.act.handlung.tauf2'},{id:'ehe',text:'ew.act.handlung.ehe'},
      {id:'bek',text:'ew.act.handlung.bek'},{id:'tod',text:'ew.act.handlung.tod'},]})
  }
  
  if (dialog) {
    const parts = dialog.split('#');
    const bereich = parts[0];
    const sub = parts[1];
    return  (
      <Dialog onClose={closeDialog} open={dialog!==false} maxWidth="md" fullWidth={bereich==='msgadm'?true:false} >
        <Close onClick={closeDialog} sx={{ position:'absolute', right:'8px', top:'8px', cursor:'pointer'}} />
    {dialog==='edit' && <ActEdit count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {dialog==='print' && <ActPrint count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {dialog==='mail#html' && <ActMail count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {dialog==='history' && <ActHistory count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {bereich==='mutation' && <ActMutation sub={sub} count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {bereich==='handlung' && <ActHandlung sub={sub} count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {bereich==='msgadm' && <ActMsgAdm count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {bereich==='compare' && <ActCompare count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {bereich==='birthday' && <ActBirthday sub={sub} count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {bereich==='sperren' && <ActSperren sub={sub} count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {bereich==='verknuepf' && <ActVerknuepf sub={sub} count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
    {bereich==='verkndisp' && <ActVerknDisp sub={sub} count={count} manyIds={manyIds} closeDialog={closeDialog} fv={filterValues} /> }
      </Dialog>
    )
  }

  if (actDialogState.disp===false) return null;
  const readonly = getInfoValue('readonly');
  const is_admin = checkLevel({maxlevel:15});
  const editrecht = !infoEmpf && !readonly || is_admin;
  return (
    <>
      <Dialog onClose={closeActionDialog} open={actDialogState.ids!==false} fullWidth maxWidth="md" >
        <Close onClick={closeActionDialog} sx={{ position:'absolute', right:'8px', top:'8px', cursor:'pointer'}} />
        <DialogTitle>{ta('ew.act.target.titel')} {target}</DialogTitle>
        <Grid container spacing={2} sx={{padding:'0 24px 16px 24px'}} >
          <ActionButton icon={EditIcon} label="ew.act.bearbeiten.button" onClick={acEdit} />
          <ActionButton icon={ContentPaste} label="Zwischenablage" onClick={acClipboard} />
          {is_admin && <ActionButton icon={Delete} label="ew.act.loeschen.button" onClick={acDelete1} /> }
          {is_admin && <ActionButton icon={DeleteForever} label="ew.act.loeschen2.button" onClick={acDelete2} />}
          {is_admin && <ActionButton icon={RestoreFromTrash} label="ew.act.reaktivieren.button" onClick={acReactivate} />}
          <ActionButton icon={Email} label="ew.act.nachricht.button" onClick={acMessage} />
          <ActionButton icon={Print} label="ew.act.drucken.button" onClick={acPrint} />
          {editrecht && <ActionButton icon={History} label="ew.act.history.button" onClick={acDialog('history')} />}
          {editrecht && <ActionButton icon={TransferWithinAStation} label="ew.act.mutationen.button" onClick={acMutation} />}
          {editrecht && <ActionButton icon={ImportContacts} label="ew.act.handlungen.button" onClick={acHandlungen} />}
          {mitteilung && <ActionButton icon={Send} label="ew.act.mitteilung.button" onClick={clickMenu('msgadm')} />}
          {is_admin && <ActionButton icon={Compare} label="ew.act.vergleichen.button" onClick={clickMenu('compare')} /> }
{/*          <ActionButton icon={Cake} label="ew.act.geburtstag.button" onClick={clickMenu('birthday')} />*/}
          {is_admin && <ActionButton icon={Lock} label="ew.act.sperren.button" onClick={clickMenu('sperren')} />}
          {is_admin && <ActionButton icon={ArrowRightAlt} label="ew.act.zueinheit.button" onClick={SwitchBezirk} />}
          {is_admin && <ActionButton icon={AddLink} label="ew.act.verknüpfen.button" onClick={clickMenu('verknuepf')} />}
          {is_admin && <ActionButton icon={Link} label="ew.act.dispverkn.button" onClick={clickMenu('verkndisp')} />}
          {infoEmpf && <ActionButton icon={Link} label="ew.act.delverkn.button" onClick={acVerkndel} />}
        </Grid>
      </Dialog>  
      <Confirm
        isOpen={openConf1}
        loading={isLoading}
        title={target+" "+ta('ew.act.loeschen.titel')}
        content="Wirklich den Status auf inaktiv setzten?"
        onConfirm={handleConfirm1}
        onClose={handleCloseConf1}
      />
      <Confirm
        isOpen={openConf2}
        loading={isLoading}
        title={target+" "+ta('ew.act.loeschen2.titel')}
        content="Person wird gelöscht und kann nicht wiederhergestellt werden!"
        onConfirm={handleConfirm2}
        onClose={handleCloseConf2}
      />
      <SubMenu menu={submenu} close={closeSubMenu} click={clickSubMenu} />
    </>
  )
}

export default ActionsDialog;