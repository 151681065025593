import React from 'react';
import { useListContext, useCreateContext, useTranslate, useLocaleState, useNotify, CreateBase, SimpleForm, Toolbar, SaveButton, SelectInput } from 'react-admin';
import SelectSimpleInput from '../lib/SelectSimpleInput';
import { DialogContent } from '@mui/material';
import moment from 'moment';
 
const BirthdayCreateForm = ({count, manyIds}) => {
  const { filterValues } = useListContext();
  const { save } = useCreateContext();
  
  const transformAction = (filterValues) => data => ({
    ...data, filter: filterValues
  });
  
  const BirthdayToolbar = ({count}) => {
    const ta = useTranslate();      
    return(
      <Toolbar>
          <SaveButton type="button" label={ta('ew.act.birthday.exec', {smart_count:count})} transform={transformAction(filterValues)} />
      </Toolbar>
    );
  };
  
  const [locale] = useLocaleState();
  require('moment/locale/de');
  require('moment/locale/fr');
  moment.locale(locale);
  const monate=[];
  for (var i=0;i<12;i++) {
    monate.push({id:i, name:moment().month(i).format('MMMM')});
  }
  const jahre=[];
  for (i=-1;i<5;i++) {
    jahre.push({id:i, name:moment().get('year')+i});
  }
  
  return (
    <SimpleForm onSubmit={save} toolbar={<BirthdayToolbar count={count} />}>
      <SelectSimpleInput source="typ" simpchoices="m|ew.act.birthday.typ.m;j|ew.act.birthday.typ.j;s|ew.act.birthday.typ.s;r|ew.act.birthday.typ.r" />
      <SelectInput source="month" choices={monate} />
      <SelectInput source="year" choices={jahre} />
    </SimpleForm>
  )  
}

const ActBirthday = (props) => {
  const ta = useTranslate();      
  const notify = useNotify();
  const {count, manyIds, closeDialog } = props;

  const onSuccess = (data) => {
    notify(data.message);
    if (data.id > 0) {
      closeDialog();
    }
  };

  return (
    <DialogContent>
      <div style={{fontWeight:'bold', fontSize:'1.2rem'}}>{ta('ew.act.birthday.titel')}</div>
      <CreateBase resource="addBirthday" record={{id: manyIds}} mutationOptions={{ onSuccess }} >
        <BirthdayCreateForm count={count} manyIds={manyIds} />
      </CreateBase>
    </DialogContent>
  );
}

export default ActBirthday
