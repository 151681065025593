import React, { useState, useEffect } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { DialogContent } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DialogTitle from '@mui/material/DialogTitle';

 
const ActCompare = ({ count, manyIds, order, dublette }) => {
  const ta = useTranslate();      
  const [ personen, setPersonen ] = useState([]);
  const [ felder, setFelder ] = useState([]);
  const dataProvider = useDataProvider();
  const sort = order?order:{};
  
  const nodisp = {id:true, id_bezirk:true, id_gemeinde:true, id_haushalt:true, id_haushalt2:true};
  const choices = {
    geschlecht: {1:'glob.choices.weiblich',2:'glob.choices.maennlich'},
    id_status: {1:'glob.choices.stGetauft',2:'glob.choices.stBekennend',3:'glob.choices.stFreund',7:'glob.choices.stKind',5:'glob.choices.stGruppe',
      9:'glob.choices.stAusgetreten', 11:'glob.choices.stGast', 13:'glob.choices.stAssoziert', 4:'--'}
  }

  useEffect(() => {
    dataProvider.getList('setFelder', { id: 0, filter: { }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(response => { if (response.data) { setFelder(response.data.reduce((obj,f)=>({...obj, ['frei_'+f.id]:f.bezeichnung}), {})) }});
  }, [dataProvider]);
  
  useEffect(() => {
    if (manyIds) {
      dataProvider.getList('addrPerson/gruppentext', { ids: 0, filter: { id: String(manyIds).split(','), active:-1 }, sort: sort, pagination: { page: 1, perPage: 999 },})
        .then(response => { if (response.data) { setPersonen(response.data) }});
    }
  }, [dataProvider, manyIds]);
  
  if (!personen || personen.length < 1 || !felder) return null;
  
  return (
    <DialogContent>
      <DialogTitle>{ta('ew.act.compare.titel', {smart_count: count})}</DialogTitle>
      <Table size="small">
{dublette && 
        <TableHead>
          <TableRow>
            <TableCell></TableCell> 
            <TableCell>Person A</TableCell> 
            <TableCell>Person B</TableCell> 
          </TableRow>
        </TableHead>
}
        <TableBody>
  {Object.keys(personen[0]).map((k, x)=> {
    if (nodisp[k]) return null;
    return (
          <TableRow key={k} className={k} sx={{verticalAlign:'top', '&:nth-of-type(odd)':{backgroundColor:'#f8f8f8'}}}>
            <TableCell>
              {k.substr(0,5)==='frei_'?felder[k]:ta('resources.addrPerson.fields.'+k)}
            </TableCell>
    {personen.map((p, i)=> {
      let t = p[k];
      if (t===true) {
        t = 'x';
      }
      else if (choices[k]) {
        t = choices[k][t];
        if (t && t.indexOf('.')>-1) {
          t = ta(t);
        }
      }
      if (k==='gruppentext') {
        if (t) {
          t = t.split('|').map((gr)=>(<div>{gr}</div>));
        }
      }
      return (
            <TableCell key={i}>
              {t}
            </TableCell>
    )})}
          </TableRow>
  )})}    
        </TableBody>
      </Table>
    </DialogContent>
  );
}

export default ActCompare
