import * as React from 'react';
import { useState } from 'react';
import { apiPath } from './isTest';
import dataProvider from './dataProvider';
import { useLogin, useNotify, Login } from 'react-admin';
import { Card, CardContent, Button, TextField, Box, Stack } from '@mui/material';

const MyLoginPage = ({ theme }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [tokendata, setTokendata] = useState({});
    const [token, setToken] = useState('');
    const [otperror, setOtpError] = useState(false);
    const [otpConfirm, setOtpConfirm] = useState('');
    const [yubico, setYubico] = useState('');
    const [otp, setOtp] = useState('');
    const login = useLogin();
    const notify = useNotify();

    const handleAbbrechen = e => {
      setTokendata({}); 
    }
      

    const ActivateTOTP = () => {
      dataProvider.update('authToken/activateotp', { id: 1, data: {otp:otpConfirm, token:token} })
         .then(({data}) => {
            setOtpError(data.id!==1);
            if(data.id===1) {
              login({ token: token }).catch((res) => {
                notify(res);
              })
            }
          }
        )
    }
    
    const ActivateYubico = () => {
      dataProvider.update('authToken/activatyubico', { id: 1, data: {yubico:yubico, token:token} })
         .then(({data}) => {
          if(data.id===1) {
            login({ token: token }).catch((res) => {
              notify(res);
            })
          }
        })
    }

    const userkeypress = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        e.preventDefault();
        if (e.target.name==='password') {
          handleWeiter(e);  
        }
        else if (e.target.name==='username') {
          const form = e.target.form;
          const index = [...form].indexOf(e.target);
          console.log(e.target.name)
          form[index + 2].focus();
        }
      }
    }
    const otpkeypress = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        e.preventDefault();
        handleSubmit(e);  
      }
    }
    const handleWeiter = e => {
      const request = new Request(apiPath+'authToken', {
        method: 'PUT',
        body: JSON.stringify({ username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
      return fetch(request)
        .then(response => {
          if (response.ok) {
            return response.json().then(json => {
              setTokendata(json);
              setToken(json.token);
            }).catch(err => {
              notify('token:no JSON');
            });

          } else {
            return response.json().catch(err => {
              notify(response.statusText);
            }).then(json => {
              notify(json.message); 
            });
          }
        });
    }
    
    const handleSubmit = e => {
        e.preventDefault();
        // will call authProvider.login({ username, password })
        login({ token, otp }).catch((res) => {
            notify(res)
          }
        );
    };
    
    return (
      <Login>
        
        <form>
          <Card>
            <CardContent>
              <div style={{padding: '10px 0 20px 0'}}>
                <img src="logo2_de.svg" alt="EMK Logo" style={{height: '115px'}}/>
              </div>
        {!tokendata.challange &&
              <>
                <div>
                  <TextField
                    label="Benutzername oder E-Mail"
                    fullWidth
                    name="username"
                    value={username}
                    autoFocus 
                    onChange={e => setUsername(e.target.value)}
                    onKeyPress ={userkeypress}
                  />
                </div>
                <div>
                  <TextField
                    label="Passwort"
                    fullWidth
                    name="password"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onKeyPress ={userkeypress}
                  />
                </div>
                <div style={{marginTop: '16px'}}>
                  <Button variant="contained" onClick={handleWeiter}>Weiter</Button>
                </div>
              </>
}
        {parseInt(tokendata.challange) > 0 &&
              <>  
                <p>
                  Benutzername / E-Mail:<br/>
                  <b>{username}</b>
                </p>
          
                <div>
                  <TextField
                    label="2-Faktor"
                    fullWidth
                    name="otp"
                    value={otp}
                    autoFocus 
                    onChange={e => setOtp(e.target.value)}
                    onKeyPress ={otpkeypress}
                  />
                </div>

                <p style={{fontSize:'0.8em'}}>
                  (falls Codkarte: Position <b>{tokendata.challange}</b>)
                </p>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                  <Button variant="contained" onClick={handleSubmit}>Anmelden</Button>
                  <Button variant="text" onClick={handleAbbrechen}>Abbrechen</Button>
                </Box>
              </>
        }
        {parseInt(tokendata.challange) < 0 &&
          <>  
            <h3>2-Faktor registrieren</h3>
            <h3>Authenticator</h3>
            <Stack direction="row" alignItems="center">
            <img src={tokendata.otpcode} style={{marginBottom:"1em"}} />
              <Stack gap={2}>
                <TextField error={otperror} label="Code aus APP" size="small" onChange={e=>setOtpConfirm(e.target.value)} InputLabelProps={{ shrink: true }} />
                <Button variant="contained" onClick={e=>ActivateTOTP()} >aktivieren</Button>
              </Stack>
            </Stack>
            <h3>Yubico</h3>
            <Stack direction="row" alignItems="center" gap={2}>
              <TextField value={yubico} label="Yubico hier auslösen" onChange={e=>setYubico(e.target.value.substr(0,12))} />
              <Button variant="contained" onClick={()=>ActivateYubico()} >aktivieren</Button>
            </Stack>
          </>
        }
        </CardContent>
          </Card>
        </form>
      </Login>
    );
};

export default MyLoginPage;