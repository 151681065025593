import { Admin, AppBar, LocalesMenuButton, Layout, resolveBrowserLocale, 
  CustomRoutes, 
  Resource, defaultTheme, MenuItemLink, Logout, UserMenu } from 'react-admin';
import { Route } from 'react-router-dom';
import { getGlobalData, mergeDeep } from './lib/globalData';
import isTest from './lib/isTest';
import { Typography } from '@mui/material';
import { Face } from '@mui/icons-material';
import dataProvider from './lib/dataProvider';
import authProvider from './lib/authProvider';
import { MyMenu } from './lib/MyMenu';
import MyLoginPage from './lib/MyLoginPage';
import SwitchBezirk from './lib/SwitchBezirk';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import ra_de from 'ra-language-german';
import ra_fr from 'ra-language-french';
import ra_en from 'ra-language-english';
import languageDe from './language-de';
import languageFr from './language-fr';
import languageEn from './language-en';

import addrPerson from './addr/person'
import addrForm from './addr/form'
import addrFilter from './addr/filter'
import addrAmt from './addr/amt'
import addrActionhist from './addr/actionhist'
import addrAmthist from './addr/amthist'
import addrHistory from './addr/history'

import eventEvent from './event/event'

import authProfil from './auth/profil'
import authUser from './auth/user'

import genMailhist from './gen/mailhist'
import genMailvor from './gen/mailvor'
import genDubletten from './gen/dubletten'
import genVersand from './gen/versand'

import setFelder from './set/felder'
import setGruppe from './set/gruppe'
import setGemeinde from './set/gemeinde'
import SetKassier from './set/kassier'
import setAmt from './set/amt'
import setEinheit from './set/einheit'
import setSperr from './set/sperr'
import setImport from './set/import'

import './App.css';

const de = mergeDeep(ra_de, languageDe);
const fr = mergeDeep(ra_fr, languageFr);
const en = mergeDeep(ra_en, languageEn);
const translations = { de, fr, en };
var lang = resolveBrowserLocale('de');
if (lang!=='fr' || lang!=='en') {
  lang = 'de';
}
export const i18nProvider = polyglotI18nProvider(locale => translations[locale], lang);

const MyUserMenu = () => (
  <UserMenu>
    <MenuItemLink to="/authProfil/1" primaryText="Mein Profil" leftIcon={<Face />}/>
    <Logout />
  </UserMenu>
)

export const MyAppBar = () => {
  const info = getGlobalData('info');
  
  return (
    <AppBar sx={{backgroundColor:isTest?'#FF0000':'#aed461'}} userMenu={<MyUserMenu />}>
        <Typography flex="1" variant="h6" id="react-admin-title"/>
        <Typography flex="1" variant="h6">{info.bezirk}</Typography>
        {isTest && <Typography flex="1"> bez_id:{info.bezirk_id} / lvl: {info.level} / uid: {info.rootuser?info.rootuser+'->'+info.uid:info.uid} / r{info.version}@{info.dbname}</Typography>}
        <SwitchBezirk key="switch" />
        <LocalesMenuButton
            languages={[
                { locale: 'de', name: 'Deutsch' },
                { locale: 'fr', name: 'Français' },
                { locale: 'en', name: 'English' },
            ]}
        />
    </AppBar>
  )
};

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} menu={MyMenu} />;

const theme = {
    ...defaultTheme,
    palette: {
      primary: {
        main: '#7CA22F',
        contrastText: '#FFF'
      },
      secondary: {
        main: '#189eaf',
        contrastText: '#000'
      }
    }
}

const App = () => (
  <Admin i18nProvider={i18nProvider} dataProvider={dataProvider} loginPage={MyLoginPage} layout={MyLayout} authProvider={authProvider} theme={theme} requireAuth>;
    <Resource name="addrPerson" {...addrPerson} />
    <Resource name="addrForm" {...addrForm} />
    <Resource name="addrFilter" {...addrFilter} />
    <Resource name="addrActionhist" {...addrActionhist} />
    <Resource name="addrAmt" {...addrAmt} />
    <Resource name="addrAmthist" {...addrAmthist} />
    <Resource name="addrChfunktion" />
    <Resource name="addrFullname" />
    <Resource name="addrHistory" {...addrHistory} />

    <Resource name="eventEvent" {...eventEvent} />

    <Resource name="authProfil" edit={authProfil} />
    <Resource name="authUser" {...authUser} />
    <Resource name="authFwgroup" />

    <Resource name="genMailhist" {...genMailhist} />
    <Resource name="genMailempf" />
    <Resource name="genMailvor" {...genMailvor} />
    <Resource name="genDubletten" {...genDubletten} />
    <Resource name="genVersand" {...genVersand} />

    <Resource name="setFelder" {...setFelder} />
    <Resource name="setGruppe" {...setGruppe} />
    <Resource name="setGemeinde" {...setGemeinde} />
    <Resource name="setAmt" {...setAmt} />
    <Resource name="setEinheit" {...setEinheit} />
    <Resource name="setBezirkperson" />
    <Resource name="setSperr" {...setSperr} />
    <Resource name="setImport" {...setImport} />
    <CustomRoutes>
      <Route path="/setKassier" element={<SetKassier />} />,
    </CustomRoutes>
  </Admin>
)

export default App;
