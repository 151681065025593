// in src/MyMenu.js
import * as React from 'react';
import { Menu, MenuItemLink } from 'react-admin';
import { MenuItem, ListItemIcon, Typography } from '@mui/material';
import { 
  People, InsertDriveFile, FilterAlt, Event, Work, History,
  AccountBox, ScheduleSend, DynamicFeed, LocalLibrary,
  Face, AutoAwesomeMotion, AutoStories,
  Input, Groups, Paid, 
  ControlPointDuplicate, Send, Block, Upload, RestorePage } from '@mui/icons-material';
import { checkLevel, getBezirk, getGlobalData } from './globalData';

const HauptNav = (props) => {
  if(props.maxlevel && !checkLevel(props))return null;
  return (
    <div style={{padding:'3px 0 3px 16px',fontSize:'1.3em', backgroundColor:'#CAD8E2', color:'#FFF'}}>{props.text}</div>
  )
}
const SubNav = (props) => {
  if(props.maxlevel && !checkLevel(props))return null;
  return (
    <div style={{paddingLeft:'16px',fontSize:'1.1em'}}>{props.text}</div>
  )
}
const MenuLink = (props) => {
  if(props.maxlevel && !checkLevel(props))return null;
  const { checkBezirk, ...rest } = props;
  return (
    <MenuItemLink {...rest} />
  )
}
const MenuSubLink = (props) => {
  if(props.maxlevel && !checkLevel(props))return null;
  return (
    <MenuLink {...props} style={{marginLeft:'1em'}} />
  )
}

export const MyMenu = (props) => {
  const info = getGlobalData('info');
  const bezirk = getBezirk(); // checken!!!
  const eventLvl = (info.level===15 && bezirk===115)?25:20;
  const addrAmtLvl = (info.level!==15 || bezirk===115)?0:24;
  return (
    <Menu {...props}>
      <HauptNav text="Adressen" />
      <MenuLink to="/addrPerson" primaryText="Personen" leftIcon={<People />}/>
      <SubNav text="Ausgabe" maxlevel={30} />
      <MenuSubLink to="/addrForm" primaryText="Ausgabeform" leftIcon={<InsertDriveFile />} maxlevel={30} />
      <MenuSubLink to="/addrFilter" primaryText="Personenfilter" leftIcon={<FilterAlt />} maxlevel={30} />
{/*      <MenuLink to="/eventEvent" primaryText="Events" leftIcon={<Event />} maxlevel={eventLvl} checkBezirk={true} /> */}
      <MenuLink to="/addrActionhist" primaryText="History Aktionen" leftIcon={<RestorePage />} maxlevel={addrAmtLvl} checkBezirk={true} />
      <MenuLink to="/addrAmt" primaryText="Ämter" leftIcon={<Work />} maxlevel={addrAmtLvl} checkBezirk={true} />
      <MenuSubLink to="/addrAmthist" primaryText="Ämter History" leftIcon={<History />} maxlevel={addrAmtLvl} checkBezirk={true} />
      <MenuLink to="/addrHistory" primaryText="History Personen" leftIcon={<History />} maxlevel={20} checkBezirk={true} />
      <HauptNav text="Profil" />
      <MenuLink to="/authProfil/1" primaryText="Mein Profil" leftIcon={<Face />}/>
      <MenuLink to="/genMailhist" primaryText="E-Mail History" leftIcon={<ScheduleSend />}/>
      <MenuLink to="/genMailvor" primaryText="E-Mail Vorlagen" leftIcon={<DynamicFeed />} maxlevel={20} checkBezirk={true} />
      <MenuItem component="a" href="https://nx5186.your-storageshare.de/s/jM3fs2dPcboDwtJ" target="_blank">
        <ListItemIcon>
            <AutoStories fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" sx={{color:"rgba(0, 0, 0, 0.6)"}}>Handbuch</Typography>
      </MenuItem>
      <HauptNav text="Einrichten" maxlevel={20} />
      <SubNav text="Bezirk" maxlevel={20} checkBezirk={true} />
      <MenuLink to="/setFelder" primaryText="Lokale Felder" leftIcon={<Input />} maxlevel={20} checkBezirk={true} />
      <MenuLink to="/setGruppe" primaryText="Gruppen" leftIcon={<Groups />} maxlevel={20} checkBezirk={true} />
      <MenuLink to="/setGemeinde" primaryText="Gemeinden" leftIcon={<LocalLibrary />} maxlevel={20} checkBezirk={true} />
      <MenuLink to="/setKassier" primaryText="Kassier" leftIcon={<Paid />} maxlevel={1} checkBezirk={true} />
      <SubNav text="Alle Einheiten" maxlevel={20} />
      <MenuLink to="/authUser" primaryText="Benutzer" leftIcon={<AccountBox />} maxlevel={20} />
      <MenuLink to="/setAmt" primaryText="Ämter" leftIcon={<Work />} maxlevel={10} />
      <MenuLink to="/setEinheit" primaryText="Einheiten" leftIcon={<AutoAwesomeMotion />} maxlevel={13} />
      <HauptNav text="Extras" maxlevel={10} />
      <MenuLink to="/genDubletten" primaryText="Dubletten bereinigen" leftIcon={<ControlPointDuplicate />} maxlevel={10} />
      <MenuLink to="/genVersand" primaryText="Landesweiter Versand" leftIcon={<Send />} maxlevel={10} />
      <MenuLink to="/setSperr" primaryText="Sperrliste" leftIcon={<Block />} maxlevel={10} />
      <MenuLink to="/setImport" primaryText="Adressimport" leftIcon={<Upload />} maxlevel={1} />
    </Menu>
  )
}
