import React/*, { useState }*/ from 'react';
import { List, Datagrid, useRecordContext,
   ReferenceField, DateField, TextField,
   TextInput
  } from 'react-admin';
import SelectSimpleInput from '../lib/SelectSimpleInput';

let jahrOptions = "";
const now = new Date();
for (var i = now.getFullYear(); i>2000;i--) {
  jahrOptions += ';'+i;
}

const histFilters = [
  <TextInput source="name" alwaysOn />,
  <SelectSimpleInput label="Jahr" source="jahr" alwaysOn resettable simpchoices={jahrOptions} />
];

const TextDisp = (props) => {
  const record = useRecordContext(props);  
  return record.text.split('|').map(line=>(<div>{line}</div>));
}

const ListForm = (props) => {
  return (
    <List {...props} title={'ew.addr.history.title'} filters={histFilters} perPage={25}  >
      <Datagrid bulkActionButtons={false} style={{ width: 'auto' }}>
        <DateField source="zeit" showTime  sortable={false} options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} />
        <ReferenceField source="id_benutzer" reference="authUser/fullname" sortable={false}>
          <TextField source="fullname" />
        </ReferenceField>
        <TextField source="fullname"  sortable={false}/>
        <TextDisp  sortable={false}/>
      </Datagrid>
    </List>
  )
}

const addrHistory = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),
}
export default addrHistory
