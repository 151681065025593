import React, { useState } from 'react';
import { useListContext, Create, Edit, SimpleForm, required, TextInput,
  List, Datagrid, TextField, EditButton
  } from 'react-admin';
import { getGlobalData } from '../lib/globalData';
import { apiPath } from '../lib/isTest';
import Move from '../lib/Move';
import Button from '@mui/material/Button';
import ListAltIcon from '@mui/icons-material/ListAlt';

const AmtBulkActionButtons = () => {
  const { selectedIds } = useListContext();
  const token = getGlobalData('token');
  return (
  <Button href={apiPath+"setAmt/printList?token="+token+"&ids="+selectedIds} startIcon={<ListAltIcon/>} target="_blank">Ämterliste erstellen</Button>
  )
}

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  return (
  <>
    <List {...props} title={'ew.set.amt.title'} sort={{ field: 'pos', order: 'ASC' }} pagination={false} perPage={100}>
      <Datagrid bulkActionButtons={<AmtBulkActionButtons />} style={{ width: 'auto' }}>
        <Move label="glob.move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="setAmt" />
        <TextField source="bezeichnung_de" sortable={false}/>
        <TextField source="bezeichnung_fr" sortable={false}/>
        <TextField source="email" sortable={false}/>
        <EditButton label="" />
      </Datagrid>
    </List>
  </>
  )
}

const CEForm = (props) => {
  return (
    <SimpleForm {...props}>
        <TextInput source="bezeichnung_de" validate={required()} />
        <TextInput source="bezeichnung_fr" validate={required()} />
        <TextInput source="email" />
    </SimpleForm>
  )
};

const setAmt = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),

  create: (props) => (
    <Create {...props} title={'ew.set.amt.titleCreate'} redirect="list">
      <CEForm {...props}/>
    </Create>
  ),

  edit: (props) => (
    <Edit title={'ew.set.amt.titleEdit'} mutationMode="optimistic" {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default setAmt
