import React, { useState } from 'react';
import { List, Create, Edit, Datagrid, SimpleForm, TextField, required, TextInput } from 'react-admin';
import { getInfoValue } from '../lib/globalData';import Move from '../lib/Move';
import CondEditButton from '../lib/CondEditButton';

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  const bezirk_id = getInfoValue('bezirk_id');
  return (
  <>
    <List {...props} bulkActionButtons={false} title={'ew.set.gemeinde.title'} sort={{ field: bezirk_id===91?'bezeichnung':'pos', order: 'ASC' }} perPage={999} pagination={false} >
      <Datagrid bulkActionButtons={false} style={{ width: 'auto' }}>
      {bezirk_id !== 91 &&
        <Move label="glob.move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="setGemeinde" />
      }
        <TextField source="bezeichnung" sortable={false}/>
        <CondEditButton label="" />
      </Datagrid>
    </List>
  </>
  )
}

const CEForm = (props) => {
  return (
    <SimpleForm {...props}>
        <TextInput source="bezeichnung" validate={required()} />
    </SimpleForm>
  )
};

const setGemeinde = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),

  create: (props) => (
    <Create {...props} title={'ew.set.gemeinde.titleCreate'} redirect="list">
      <CEForm {...props}/>
    </Create>
  ),

  edit: (props) => (
    <Edit title={'ew.set.gemeinde.titleEdit'} mutationMode="optimistic" {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default setGemeinde
