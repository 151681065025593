import React, { useState, useEffect, useRef } from 'react';
import { useDataProvider, useListContext, useTranslate } from 'react-admin';
import { getGlobalData } from '../lib/globalData';
import { apiPath } from '../lib/isTest';
import { DialogTitle, DialogContent, Stack, Button, TextField } from '@mui/material';
import ControlledAutoComplete from '../lib/ControlledAutoComplete';

const ActPrint = ({count, manyIds}) => {
  const ta = useTranslate();
  
  const formEl = useRef(null);
  const dataProvider = useDataProvider();
  const [formen, setFormen] = useState([{id:0,formname:'--'}]);
  const [form, setForm] = useState(0);
  const [title, setTitle] = useState('');
  const [opt, setOpt] = useState({});
  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    dataProvider.getList('addrForm', { id: 0, filter: { }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(response => {
        if (response.data) {
          setFormen([{id:0,formname:'--'}, ...response.data, {id:-1,formname:'Datenblatt'}, {id:-2,formname:'Datenblatt mit Adressblatt (rechts)'}, {id:-3,formname:'Datenblatt mit Adressblatt (links)'}]);
        }
      });
  }, [dataProvider]);
  
  const changeForm = (ev, nv) => {
    setForm(nv.id);
    if (!dirty) {
      setTitle(nv.formname+': '+new Date().toLocaleDateString());
    }
  }
  const changeTitle = (ev) => {
    setTitle(ev.target.value);
    setDirty(true);
  }
  const changeOpt = (field) => (ev) => {
    setOpt({...opt, [field]:ev.target.value});
  }
  const doPrint = (ev) => {
    formEl.current.submit();
  }
  
  const { filterValues } = useListContext();
  const token = getGlobalData('token');
  const filter = JSON.stringify(filterValues);
  const selForm = formen.find((it=>it.id===form));
  return (
    <>
      <DialogTitle>{ta('ew.act.drucken.button')}</DialogTitle>
      <DialogContent>
        <Stack>
          <ControlledAutoComplete options={formen} optionLabel="formname" label="ew.act.drucken.form" onChange={changeForm} value={form} sx={{minWidth:'310px'}} />
          <TextField onChange={changeTitle} value={title} label={ta('ew.act.drucken.titelfeld')} />
    {selForm.id_ausgabetyp===2 &&          
          <>
            <p>{ta('ew.act.drucken.postext1')}<br />{ta('ew.act.drucken.postext2')}</p>
            <Stack direction="row">
              <TextField onChange={changeOpt('row')} value={opt.row} label={ta('ew.act.drucken.row')} type="number"/>
              <TextField onChange={changeOpt('col')} value={opt.col} label={ta('ew.act.drucken.col')} type="number" sx={{marginLeft:'1em'}}  />
            </Stack>
            <p>{ta('ew.act.drucken.numtext')}</p>
            <TextField onChange={changeOpt('num')} value={opt.num} label={ta('ew.act.drucken.num')} type="number"/>
          </>
    }
          <Button variant="contained" sx={{marginTop:'12px'}} onClick={doPrint} >{ta ('ew.act.drucken.start', {smart_count:count})}</Button>
        </Stack>
      </DialogContent>
      <form method="post" action={apiPath+"genPrint"} ref={formEl} target="_blank">
        <input type="hidden" name="token" value={token} />
        <input type="hidden" name="form" value={form} />
        <input type="hidden" name="title" value={title} />
        <input type="hidden" name="opt" value={JSON.stringify(opt)} />
        <input type="hidden" name="filter" value={filter} />
        <input type="hidden" name="ids" value={manyIds} />
      </form>
    </>
  );
}
  
export default ActPrint

