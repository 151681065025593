import React from 'react';
import { useListContext, useTranslate, useCreateContext, useChoicesContext, useRedirect,
  CreateBase, SimpleForm, SaveButton, Toolbar, required,
  FileField,
  TextInput, BooleanInput, FileInput, ReferenceInput, AutocompleteInput
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { DialogContent, Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import SelectSimpleInput from '../lib/SelectSimpleInput';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useWatch,  useFormContext } from 'react-hook-form';
  
const MailCreateForm = ({count, manyIds}) => {
  const { filterValues } = useListContext();
  const { save } = useCreateContext();
  const ta = useTranslate();                      
  
  const transformAction = (action, filterValues) => data => ({
    ...data, action, filter: filterValues, manyIds
  });

  const MailToolbar = ({count}) => {
    return(
      <Toolbar>
          <SaveButton type="button" label={ta('ew.act.mail.send', {smart_count:count})} transform={transformAction('send', filterValues)} />
          <SaveButton type="button" label="ew.act.mail.test" transform={transformAction('test', filterValues)} sx={{marginLeft:'12px'}} />
          <SaveButton type="button" label="ew.act.mail.save" transform={transformAction('save', filterValues)} sx={{marginLeft:'12px'}} />
      </Toolbar>
    );
  };
  
  const VorlageSelect = ({vorlage}) => {
    const dc = useChoicesContext();
    const { setValue } = useFormContext();
    const handleVorlage = (id) => {
      const recs = dc.allChoices.filter(opt=>opt.id===id);
      if (recs.length===1) {
        const rec = recs[0];
        setValue('betreff', rec.betreff);
        setValue('text', rec.text);
        setValue('vorlagetitel', rec.vorlagetitel);
        if (rec.doc && Array.isArray(rec.doc)) {
          const fi = [];
          rec.doc.forEach(doc=>{ fi.push({src:doc.src, title:doc.name})});
          rec.doc.forEach(doc=>{setValue('files', fi)});
        }
        
      }
    }
    return (
      <AutocompleteInput optionText={vorlage?"vorlagetitel":"titel"} label={"ew.act.mail.load" + (vorlage?"templ":"mail")} sx={{minWidth:'300px'}} helperText={false} onChange={handleVorlage} />
    )
  }
  
  const SetVorlage = () => {
    const savevorlage = useWatch({ name: 'savevorlage', defaultValue: false });                 
    return (
      <>
        <BooleanInput source="savevorlage" label="Als Vorlage speichern" helperText={false} defaultValue={false} />
        {!!savevorlage && <TextInput source="vorlagetitel" label="ew.act.mail.vorlagetitel" helperText={false} />}
        {!!savevorlage && <BooleanInput source="privat" label="Privat" helperText={false} />}
      </>
    )
  }

  return (
    <SimpleForm onSubmit={save} toolbar={<MailToolbar count={count} />}>
      <Accordion sx={{width:'100%', border: '1px solid #7ca22f'}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} >{ta('ew.act.mail.vorlagentitel')}</AccordionSummary>
        <AccordionDetails>
          <Stack spacing={0}>
            <ReferenceInput source="loadmail" reference="genMailvor" perPage={1000} sort={{}} filter={{ vorlage: 0 }} >
              <VorlageSelect />
            </ReferenceInput>
            <ReferenceInput source="loadtempl" reference="genMailvor" perPage={1000} sort={{}} filter={{ vorlage: 1 }} >
              <VorlageSelect vorlage />
            </ReferenceInput>
            <SetVorlage /> 
          </Stack>
        </AccordionDetails>
      </Accordion>  
      <Accordion sx={{width:'100%', border: '1px solid #7ca22f'}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{ta('ew.act.mail.optionen')}</AccordionSummary>
        <AccordionDetails>
          <Stack spacing={0}>
            <SelectSimpleInput source="address" defaultValue={0} simpchoices="0|ew.act.mail.allAddr;1|prio. E-Mail;2|prio. E-Mail (2)" label="ew.act.email.addr" validate={required()} helperText={false}  /> 
            <BooleanInput source="clipboard" label="Personen ohne E-Mail Adresse in die Zwischenablage" helperText={false}/>
            <SelectSimpleInput source="history" defaultValue={1} simpchoices="0|Für alle sichtbar;1|Privat;2|Kein Eintrag" label="ew.act.mail.history" validate={required()} helperText={false} /> 
          </Stack>
        </AccordionDetails>
      </Accordion>  
      <TextInput source="betreff" label="ew.act.mail.betreff" fullWidth helperText={false} />
      <RichTextInput source="text" label="ew.act.mail.text" fullWidth helperText={false} />
      <FileInput source="files" label="ew.act.mail.anhaenge" multiple sx={{'& .previews > div':{display: 'inline-block'}}}>
        <FileField source="src" title="title" target="_blank" />
      </FileInput>
    </SimpleForm>
  )
}
  
const ActMail = ({count, manyIds, closeDialog}) => {
  const redirect = useRedirect();
  const onSuccess = (data) => {
    closeDialog();
    redirect("/addrPerson");
  };
  return (
    <>
      <DialogContent>
        <CreateBase resource="genSendmail"  mutationOptions={{ onSuccess }}>
          <MailCreateForm count={count} manyIds={manyIds} />
        </CreateBase>
      </DialogContent>
    </>
  );
}
  
export default ActMail

