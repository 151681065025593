// in src/Dashboard.js
import React, {useState, useRef, useEffect } from "react";
import { useGetOne, useDataProvider, Title } from 'react-admin';

import { apiPath } from '../lib/isTest';
import { getGlobalData } from '../lib/globalData';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Spender = (value, index) => { 
  const [ refresh, setRefesh ] = useState(0);
  const dataprovider = useDataProvider();
  const uploadExportEl = useRef(null);
  //useEffect(() => {
  //  const { data: record, isLoading } = dataprovider.getList('setKassier', { pagination: { page: 2, perPage:1000 },
  //      sort: {  }, });
  //}, [refresh]);
  useEffect(() => {
    const { data: record, isLoading } = dataprovider.getOne('setKassier', { id: 1 });
  }, [refresh]);
  //if (value != index) return;

  /*
  useEffect(() => {
    dataprovider.getList('repBookingcom/getAllSettings', { filter: { }, sort: {  }, pagination: { page: 1, perPage: 999 }, })
      .then(res  => {
        setRefesh(refresh+1);
      })
  }, []);
  */
  
  const upload = () => {
    //const [ refresh, setRefesh ] = useState(0);
    if (uploadExportEl.current.files.length === 0) return;

    var data = new FormData()
    data.append('token', getGlobalData('token'))
    data.append('id', 1)
    data.append('ul_type', 'buchungsexport')
    data.append('buchungsexport', uploadExportEl.current.files[0])

    fetch(apiPath+'setKassier', { method: 'POST', body: data })
    .then(data => { 
      console.log('then refresh - aber wie geht das?');
      //setRefresh(refresh+1) 
    });
  }
  
  //setRefresh(refresh+1); 
  console.log('Spender');
  
  return (
    <Box sx={{ p: 3 }}>
      <Typography>Spender + Bestätigung</Typography>
        <div>F4 -> Auswertung -> Buchhaltung -> Kontoauszug</div>
        <div>
          Hier hoochladen: <input type="file" name="export" ref={uploadExportEl} />
          &nbsp;&nbsp;
          <Button variant="contained" onClick={upload} style={{ marginRight: '10px'}} >Upload</Button>
          <br />
          <br />
        </div>
        <div><img src="/spenderGaraioExport.png" style={{width:'90%', maxWidth:'420px', display:'block'}} alt="F4 -> Auswertung -> Buchhaltung -> Kontoauszug" /></div>
    </Box>
  )
}

const SetKassier = () => {
  const [value, setValue] = React.useState(0);
  //const { data: record, isLoading } = useGetOne('setKassier', { id: 1 });
  //if (isLoading) { return null; }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card sx={{mt:2}}>
      <Title title="" />
      <CardContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Spender + Bestätigung" />
              <Tab label="Dankesbrief" />
              <Tab label="KST Auswertung" />
              <Tab label="Rechnungen erstellen" />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Spender />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            Item Two
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            Item Three
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            Item Four
          </CustomTabPanel>
        </Box>
      </CardContent>
    </Card>
  );
}
export default SetKassier
