import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import SubMenuTitle from './SubMenuTitle';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Close } from '@mui/icons-material';

const SubMenu = (props)=> {
  const { menu, close, click } = props;
  const ta = useTranslate();      
  const [ copied, setCopied ] = useState({});
  const handleListItemClick = (act) => (ev) => {
    click(act);
  }

  const setCopiedItem = (k) => {
    setCopied(cp => { return {...cp, [k]:true}; });
  }
  
  if (!menu.list) return null;
  return (
    <Dialog onClose={close} open={menu !== false}>
      <List sx={{ pt: 0, minWidth:'150px' }}>
        <SubMenuTitle title={ta(menu.title)} />
        <Close onClick={close} sx={{ position:'absolute', right:'8px', top:'8px', cursor:'pointer'}} />
        {menu.list.map((item, k) => {
          if (item.clipboard) {
            return (
              <CopyToClipboard text={item.clipboard} onCopy={(t,r) => {setCopiedItem(k)}} key={menu.action+item.id}>
                <ListItemButton sx={{backgroundColor:copied[k]?"#7ca22f":'',"&:hover":{backgroundColor:copied[k]?"#7ca22f":'rgba(0,0,0,0.04)'}}} variant="contained">{ta(item.text)}</ListItemButton>
              </CopyToClipboard>
            )
          }
          return (
            <ListItemButton onClick={item.href?null:handleListItemClick({action:menu.action, id: item.id})} href={item.href?item.href:null} key={menu.action+item.id}>
          {item.icon?
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
          :null}
              <ListItemText primary={ta(item.text)} />
            </ListItemButton>
        )})}
      </List>
    </Dialog>
  );
  
}


export default SubMenu;