import {
    CreateButton,
    FilterButton,
    FilterForm,
    ListBase,
    Pagination,
} from 'react-admin';
import { Stack } from '@mui/material';

const ListToolbar = ({filters, noCreateButton}) => {
  const hasNDFilters = filters.reduce((pv, cv)=>(pv+!cv.props.alwaysOn), false)
  return (
    <Stack direction="row" justifyContent="space-between">
      <FilterForm filters={filters} />
      <div>
        { hasNDFilters>0 && <FilterButton filters={filters} disableSaveQuery/>}
        { noCreateButton===false && <CreateButton />}
      </div>
    </Stack>
  )
}

const MyList = ({children, filters, noCreateButton=false}) => (
  <ListBase>
    <ListToolbar filters={filters} noCreateButton={noCreateButton}/>
      {children}
    <Pagination />
  </ListBase>
)
export default MyList;
