import React, { useState, useEffect } from 'react';
import { useDataProvider, useTranslate, 
  List, Create, Edit, Datagrid, SimpleForm, TabbedForm, FormTab,
  TextField, ImageField, SelectField,
  EditButton, TextInput, RadioButtonGroupInput, ReferenceInput, AutocompleteInput, BooleanInput, ImageInput } from 'react-admin';
import { isHauptadmin } from '../lib/globalData';
import SelectSimpleInput from '../lib/SelectSimpleInput';
import DataGridGroupedBody from '../lib/DataGridGroupedBody.tsx';
import ControlledAutoComplete from '../lib/ControlledAutoComplete';
import Move from '../lib/Move';
import CloseToolbar from '../lib/CloseToolbar';
import {  NumberInputGrid, SelectInputGrid } from '../lib/gridInput';
import { useWatch, useFormContext } from 'react-hook-form';
import { Button, Grid} from '@mui/material';
import MoveIcon from '@mui/icons-material/UnfoldMore';
import DeleteIcon from '@mui/icons-material/Delete';

const ausgabetypen = [
    { id: 1, name: 'Liste (PDF)' },
    { id: 2, name: 'Etiketten' },
    { id: 3, name: 'Umschläge' },
    { id: 4, name: 'Export (z.B. für Excel)' },
    { id: 5, name: 'Geburtstagsliste' },
  ];

const MyDatagrid = props => <Datagrid {...props} body={<DataGridGroupedBody />} />;
  
const ListForm = (props) => {
// Checkbox "Alle Personen anzeigen"
  const {classes, ...rest} = props;
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  return (
  <>
    <List {...rest} bulkActionButtons={false} title={'ew.addr.form.title'} sort={{ field: 'id_ausgabetyp, pos', order: 'ASC' }} perPage={25} >
      <MyDatagrid style={{ width: 'auto' }} bulkActionButtons={false}>
        <Move label="glob.move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="addrForm" />
        <TextField source="formname" sortable={false}/>
        {isHauptadmin() && <TextField source="id_ausgabetyp" sortable={false}/>}
        {isHauptadmin() && <TextField source="pos" sortable={false}/>}
        {isHauptadmin() && <TextField source="id" sortable={false}/>}  
        <EditButton label="" />
        <SelectField source="id_ausgabetyp" choices={ausgabetypen} label=""/>
      </MyDatagrid>
    </List>
  </>
  )
}

const Basis = () => {
  const ausgabetyp = useWatch({ name: 'id_ausgabetyp' });                 
  return (
    <>
      <TextInput source="formname" />
      <RadioButtonGroupInput source="id_ausgabetyp" choices={ausgabetypen} />

  {parseInt(ausgabetyp)!==5 &&
    <>
      <ReferenceInput source="form.af_sort1" reference="setFelder/filter" perPage={999} sort={{field:'id'}}>
        <AutocompleteInput optionText="bezeichnung" />
      </ReferenceInput>
      <ReferenceInput source="form.af_sort2" reference="setFelder/filter" perPage={999} sort={{field:'id'}}>
        <AutocompleteInput optionText="bezeichnung" />
      </ReferenceInput>
      <ReferenceInput source="form.af_sort3" reference="setFelder/filter" perPage={999} sort={{field:'id'}}>
        <AutocompleteInput optionText="bezeichnung" />
      </ReferenceInput>
    </>
  }
  </>
  )
}
  
const Darstellung = () => {
  const ausgabetyp = parseInt(useWatch({ name: 'id_ausgabetyp' }));
  const form = useWatch({ name: 'form' });

  if (!form) return null;
  
  if (ausgabetyp===1) {
    return (
      <>
        <SelectSimpleInput source="form.af_liformat" simpchoices="0|glob.choice.hoch;1|glob.choice.quer" />
        <BooleanInput source="form.af_linetz" />
        
        <ImageInput source="af_logo" label="Logo" accept="image/*">
            <ImageField source="src" title="af_lilogo" />
        </ImageInput>
        <Grid container columnSpacing={2}>
          <NumberInputGrid source="form.af_lilogow" defaultValue={20} compact />
          <NumberInputGrid source="form.af_lilogox" defaultValue={15} compact />
        </Grid>
      </>
    )
  }
  if (ausgabetyp===2) {
    return (
      <>
        <SelectSimpleInput source="form.af_etformat" simpchoices="0|2 x 7;1|2 x 8;2|3 x 7;3|3 x 8;4|3 x 9" />
        <Grid container columnSpacing={2}>
          <NumberInputGrid source="form.af_etbreite" defaultValue={70} compact />
          <NumberInputGrid source="form.af_ethoehe" defaultValue={37} compact />
        </Grid>
        <Grid container columnSpacing={2}>
          <NumberInputGrid source="form.af_etoben" defaultValue={10} compact />
          <NumberInputGrid source="form.af_etlinks" defaultValue={10} compact />
        </Grid>
        <BooleanInput source="form.af_etlargefont" />
        <SelectSimpleInput source="form.af_ethaushalt" simpchoices="0|glob.choices.person;1|glob.choices.haushalt;2|glob.choices.haushaltmitvornamen" />
        <ReferenceInput source="form.af_etzusatzfeld" reference="setFelder/filter" perPage={999} sort={{field:'id'}}>
          <AutocompleteInput optionText="bezeichnung" />
        </ReferenceInput>
      </>
    )
  }
  if (ausgabetyp===3) {
    return (
      <>
        <SelectSimpleInput source="form.af_umformat" simpchoices="0|C6;1|C5/6;2|C5;3|C4;4|glob.choices.umA4R;5|glob.choices.umA4L" />
        <SelectSimpleInput source="form.af_umhaushalt" simpchoices="0|glob.choices.person;1|glob.choices.haushalt;2|glob.choices.haushaltmitvornamen" />
        <ReferenceInput source="form.af_umzusatzfeld" reference="setFelder/filter" perPage={999} sort={{field:'id'}}>
          <AutocompleteInput optionText="bezeichnung" />
        </ReferenceInput>
        <BooleanInput source="form.af_umlargefont" />
        <ImageInput source="af_logo" label="Logo" accept="image/*">
            <ImageField source="src" title="af_lilogo" />
        </ImageInput>
        <Grid container columnSpacing={2}>
          <NumberInputGrid source="form.af_umlogow" defaultValue={20} compact />
          <NumberInputGrid source="form.af_umlogox" defaultValue={15} compact />
          <NumberInputGrid source="form.af_umlogoy" defaultValue={15} compact />
        </Grid>
      </>
    )
  }
  if (ausgabetyp===4) {
    return (
      <>
        <SelectSimpleInput source="form.af_exformat" simpchoices="0|Excel;1|CSV" />
        <SelectSimpleInput source="form.af_exhaushalt" simpchoices="0|glob.choices.person;1|glob.choices.haushalt;2|glob.choices.haushaltmitvornamen" />
      </>
    )
  }
  if (ausgabetyp===5) {
    var jahre = '';
    var jetzt = new Date();
    var jahr = jetzt.getFullYear();
    for (var i=-1; i<10; i++) {
      jahre += (i>-1?';':'') + (i+1) + '|' + (jahr+i);
    }
    return (
      <>
        <Grid container columnSpacing={2}>
          <SelectInputGrid source="form.af_glformat" simpchoices="0|Excel;1|PDF" compact />
          <SelectInputGrid source="form.af_glpformat" simpchoices="0|glob.choice.hoch;1|glob.choice.quer" compact />
        </Grid>
        <Grid container columnSpacing={2}>
          <SelectInputGrid source="form.af_gltyp" simpchoices="0|glob.choices.monatsliste;1|glob.choices.jahreslist;2|glob.choices.seniorenliste;3|glob.choices.rundegeburtstage" compact />
        {form.af_gltyp === 0 &&
          <SelectInputGrid source="form.af_glmonat" compact simpchoices=
            "0|glob.choices.monJan;1|glob.choices.monFeb;2|glob.choices.monMar;3|glob.choices.monApr;4|glob.choices.monMai;5|glob.choices.monJun;6|glob.choices.monJul;4|glob.choices.monAug;8|glob.choices.monSep;9|glob.choices.monOkt;10|glob.choices.monNov;11|glob.choices.monDez" />
        }
          <SelectInputGrid source="form.af_gljahr" simpchoices={jahre} compact />
        </Grid>
      </>
    )
  }
  return null;
}

const Felder = () => {
  const dataProvider = useDataProvider();
  const ta = useTranslate();
  const form = useWatch({ name: 'form' });
  const { setValue } = useFormContext();
  const [felder, setFelder] = useState(0);
  const [moveStart, setMoveStart] = useState(false);
  
  useEffect(() => {
    dataProvider.getList('setFelder/ausgabe', { id: 0, filter: { ausgabefelder:true }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(response => {
        if (response.data) {
          setFelder([{id:0,bezeichnung:'--'}, ...response.data]);
        }
      });
  }, [dataProvider]);
  
  const selectFeld = (ev, nv) => {
    if (!nv) return false;
    const bez = nv.bezeichnung.indexOf('.')>-1?ta(nv.bezeichnung):nv.bezeichnung;
    const f = form.af_felder?form.af_felder:[];
    const fm = {...form, af_felder:[...f, {id_feld:nv.id, bez:bez}]};
    setValue('form', fm, {shouldDirty: true});
  }
  const moveClick = (pos) => (ev) => {
    if (moveStart === false) {
      setMoveStart(pos);
    }
    else {
      if (moveStart!==pos) {
        const felder = [...form.af_felder];
        felder.splice(pos, 0, felder.splice(moveStart, 1)[0]);
        const fm = {...form, af_felder:felder};
        setValue('form', fm, {shouldDirty: true});
      }
      setMoveStart(false);
    }
  }
  const delClick = (pos) => (ev) => {
    const fs = [...form.af_felder];
    fs.splice(pos, 1);
    const fm = {...form, af_felder:fs};
    setValue('form', fm, {shouldDirty: true});
  }

  return (
    <>
      <BooleanInput source="form.af_formallefelder" />
    {form && !form.af_formallefelder && 
      <>
        <ControlledAutoComplete options={felder} optionLabel="bezeichnung" label="ew.addr.form.add" onChange={selectFeld} value={0} sx={{minWidth:'500px'}} /> 
      {form && form.af_felder && form.af_felder.map((feld, i)=> {
        if(!feld.bez) return null;
        return (
        <div key={i}>
          <Button onClick={moveClick(i)} variant="contained"  color={moveStart===i?"secondary":"primary"} sx={{margin:'0 16px 8px 0'}} ><MoveIcon /></Button>
          <Button onClick={delClick(i)} variant="contained" sx={{margin:'0 16px 8px 0'}} ><DeleteIcon /></Button>
          {feld.bez.substr(0,2)==='$#'?ta('resources.addrPerson.fields.'+feld.bez.substr(2)):(feld.bez.substr(0,10)==='resources.'?ta(feld.bez):feld.bez)}
        </div>
      )})}  
      </>
    }
    </>
  )
}

const Ftab = (props) => {
  const at = parseInt(useWatch({ name: 'id_ausgabetyp' }));
  if (at === 2 || at === 3) return null;
  return (
    <TabbedForm.Tab {...props} />
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <TabbedForm {...props} toolbar={<CloseToolbar />}> 
      <FormTab label="Basis">
        <Basis />
      </FormTab> 
      <FormTab label="Darstellung">
        <Darstellung />
      </FormTab> 
      <Ftab label="Felder">
        <Felder />
      </Ftab> 
    </TabbedForm>
  )
};

const addrForm = {
  list: (props) => (
    <ListForm {...props} />
  ),
  
  create: (props) => (
    <Create {...props} title={'ew.addr.form.titleCreate'}>
      <SimpleForm>
        <TextInput source="formname" />
      </SimpleForm>
    </Create>
  ),
  
  edit: (props) => (
    <Edit title={'ew.addr.form.titleEdit'} {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}   
export default addrForm