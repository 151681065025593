import React, { useState, useEffect, useRef } from 'react';
import { apiPath } from '../lib/isTest';
import { getGlobalData } from '../lib/globalData';
import ControlledAutoComplete from '../lib/ControlledAutoComplete';
import { useListContext, useDataProvider, useTranslate, useNotify  } from 'react-admin';
import { DialogTitle, DialogContent, Stack, Button, FormControlLabel, TextField, Switch } from '@mui/material';
 
const ActMutation = ({sub, count, manyIds, closeDialog }) => {
  const formEl = useRef(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const ta = useTranslate();      
  const token = getGlobalData('token');
  const { filterValues } = useListContext();
  const [bezirke, setBezirke] = useState([]);
  const [opt, setOpt] = useState({});
  const [errors, setErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(true);

  useEffect(() => {
    dataProvider.getList('genBezirk', { id: 0, filter: { }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(response => {
        if (response.data) {
          setBezirke([{id:0, bezeichnung:'--'}, ...response.data, {id:91, bezeichnung:'EMK Schweiz'}]);
        }
      });
  }, [dataProvider]);

  useEffect(() => {
    setHasErrors(CheckErrors());
  }, [opt]);

  const CheckErrors = () => {
    const errs = {
      datum: !opt.datum,
      id_bezirk: sub==='trans' && !opt.id_bezirk,
    }
    setErrors(errs);
    const hasErr = Object.keys(errs).reduce((pv, cv)=>pv || errs[cv], false);
    return hasErr;
  }

  const doExec = (ev) => {
    if (hasErrors) return false;
    formEl.current.submit();
    notify('ew.act.done');
    closeDialog();
  }
  
  const changeOpt = (field) => (ev) => {
    setOpt({...opt, [field]:ev.target.value});
  }
  const changeOptChk = (field) => (ev) => {
    setOpt({...opt, [field]:ev.target.checked});
  }
  const changeBezirk = (ev, nv) => {
    setOpt({...opt, id_bezirk:nv.id});;
  }

  return (
    <>
      <DialogTitle>{ta('ew.act.mutationen.'+sub)}</DialogTitle>
      <DialogContent>
        <Stack>
          <TextField type="date" onChange={changeOpt('datum')} value={opt.datum} label={ta('ew.act.mutationen.date')} InputLabelProps={{shrink:true}} required error={errors.datum} />
        {(sub==='quit' || sub==='delete') &&  
          <TextField onChange={changeOpt('bemerkung')} value={opt.bemerkung} multiline minRows={3} fullWidth label={ta('ew.act.mutationen.bemerkung')} />
        }
        {sub==='trans' &&  
          <ControlledAutoComplete options={bezirke} optionLabel="bezeichnung" label="ew.act.mutationen.id_bezirk" 
            onChange={changeBezirk} value={opt.id_bezirk?opt.id_bezirk:0} sx={{minWidth:'310px'}} error={errors.id_bezirk} />
        }
        {(sub==='trans') &&  
          <FormControlLabel control={<Switch onChange={changeOptChk('verknuepft')} checked={opt.verknuepft?true:false} />} label={ta('ew.act.mutationen.verknuepft')} />        
        }
        {sub==='quit' &&  
          <FormControlLabel control={<Switch onChange={changeOptChk('freund')} checked={opt.freund?true:false} />} label={ta('ew.act.mutationen.freund')} />        
        }
          <FormControlLabel control={<Switch onChange={changeOptChk('keineintrag')} checked={opt.keineintrag?true:false} />} label={ta('ew.act.mutationen.keineintrag')} />        
          <Button variant="contained" sx={{marginTop:'12px'}} onClick={doExec} disabled={hasErrors} >{ta('ew.act.mutationen.exec-'+sub, {smart_count:count})}</Button>
        </Stack>
      </DialogContent>
      <form method="post" action={apiPath+"addrAktion"} ref={formEl} target="_blank">
        <input type="hidden" name="token" value={token} />
        <input type="hidden" name="filter" value={JSON.stringify(filterValues)} />
  {Object.keys(opt).map((o)=>(
        <input type="hidden" name={o} value={!opt[o]?0:opt[o]} />
  ))}      
        <input type="hidden" name="sub" value={sub} />
        <input type="hidden" name="ids" value={manyIds} />
        <input type="hidden" name="datenblatt" value="1" />
      </form>
    </>
  );
/*
  return (
    <DialogContent>
      <div style={{fontWeight:'bold', fontSize:'1.2rem'}}>{ta('ew.act.mutationen.'+sub)}</div>
      <CreateBase resource="addrAktion" record={{id: manyIds, sub}} mutationOptions={{ onSuccess }} >
        <MutCreateForm count={count} manyIds={manyIds} sub={sub} />
      </CreateBase>
    </DialogContent>
  );
*/  
}

export default ActMutation
