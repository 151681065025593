const langDE =
{
    ew: {
        act: {
            bearbeiten: {
                button: "Bearbeiten",
                feld: "Feld",
                start: "Feld für %{smart_count} Person überschreiben |||| Feld für %{smart_count} Personen überschreiben",
                titel: "Ein Feld für %{smart_count} Person setzen |||| Ein Feld für %{smart_count} Personen setzen",
                wert: "Wert"
            },
            birthday: {
                exec: "Geburtstagsliste erstellen",
                titel: "Geburtstagsliste",
                typ: {
                    j: "Jahresliste",
                    m: "Monatsliste",
                    r: "Runde Geburtstage (10er, ab 60 auch 5er)",
                    s: "Seniorenliste (ab 65 nur 5er, ab 80 alle)"
                }
            },
            compare: {
                titel: "Personen daten anzeigen |||| Vergleich von %{smart_count} Personen"
            },
            datenblatt: {
                button: "Datenblatt"
            },
            delverkn: {
                button: "Verknüpfung lösen"
            },
            dispverkn: {
                button: "Zeige Verknüpfungen"
            },
            done: "Aktion ausgeführt",
            drucken: {
                button: "Drucken / Export",
                col: "Startspalte",
                form: "Ausgabeform",
                geburtsdatum: "Geburtsdatum",
                num: "Anzahl Etiketten pro Adresse",
                numtext: "Gleiche Etiketten mehrfach drucken.",
                postext1: "Hier können Sie die Start-Etikette wählen,",
                postext2: "falls Sie auf einem angefangenen Etikettenblatt drucken.",
                row: "Startzeile",
                start: "Druck / Export für %{smart_count} Personen",
                titelfeld: "Titel"
            },
            email: {
                addr: "Empfänger-Adresse",
                saved: "Gepeicherte E-Mail öffnen",
                vorlagen: "Vorlage öffnen"
            },
            geburtstag: {
                button: "Geburtstagsliste"
            },
            handlung: {
                bek: "Bekenntnisfeier",
                button: "Handlungen",
                ehe: "Hochzeit",
                "exec-bek": "Bekenntnisfeier eintragen",
                "exec-ehe": "Hochzeit eintragen",
                "exec-seg1": "Kindersegnung eintragen",
                "exec-seg2": "Segnungsfeier eintragen",
                "exec-tauf1": "Kindertaufe eintragen",
                "exec-tauf2": "Erwachsenentaufe eintragen",
                "exec-tod": "Todesfall eintragen",
                seg1: "Kindersegnung",
                seg2: "Segnungsfeier",
                tauf1: "Kindertaufe",
                tauf2: "Erwachsenentaufe",
                tod: "Beerdigung / Todesfall"
            },
            handlungen: {
                button: "Kirchliche Handlungen"
            },
            history: {
                button: "History"
            },
            loeschen: {
                button: "Löschen",
                titel: "inaktiv setzen"
            },
            loeschen2: {
                button: "Definitv löschen",
                titel: "definitv löschen"
            },
            mail: {
                allAddr: "Alle Adressen",
                anhaenge: "Anhänge",
                betreff: "Betreff",
                history: "Kommunikationshistory",
                loadmail: "Gespeicherte E-Mail öffnen",
                loadtempl: "Vorlage öffnen",
                mailtitel: "Gespeicherte E-Mail öffnen",
                optionen: "Optionen",
                save: "Mail speichern",
                send: "Mail senden an %{smart_count} Empfänger",
                test: "Test Mail an mich",
                text: "Text",
                titel: "E-Mail versenden",
                vorlagentitel: "Vorlagen und gespeicherte E-Mails",
                vorlagetitel: "Vorlagentitel"
            },
            mitteilung: {
                button: "Mitteilung an Bezirk"
            },
            msgadm: {
                confirm: "Mitteilung gesendet",
                exec: "Mitteilung senden",
                subtitle: "Mitteilung wird an den/die Bezriksadministrator(en) der ausgewählten Person(en) per E-Mail gesendet",
                title: "Mitteilung"
            },
            mutationen: {
                bemerkung: "Bemerkung",
                button: "Mutationen",
                date: "Datum",
                delete: "Streichen",
                "exec-delete": "%{smart_count} Person streichen |||| %{smart_count} Personen streichen",
                "exec-quit": "Austritt eintragen",
                "exec-trans": "%{smart_count} Person überweisen |||| %{smart_count} Personen überweisen",
                freund: "Bleibt Freund",
                id_bezirk: "Bezirk",
                keineintrag: "Kein Eintrag in History / Kirchenbuch / Mitteilungen",
                quit: "Austritt",
                trans: "Überweisen",
                verknuepft: "als verknüpfte Person behalten"
            },
            nachricht: {
                button: "Nachricht",
                clipAddr: "Adressen auf Zwischenablage",
                clipMail: "E-Mails auf Zwischenablage",
                send: "Nachricht senden",
                sendapp: "Mail senden aus emk-web",
                sendbcc: "E-Mail (bcc)",
                sendto: "E-Mail (an)"
            },
            reaktivieren: {
                button: "Reaktivieren"
            },
            sperren: {
                all: "Person sperren beide",
                button: "Sperren",
                ch: "Person sperren CH Versand",
                kuw: "Person sperren KuW",
                nall: "Person entsperren beide",
                nch: "Person entsperren CH Versand",
                nkuw: "Person entsperren KuW"
            },
            target: {
                all: "%{smart_count} Person gemäss Filter |||| alle %{smart_count} Personen gemäss Filter",
                sel: "%{smart_count} ausgewählte Person |||| %{smart_count} ausgewählte Personen",
                titel: "Aktion für"
            },
            titel: "Aktionen für %{smart_count} Person |||| Aktionen für %{smart_count} ausgewählte Personen",
            vergleichen: {
                button: "Personen vergleichen"
            },
            verkndisp: {
                text: "Die selektierte Person ist in folgenden Einheiten verknüpft:",
                text2: "Bitte beachten Sie, dass nur Einheiten angezeigt werden, auf die Sie Zugriff haben.",
                titel: "Verknüpfungen"
            },
            verknuepf: {
                done: "Person wurde verknüpft",
                exec: "Person verknüpfen",
                text: "Bitte wählen Sie die Einheit, in die die Person(en) verknüpft werden soll.",
                titel: "Person verknüpfen"
            },
            "verknüpfen": {
                button: "Person verknüpfen"
            },
            zueinheit: {
                button: "Gehe zu Personeneinheit"
            }
        },
        addr: {
            acthist: {
                title: "History Aktionen"
            },
            amt: {
                title: "Ämter"
            },
            amthist: {
                title: "Ämter History",
                titleEdit: "Amt History Editieren"
            },
            filter: {
                aemter: "Ämter",
                aktiv: "Aktiv",
                alle: "Alle",
                allePers: "Alle Personen",
                allejs: "Alle Jungscharen",
                allewerke: "Alle Werke",
                amtstatus: "Aemterstatus",
                eigene: "Nur eigene",
                einheit: "Einheit",
                einheiten: "Alle Bezirke",
                feld: "Feld",
                geladen: "Filter geladen",
                gruppenfunktion: "Gruppenfunktion",
                idEinheit: "Einheit (ID)",
                load: "Filter laden",
                logik: "Logik",
                name: "Filterbezeichnung",
                nuraktiv: "Nur aktive Personen",
                nurinaktiv: "Nur inaktive Personen",
                save: "Filter speichern",
                title: "Gespeicherte Filter",
                vergleich: "Vergleich",
                verknuepft: "Verknüpfte Personen",
                verknuepfte: "Nur verknüpfte",
                wahl: "Personenfilter wählen",
                wert: "Wert"
            },
            form: {
                add: "Feld hinzufügen",
                title: "Ausgabeformen",
                titleCreate: "Ausgabeform erstellen",
                titleEdit: "Ausgabeform bearbeiten"
            },
            haushalt: {
                select: {
                    title: "Haushalt zuweisen"
                }
            },
            history: {
                title: "Personen History"
            },
            person: {
                MoeglicheVerknuepfungen: "Mögliche Verknüpfungen",
                Verknuepfen: "Verknüpfen",
                aktionen: "Aktionen",
                erwfilter: "Erweiterter Filter",
                title: "Personen",
                titleCreate: "Person erfassen",
                titleEdit: "Person bearbeiten"
            },
            tab: {
                daten: "Daten",
                emk: "EMK",
                gruppen: "Gruppen",
                gruppenhist: "Gruppen History",
                haushalt: "Haushalt",
                kontakt: "Kontakt",
                person: "Person"
            }
        },
        auth: {
            profil: {
                addrTitle: "Adresse",
                langTitle: "Sprache ändern",
                pwdTitle: "Passwort ändern",
                titleEdit: "Profil",
                totp: "TOTP Zwei-Faktor"
            },
            user: {
                copydia: {
                    rolle: "Neue Benutzerrolle eingeben",
                    title: "Benutzer kopieren"
                },
                error: {
                    deleterolls: "Benutzer nicht gelöscht. - Erst müssen alle Rollen gelöscht werden.",
                    invalidchars: "Der Benutzername darf nur Buchstaben (auch Umlaute), Ziffern, Punkte, Unterstriche und Bindestriche enthalten.",
                    noBezId: "Bezirk nicht gefunden",
                    usernameexists: "Benutzername bereits vergeben.",
                    validemail: "Eine gültige E-Mail-Adresse muss eingegeben werden.",
                    validusername: "Ein Benutzername muss eingegeben werden."
                },
                title: "Benutzer",
                titleCreate: "Benutzer erstellen",
                titleEdit: "Benutzer bearbeiten",
                usrCodeYubi: "Name + Passwort + Authenticator/Codekarte/Yubico",
                yubico: "Yubico"
            }
        },
        set: {
            amt: {
                delerr: "Dem Amt sind noch Personen zugeordnet. Erst diese entfernen.",
                title: "Ämter"
            },
            einheit: {
                bezirk: "Bezirk",
                distrikt: "Distrikt",
                strang: "Strang",
                title: "Einheiten"
            },
            felder: {
                specialHelperText: "sehen nur Benutzer mit Spezialrecht",
                title: "Lokale Felder",
                titleCreate: "Neuer Eintrag in Lokale Felder",
                titleEdit: "Lokale Felder Bearbeiten"
            },
            gemeinde: {
                title: "Gemeinden",
                titleCreate: "Gemeinde erstellen",
                titleEdit: "Gemeinde bearbeiten"
            },
            gruppe: {
                title: "Gruppen"
            },
            mailhist: {
                privatepublic: "Öffentliche und private",
                title: "E-Mail History"
            },
            mailvor: {
                title: "E-Mail Vorlagen"
            },
            sperr: {
                chVersand: "CH Versand",
                kuw: "K&W",
                title: "Sperrliste"
            }
        }
    },
    glob: {
        choice: {
            hoch: "Hoch",
            quer: "Quer"
        },
        choices: {
            all: "Alle",
            andere: "Andere",
            auswahl: "Auswahl",
            datum: "Datum",
            de: "Deutsch",
            en: "Englisch",
            fr: "Französisch",
            geschieden: "geschieden",
            getrennt: "getrennt",
            haushalt: "Haushalt",
            haushaltmitvornamen: "Haushalt mit Vornamen Personen",
            jahreslist: "Jahresliste",
            janeinfeld: "Ja / Nein",
            ledig: "ledig",
            maennlich: "Männlich",
            mehrzeilig: "Mehrzeilig",
            monApr: "April",
            monAug: "August",
            monDez: "Dezember",
            monFeb: "Februar",
            monJan: "Januar",
            monJul: "Juli",
            monJun: "Juni",
            monMai: "Mai",
            monMar: "März",
            monNov: "November",
            monOkt: "Oktober",
            monSep: "September",
            monatsliste: "Monatsliste",
            no: "Nein",
            onemonth: "1 Monat",
            oneyear: "1 Jahr",
            person: "Person",
            privateonly: "Nur private Emails",
            publiconly: "Nur öffentliche Emails",
            rundegeburtstage: "Runde Geburtstage (10er, ab 60 auch 5er)",
            seniorenliste: "Seniorenliste (ab 65 nur 5er, ab 80 alle)",
            sevendays: "7 Tage",
            sinceyesterday: "Seit gestern",
            stAssoziert: "Assoziertes Mitglied (AT)",
            stAusgetreten: "Ausgetreten",
            stBekennend: "Bekennendes Glied",
            stFreund: "Freund",
            stGast: "Gastmitglied (AT)",
            stGetauft: "Getauftes Glied",
            stGruppe: "Nur Gruppenmitglied",
            stKind: "Kind / Teenager",
            textfeld: "Textfeld",
            today: "Heute",
            umA4L: "A4 für C5 links",
            umA4R: "A4 für C5 rechts",
            verheiratet: "verheiratet",
            verwitwet: "verwitwet",
            weiblich: "Weiblich",
            werkWaehlen: "Werk wählen",
            yes: "Ja"
        },
        close: "Schliessen",
        columns: "Spalten",
        confirm: "Bestätigen",
        copy: "Kopieren",
        day: {
            short: {
                fri: "Fr",
                mon: "Mo",
                sat: "Sa",
                sun: "So",
                thu: "Do",
                tue: "Di",
                wed: "Mi"
            }
        },
        error: {
            noAccess: "Kein Zugang"
        },
        errpassword: "Passwort ungültig.",
        erruser: "Benutzer nicht registriert.",
        invalidtoken: "Ungültiges Token.",
        move: "Verschieben",
        onlysinglebez: "Nur für einzelnen Bezirk verfügbar.",
        otp: {
            invalid: "Falsche Zweit-Faktor"
        },
        validatePhone: "Nur Ziffern, Plus und Leerzeichen erlaubt.",
        yubico: {
            invalid: "Ungültiger Yubico Code.",
            wrongkey: "Falsche Yubico Key."
        }
    },
    ra: {
        action: {
            select_columns: "Spalten"
        },
        configurable: {
            customize: "Anzeige anpassen"
        }
    },
    resources: {
        addBirthday: {
            fields: {
                month: "Monat",
                typ: "Typ",
                year: "Jahr"
            }
        },
        addrAktion: {
            fields: {
                bemerkung: "Bemerkung",
                datum: "Datum",
                freund: "Bleibt Freund",
                grund: "Grund",
                id_bezirk: "Bezirk",
                keineintrag: "Kein Eintrag in History / Kirchenbuch / Mitteilungen",
                mitteilung: "Mitteilung an Kirche & Welt",
                ort: "Ort",
                pfarrer: "Pfarrer",
                verknuepft: "als verknüpfte Person behalten",
                vers: "Vers"
            }
        },
        addrAmt: {
            fields: {
                add: "Zusätzliche Person",
                id_person: "Person"
            }
        },
        addrAmthist: {
            fields: {
                bis: "Bis",
                id_ch_funktion: "Amt",
                id_person: "Person",
                von: "Von"
            }
        },
        addrFilter: {
            load: {
                fields: {
                    id_filter: "Filter"
                }
            }
        },
        addrForm: {
            fields: {
                form: {
                    af_etbreite: "Etikettenbreite",
                    af_etformat: "Etikettenformat",
                    af_ethaushalt: "Anschrift",
                    af_ethoehe: "Etikettenhöhe",
                    af_etlargefont: "Grössere Schrift",
                    af_etlinks: "Seitenrand links",
                    af_etoben: "Seitenrand oben",
                    af_etzusatzfeld: "Zusatzfeld",
                    af_exformat: "Dateiformat",
                    af_exhaushalt: "Anschrift",
                    af_formallefelder: "Alle Felder",
                    af_glformat: "Dateiformat",
                    af_gljahr: "Jahr",
                    af_glmonat: "Monat",
                    af_glpformat: "Seitenformat",
                    af_gltyp: "Typ",
                    af_liformat: "Seitenformat",
                    af_lilogo: "Bild",
                    af_lilogow: "Breite",
                    af_lilogox: "Abstand oben",
                    af_linetz: "Gitternetzlinien",
                    af_sort1: "Sortierung 1",
                    af_sort2: "Sortierung 2",
                    af_sort3: "Sortierung 3",
                    af_umformat: "Umschlagformat",
                    af_umhaushalt: "Anschrift",
                    af_umlargefont: "Grössere Schrift",
                    af_umlogo: "Bild",
                    af_umlogow: "Breite",
                    af_umlogox: "Abstand links",
                    af_umlogoy: "Abstand oben",
                    af_umzusatzfeld: "Zusatzfeld"
                },
                id_ausgabetyp: "Typ"
            }
        },
        addrPerson: {
            empty: "Noch keine Person erfasst",
            fields: {
                GruppenKommaGetrennt: "Grppen",
                GruppenSpalten: "Gruppen (Spalten)",
                KirchlicheHandlungen: "Handlungen",
                Mitgliedernummer: "Mitgliedernummer",
                VornamenAllerPersonen: "Vornamen aller Personen",
                active: "Aktiv/Inaktiv",
                aemter: "Ämter",
                alter: "Alter",
                anrede: "Anrede",
                bemerkungen: "Bemerkungen",
                beruf: "Beruf",
                du: "Du",
                einheit: "Einheit",
                einheiten: "Alle Bezirke",
                email_g: "E-Mail (zwei)",
                email_p: "E-Mail",
                fax_g: "Fax Geschäft",
                fax_p: "Fax privat",
                geboren: "Geboren",
                geschlecht: "Geschlecht",
                gruppenfunktion: "Gruppenfunktion",
                gruppentext: "Gruppen",
                handy: "Handy",
                haushalt: "Haushalt",
                haushaltanrede: "Anrede (Haushalt)",
                haushaltname: "Name (Haushalt)",
                haushaltname2: "Name 2 (Haushalt)",
                haushaltvorname: "Vorname (Haushalt)",
                heimatort: "Heimatort",
                homepage: "Homepage",
                idEinheit: "ID Bezirk Person",
                id_gemeinde: "Gemeinde",
                id_gruppe: "Gruppe",
                id_haushalt: "Haushalt ID",
                id_status: "Status",
                inaktiv: "Gelöscht",
                info: "Verknüpft",
                jsname: "JS-Name",
                landkurz: "Land",
                ledig: "Ledig Name",
                mitgliednr: "Mitgliedernummer",
                mitgliedsbeitrag: "Mitgliedsbeitrag",
                name: "Name",
                ort: "Ort",
                person_id: "Personen ID",
                plz: "PLZ",
                q: "Suche",
                sperr: "Sperre",
                sprache: "Sprache",
                strasse: "Strasse Nr",
                telefon_g: "Telefon Geschäft",
                telefon_p: "Telefon privat",
                titel: "Titel",
                untersagung: "Untersagung",
                vbpknr: "VbPK-Nr",
                verknuepft: "Verknüpfte Personen",
                vorname: "Vorname",
                weiterevornamen: "Weitere Vornamen",
                zivilstand: "Zivilstand",
                zusatz: "Adresszusatz"
            },
            invite: "Eine erste Person erstellen?"
        },
        authProfil: {
            fields: {
                email: "E-Mail",
                lang: "Sprache",
                passwort2: "Passwort Wiederholung",
                plz_ort: "PLZ Ort",
                strasse: "Strasse Nr"
            }
        },
        authUser: {
            fields: {
                benutzername: "Benutzername",
                email: "E-Mail",
                id_bezirk: "Einheit",
                id_einheit: "Einheit",
                id_fw_gruppe: "Benutzergruppe",
                id_sland: "Land",
                id_strang: "Strang",
                lang: "Sprache",
                name: "Name",
                passwort: "Passwort erstellen und an Benutzer senden",
                plz_ort: "PLZ Ort",
                q: "Suche",
                readonly: "Nur anzeigen / drucken",
                rollename: "Rollenname",
                sicherheit: "Sicherheitsstufe",
                strasse: "Strasse Nr",
                vorname: "Vorname"
            }
        },
        setEinheit: {
            fields: {
                bezeichnung: "Bezeichnung",
                id_admin: "Admin",
                id_bezirkperson: "ID Einheit Person",
                id_emkbezirk: "Bezug EMK Bezirk",
                id_sland: "Land",
                id_strang: "Strang",
                ityp: "Einheit"
            }
        }
    },
    se: {
        allgemein: {
            Adresszusatz: "Adresszusatz",
            Alter: "Alter",
            Amt: "Amt",
            Ausgefuehrt: "Ausgeführt.",
            Bezirk: "Bezirk",
            Domain: "emk-web.ch",
            Einheit: "Einheit",
            Geboren: "Geboren",
            Land: "Land",
            Mitteilung: "Mitteilung",
            Name: "Name",
            Ort: "Ort",
            Personen: "Personen",
            Plz: "PLZ",
            StrasseNr: "Strasse Nr",
            Vorname: "Vorname",
            Zuweisung: "Zuweisung"
        },
        spezial: {
            Aemterliste: "Ämterliste",
            Beerdigung: "Beerdigung",
            FolgenderTextWurdeVersendet: "Folgender Text wurde versendet",
            IhreAktionWurdeAusgefuehrt: "Ihre Aktion wurde ausgeführt. Eine Mitteilung wurde an die verantwortliche Person gesendet.",
            Pfarrer: "Pfarrer",
            TextStreichung: "%s wurde vom bearbeitendenden Bezirk gestrichen. Da Sie diese Person als verknüpfte Person führen, ist sie nun zur Bearbeitung Ihrem Bezirk zugewiesen worden.",
            TextUeberweisung: "Eine Person wurde vom Bezirk %s auf den Bezirk %s ueberwiesen: %s"
        }
    }
}
export default langDE;