import { SelectField, useTranslate } from 'react-admin';

const SelectSimpleField = (props) => {
  const ta = useTranslate();
  var choices = [];
  if (props.simpchoices) {
    props.simpchoices.split(';').forEach((c)=>{
      if (c.includes('|')) {
        const w = c.split('|');
        const num = parseInt(w[0]);
        if (w[1].indexOf('.') > -1) {
          w[1] = ta(w[1]);
        }
        if (isNaN(num)) {
          choices.push({id:w[0], name:w[1]});
        }
        else {
          choices.push({id:num, name:w[1]});
        }
      }
      else {
        if (c.indexOf('.') > -1) {
          c = ta(c);
        }
        choices.push({id:c, name:c});
      }
    })
    props ={...props, choices:choices };
  }
  props ={...props, choices:choices };
  return (
    <SelectField {...props} />
  )
}

export default SelectSimpleField;
