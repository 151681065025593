import React, { useState } from 'react';
import { List, Create, Edit, Datagrid, SimpleForm, TextField, required, TextInput } from 'react-admin';
import CondEditButton from '../lib/CondEditButton';
import CloseToolbar from '../lib/CloseToolbar';
import Move from '../lib/Move';

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  return (
  <>
    <List {...props} bulkActionButtons={false} title={'ew.addr.filter.title'} sort={{ field: 'pos', order: 'ASC' }} perPage={25}>
      <Datagrid bulkActionButtons={false} style={{ width: 'auto' }}>
        <Move label="glob.move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="addrFilter" />
        <TextField source="filtername" sortable={false}/>
        <CondEditButton label="" />
      </Datagrid>
    </List>
  </>
  )
}

const CEForm = (props) => {
  return (
    <SimpleForm {...props} toolbar={<CloseToolbar />}>
        <TextInput source="filtername" validate={required()} />
    </SimpleForm>
  )
};

const addrFilter = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),

  create: (props) => (
    <Create {...props} title={'ew.set.gemeinde.titleCreate'} redirect="list">
      <CEForm {...props}/>
    </Create>
  ),

  edit: (props) => (
    <Edit title={'ew.set.gemeinde.titleEdit'} mutationMode="optimistic" {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default addrFilter
