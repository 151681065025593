import React from 'react';
import { Edit, SimpleForm, List, Datagrid, useLocaleState,
  EditButton, ReferenceInput, AutocompleteInput, DateInput,
  ReferenceField, TextField, DateField
  } from 'react-admin';
import { isHauptadmin } from '../lib/globalData';
import CloseToolbar from '../lib/CloseToolbar';


const ListForm = (props) => {
  const [ locale ] = useLocaleState();
  const {classes, ...rest} = props;
  const bezfield = "bezeichnung_"+(locale!=='en'?locale:'de');
  const felderFilters = [
    <ReferenceInput source="id_ch_funktion" reference="addrChfunktion" perPage={1000} sort={{}} alwaysOn resettable>
      <AutocompleteInput optionText={bezfield} sx={{minWidth:'250px'}} />
    </ReferenceInput>,
    <ReferenceInput source="id_person" reference="addrFullname" perPage={1000} sort={{}} alwaysOn resettable>
      <AutocompleteInput optionText="fullname" sx={{minWidth:'250px'}} />
    </ReferenceInput>,
    <DateInput locales="de-DE" source="von" alwaysOn resettable/>,
    <DateInput locales="de-DE" source="bis" alwaysOn resettable/>,
  ];
  return (
  <>
    <List {...rest} filters={felderFilters} title={'ew.addr.amthist.title'}  sort={{}} perPage={25} >
      <Datagrid bulkActionButtons={false} style={{ width: 'auto' }}>
        <ReferenceField source="id_ch_funktion" reference="addrChfunktion" link={false}>
          <TextField source={bezfield} />
        </ReferenceField>,
        <ReferenceField source="id_person" reference="addrFullname" link={(record, reference) => `/addrPerson/${record.id_person}`}>
          <TextField source="fullname" />
        </ReferenceField>,
        <DateField locales="de-DE" source="von" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }}/>
        <DateField locales="de-DE" source="bis" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }}/>
      {isHauptadmin() && <TextField source="id"/>}
        <EditButton label="" />
      </Datagrid>
    </List>
  </>
  )
}

const CEForm = (props) => {
  const [ locale ] = useLocaleState();
  const bezfield = "bezeichnung_"+(locale!=='en'?locale:'de');
  return (
    <SimpleForm {...props} toolbar={<CloseToolbar />}>
      <ReferenceInput source="id_ch_funktion" reference="addrChfunktion" perPage={1000} sort={{}}>
        <AutocompleteInput optionText={bezfield} sx={{minWidth:'250px'}} />
      </ReferenceInput>
      <ReferenceInput source="id_person" reference="addrFullname" perPage={1000} sort={{}}>
        <AutocompleteInput optionText="fullname" sx={{minWidth:'250px'}} />
      </ReferenceInput>
      <DateInput locales="de-DE" source="von" sx={{minWidth:'250px'}}/>
      <DateInput locales="de-DE" source="bis" sx={{minWidth:'250px'}}/>
    </SimpleForm>
  )
};

const addrAmthist = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),

  edit: (props) => (
    <Edit title={'ew.addr.amthist.titleEdit'} mutationMode="optimistic" {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default addrAmthist
