import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS  } from 'react-admin';
import { apiPath } from './isTest';
import { setGlobalData, getGlobalData } from './globalData';

const Provider = (type, params) => {
    if (type === AUTH_LOGIN) {
        const request = new Request(apiPath+'authToken', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
          .then(response => {
            if (response.ok) {
              return response.json().then(json => {
                setGlobalData({token:json.token, info:json.info})
                localStorage.setItem('token', json.token);
                localStorage.setItem('info', JSON.stringify(json.info));
              }).catch(err => {
                throw new Error('token:no JSON');
              });

            } else {
              return response.json().catch(err => {
                throw new Error(response.statusText);
              }).then(json => {
                throw json.message;
              });
            }
      });
    }
    if (type === AUTH_LOGOUT) {
        setGlobalData({token:false, info:false})
        localStorage.removeItem('token');
        localStorage.removeItem('lastmenu');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401) {
          setGlobalData({token:false, info:false})
          localStorage.removeItem('token');
          localStorage.removeItem('lastmenu');
          window.location.hash = '/login';
          return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        var token = getGlobalData('token');
        if (!token) {
          token = localStorage.getItem('token')
        }
        return token
            ? Promise.resolve()
            : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
      const info = getGlobalData('info');
      return (info && info.level) ? Promise.resolve(info.level) : Promise.reject();
    }
    return Promise.reject('Unkown method');
};
export default Provider;
