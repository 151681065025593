import React from 'react';
import { Create, Edit, SimpleForm, required, List, useRecordContext
  , TextField
  , ReferenceInput, BooleanInput, TextInput, SelectInput, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput, EditButton
  } from 'react-admin';
import CloseToolbar from '../lib/CloseToolbar';
import DataGridDnd from '../lib/DataGridDnd';


const gruppeFilters = [
  <SelectInput source="active" alwaysOn emptyValue={1} emptyText="ew.addr.filter.nuraktiv" choices={[{id:0,name:'ew.addr.filter.nurinaktiv'},{id:-1,name:'ew.addr.filter.alle'}]} />,
];

const ListForm = (props) => {

  const TextFieldStyled = ({ source }) => {
    const record = useRecordContext();
    const sx = record.inaktiv?{ textDecoration: 'line-through' }:{}
    return <TextField source={source} sx={sx} />;
  }

  return (
  <>
    <List {...props} bulkActionButtons={false} title={'ew.set.gruppe.title'} filters={gruppeFilters} sort={{ field: 'lft', order: 'ASC' }} perPage={999} pagination={false} >
      <DataGridDnd style={{ width: 'auto' }} resource="setGruppe">
        <TextFieldStyled source="bezeichnung" />
        <EditButton label="" />
      </DataGridDnd>
    </List>
  </>
  )
}

const CEForm = (props) => {
  return (
    <SimpleForm {...props} toolbar={<CloseToolbar />}>
      <ReferenceInput source="id_gemeinde" reference="setGemeinde" perPage={1000} sort={{}}>
        <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} />
      </ReferenceInput>
      <TextInput source="bezeichnung" validate={required()} />
      <ReferenceInput source="id_struktur_funktion" reference="genStrukturfunk" perPage={1000} sort={{}}>
        <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} />
      </ReferenceInput>
      <BooleanInput source="kool" />
      <ReferenceArrayInput source="strukturen" reference="genStruktur" >
        <AutocompleteArrayInput optionText="bezeichnung" sx={{minWidth:'400px', '.MuiAutocomplete-inputRoot':{display:'block'}, '.MuiAutocomplete-tag':{display:'block'}}} />
      </ReferenceArrayInput>  
    </SimpleForm>
  )
};

const setGruppe = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),

  create: (props) => (
    <Create {...props} title={'ew.set.gruppe.titleCreate'} redirect="list">
      <CEForm {...props}/>
    </Create>
  ),

  edit: (props) => (
    <Edit title={'ew.set.gruppe.titleEdit'} mutationMode="optimistic" {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default setGruppe
