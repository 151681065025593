import React, { useState } from 'react';
import { useEditContext, useRecordContext, useTranslate, useListContext, useDataProvider, useRedirect, useNotify, useStore,
  regex, email, FormDataConsumer ,
  ListBase, Pagination, Create, Edit, Datagrid, TabbedForm, FilterForm, CreateButton, SimpleForm,
  ArrayField, ReferenceField, DateField,
  TextField, FormTab, ReferenceArrayInput, AutocompleteArrayInput,
  EditButton, ReferenceInput, TextInput, SelectInput, DateInput, AutocompleteInput, BooleanInput,
  ArrayInput, SimpleFormIterator } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import ActionsDialog from '../act/ActionsDialog';
import AdvancedFilter from './AdvancedFilter';
import CloseToolbar from '../lib/CloseToolbar';
import DatagridConfigurable from '../lib/DatagridConfigurable.tsx';
import { SelectColumnsButton } from '../lib/SelectColumnsButton.tsx';
import { TextInputGrid, BooleanInputGrid, DateInputGrid, SelectInputGrid, ReferenceInputGrid, BirthDateInputGrid } from '../lib/gridInput';
import { Stack, Grid, Dialog, DialogTitle, DialogContent, Paper, Button } from '@mui/material';
import { Close, Launch, FilterList  } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

const personenFilters = [
  <TextInput source="q" alwaysOn resettable />,
  <SelectInput source="active" alwaysOn emptyValue={1} emptyText="ew.addr.filter.nuraktiv" choices={[{id:0,name:'ew.addr.filter.nurinaktiv'},{id:-1,name:'ew.addr.filter.alle'},{id:-2,name:'Zwischenablage'}]} resettable />,
  <ReferenceInput source="id_gruppe" reference="setGruppe" perPage={1000} sort={{ field: 'lft', order: 'ASC' }} alwaysOn resettable >
    <AutocompleteInput optionText="pfad" sx={{minWidth:'300px'}} fullWidth />
  </ReferenceInput>,
];

const ListForm = (props) => {
  const tr = useTranslate();

// Checkbox "Alle Personen anzeigen"
  let showaction = false;
  const p = window.location.hash.split('?');
  if (p[1]) {
    const parts = p[1].split("&");
    var get = {};
    for (var i = 0; i < parts.length; i++) {
      var temp = parts[i].split("=");
      get[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
    }
    if (get.showaction) {
      showaction = {id: parseInt(get.showaction), vorname:get.name, name:''};
    }
  }
  
  const [actDialogState, setActDialogState] = useState({disp:!!showaction, sel:showaction?showaction:false});
  const [showAdf, setShowAdf] = useStore('showAdf', false);
  const {classes, ...rest} = props;
  
  const showActionDialog = (rec) =>  (ev) => {
    setActDialogState({disp:true, sel:rec});
  };  
  const closeActionDialog = () => {
    setActDialogState({disp:false, sel:actDialogState.sel});
  };  
  const ShowActions = () => {
    const record = useRecordContext();
    return (
      <Button onClick={showActionDialog(record)}><Launch /></Button>
    )
  }

  const clickSowAdf = () => {
    setShowAdf(!showAdf);
  }

  const ListToolbar = () => {
    const { total } = useListContext();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
      <>
        <Pagination sx={{'& p': {margin:0}, '& > div': {minHeight:'40px'}}} />
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{".filter-field[data-source='id_gruppe']":{flex:0.6}}} flexWrap={isSmall?"wrap":""}>
            <FilterForm filters={personenFilters} />
            <Stack alignItems="flex-start">
                <SelectColumnsButton label="hallo" />
                <Button sx={{padding:'0', marginRight:'16px', whiteSpace:"nowrap"}} onClick={clickSowAdf}><FilterList size="small" sx={{margin:'0 8px 0 -2px'}} />{tr('ew.addr.person.erwfilter')}</Button>
            </Stack>
            <Stack alignItems="flex-start">
                <Button sx={{padding:'0'}} onClick={showActionDialog({total:total})}><Launch size="small" sx={{margin:'0 8px 0 -2px'}} />{tr('ew.addr.person.aktionen')}</Button>
                <CreateButton />
            </Stack>
        </Stack>
      </>
    )
  }

  const PersonBulkActionButtons = () => {
    const { selectedIds } = useListContext();
    return (
      <>
        <Button onClick={showActionDialog(selectedIds)}><Launch /> Aktionen</Button>
      </>
    );
  }

  return (
  <>
    <ListBase {...rest} title={'ew.addr.person.title'} sort={{ field: 'name', order: 'ASC' }} perPage={25} >
      <ListToolbar />
    {showAdf &&
      <AdvancedFilter setShowAdf={setShowAdf} />
    }
      
      <DatagridConfigurable 
          omit={['email_p', 'handy', 'geboren', 'bezirk', 'sperre', 'id', 'id_haushalt']}
          style={{ width: 'auto' }} sx={{'& > div > .RaBulkActionsToolbar-toolbar':{
          position:'static ', 
          transform: 'translateY(0)'}}} bulkActionButtons={<PersonBulkActionButtons />} 
          rowStyle={(record, index)=>({backgroundColor: record.extern?(record.info?'#AED461':'#9ACFEC'):''})}>
        <TextField source="vorname"/>
        <TextField source="name"/>
        <TextField source="haushalt"/>
        <TextField source="email_p"/>
        <TextField source="handy"/>
        <TextField source="geboren"/>
        <TextField source="bezirk"/>
        <TextField source="id"/>
        <TextField source="id_haushalt"/>
        <TextField source="sperre"/>
        <EditButton label="" />
        <ShowActions />
      </DatagridConfigurable>
      <Pagination />
      <ActionsDialog actDialogState={actDialogState} closeActionDialog={closeActionDialog} />
    </ListBase>
  </>
  )
}

const FlexInputGrid = (props) => {
/*
  0:Textfeld
  1:Datum
  2:Auswahl
  3:Mehrzeilig,
  4:JaNeinFeld
*/
  if (props.typ===0) {
    return (<TextInputGrid {...props} />);
  }
  else if (props.typ===1) {
    return (<DateInputGrid {...props} />);
  }
  else if (props.typ===2) {
    return (<SelectInputGrid {...props} />);
  }
  else if (props.typ===3) {
    return (<TextInputGrid {...props} multiline />);
  }
  else if (props.typ===4) {
    return (<BooleanInputGrid {...props} />);
  }
}

const PersonZeile = (props) => {
  const record = useRecordContext();
  return (
    <Paper sx={{padding:'16px', margin:'10px'}}>
      <div><b>{record.vorname} {record.name}</b>, {record.strasse}, {record.plz} {record.ort}, {record.handy?record.handy+', ':''}{record.email_p}</div>
    {record.hauspers && record.hauspers.length>0 &&
      <div>
        Personen im Haushalt:&nbsp;
      {record.hauspers.map((p,i)=>(
        <span key={i}>{i > 0?' / ':''}<a href={"/#/addrPerson/"+p.id+"/1"}>{p.name} ({p.alter})</a></span>
      ))} 
      </div>
    }      
    </Paper>
  
  )
}

const EditForm = (props) => {
  const [editHaushalt, setEditHaushalt] = useState(false);
  return (    
    <Edit title={'ew.addr.person.titleEdit'} mutationMode="optimistic" {...props} mutationOptions={{ meta: { editHaushalt } }} >
      <CEForm {...props} editHaushalt={editHaushalt} setEditHaushalt={setEditHaushalt}/>
    </Edit>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, editHist, setEditHist, editHaushalt, setEditHaushalt, ...props}) => {
  const tr = useTranslate();
  const [selectHaushalt, setSelectHaushalt] = useState(false);
  const validatePhone = regex(/^[0-9+ ]*$/, tr('glob.validatePhone'));
  const { record } = useEditContext();

  const HaushaltActions = (props) => {
    const { setValue } = useFormContext();

    const hausEdit = () => {
      setEditHaushalt(!editHaushalt) ;
    }
    const hausCreate = () => {
      ['haushaltanrede','haushaltvorname','haushaltname','haushaltname2','strasse','zusatz','plz','ort','landkurz',]
        .forEach((feld) => {setValue(feld, '', {shouldDirty: true})});
      setValue('id_haushalt', 0, {shouldDirty: true});
      setEditHaushalt(true) ;
    }
    
    return (
      <Stack direction="row" spacing={2} sx={{marginBottom:'10px'}}>
        <Button onClick={hausEdit} variant="contained">Haushaltdaten ändern</Button>
        <Button onClick={hausCreate} variant="contained">Neuen Haushalt erstellen</Button>
        <Button onClick={()=>setSelectHaushalt(true)} variant="contained">Bestehenden Haushalt zuweisen</Button>
      </Stack>
    )    
  }
  
  const AssignHaushalt = () => {
    const dataProvider = useDataProvider();
    const { getValues, setValue } = useFormContext();
    const hausAssign = () => {
      dataProvider.getOne('addrHaushalt', { id: getValues('id_haushalt')}).then(response => { 
        if (response.data) { 
          ['haushaltanrede','haushaltvorname','haushaltname','haushaltname2','strasse','zusatz','plz','ort','landkurz',]
            .forEach((feld) => {setValue(feld, response.data[feld], {shouldDirty: true})});
          setSelectHaushalt(false);
        }
      });
      
    }
    
    if (!selectHaushalt) return null;
    return (
      <Dialog onClose={()=>setSelectHaushalt(false)} open={selectHaushalt} maxWidth="md" >
        <Close onClick={()=>setSelectHaushalt(false)} sx={{ position:'absolute', right:'8px', top:'8px', cursor:'pointer'}} />
        <DialogTitle>{tr('ew.addr.haushalt.select.title')}</DialogTitle>
        <DialogContent>
          <ReferenceInput source="id_haushalt" reference="addrHaushalt" perPage={1000} sort={{}} alwaysOn>
            <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'300px'}} />
          </ReferenceInput>,
          <Button onClick={hausAssign} variant="contained">Übernehmen</Button>
        </DialogContent>
      </Dialog>
    )  
  }
  
  const FreieFelder = (props) => {
    const record = useRecordContext();
    if (!record.freie) return null;
    return (
      <FormTab {...props} label="Lokal">
        <Grid container columnSpacing={2}>
      {record.freie.map((f)=> (
          <FlexInputGrid source={"frei_"+f.freiesfeld_id} label={f.bezeichnung} key={f.freiesfeld_id} typ={f.typ} simpchoices={f.optionen} helperText={f.tip} />
      ))}             
        </Grid>
      </FormTab>
    )
  }
  
  const GruppenHist = (props) => (
    <>
      <BooleanInput source="changeHist" />
      <FormDataConsumer>
    {({ formData, ...rest }) => formData.changeHist?
      <ArrayInput source="gruppehist">
        <SimpleFormIterator inline disableReordering disableRemove>
          <ReferenceInput source="id_gruppe" reference="setGruppe" perPage={1000} sort={{ field: 'lft', order: 'ASC' }} >
            <AutocompleteInput optionText="pfad" sx={{minWidth:'300px'}} />
          </ReferenceInput>
          <DateInput source="von" helperText={false} />
          <DateInput source="bis" helperText={false} />
        </SimpleFormIterator>
      </ArrayInput>
    :
      <ArrayField source="gruppehist">
        <Datagrid bulkActionButtons={false}>
          <ReferenceField source="id_gruppe" reference="setGruppe" sort={{ field: 'lft', order: 'ASC' }} >
            <TextField  source="pfad" />
          </ReferenceField>
          <DateField source="von" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} />
          <DateField source="bis" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} />
        </Datagrid>
      </ArrayField>
    }
      </FormDataConsumer>
    </>
  )
  
  return (
    <>
      <PersonZeile />
      <TabbedForm {...props} sx={{marginTop:'1em', borderTop: '1px solid #CCD'}} toolbar={<CloseToolbar noDelete />}> 
        <FormTab label="ew.addr.tab.haushalt">
          {!record.extern && <HaushaltActions /> }
          <Grid container columnSpacing={2}>
            <TextInputGrid source="haushaltanrede" disabled={!editHaushalt} />
            <TextInputGrid source="haushaltvorname" disabled={!editHaushalt} />
            <TextInputGrid source="haushaltname" disabled={!editHaushalt} />
            <TextInputGrid source="haushaltname2" disabled={!editHaushalt} />
            <TextInputGrid source="strasse" disabled={!editHaushalt} />
            <TextInputGrid source="zusatz" disabled={!editHaushalt} />
            <TextInputGrid source="plz" disabled={!editHaushalt} />
            <TextInputGrid source="ort" disabled={!editHaushalt} />
            <TextInputGrid source="landkurz" disabled={!editHaushalt} />
            <AssignHaushalt />
          </Grid>
        </FormTab>
        <FormTab label="ew.addr.tab.person">
          <Grid container columnSpacing={2}>
            <TextInputGrid source="vorname" disabled={record.extern} />
            <TextInputGrid source="name"  disabled={record.extern} />
            <SelectInputGrid source="anrede" simpchoices="Frau;Herr;Schwester;Madame;Monsieur;Soeur;EMK" disabled={record.extern} />
            <TextInputGrid source="titel" disabled={record.extern} />
            <TextInputGrid source="weiterevornamen" disabled={record.extern} />
            <TextInputGrid source="ledig" disabled={record.extern} />
            <BooleanInputGrid source="du" disabled={record.extern} />
            <SelectInputGrid source="sprache" simpchoices="de;fr;en;es;pt" emptyText="glob.choices.andere" disabled={record.extern}  />
            <SelectInputGrid source="geschlecht" simpchoices="1|glob.choices.weiblich;2|glob.choices.maennlich" emptyValue={0} emptyText="--" disabled={record.extern} />
            <BirthDateInputGrid source="geboren" disabled={record.extern} />
          </Grid>
        </FormTab>
        <FormTab label="ew.addr.tab.kontakt">
          <Grid container columnSpacing={2}>
            <TextInputGrid source="telefon_p" validate={validatePhone} disabled={record.extern} />
            <TextInputGrid source="email_p" validate={email()} disabled={record.extern} />
            <TextInputGrid source="email_g" validate={email()} disabled={record.extern} />
            <TextInputGrid source="fax_p"  validate={validatePhone} disabled={record.extern} />
            <TextInputGrid source="handy"  validate={validatePhone} disabled={record.extern} />
            <TextInputGrid source="telefon_g"  validate={validatePhone} disabled={record.extern} />
            <TextInputGrid source="fax_g"  validate={validatePhone} disabled={record.extern} />
            <TextInputGrid source="homepage" disabled={record.extern} />
          </Grid>
        </FormTab>
        <FormTab label="ew.addr.tab.daten">
          <Grid container columnSpacing={2}>
            <SelectInputGrid source="zivilstand" simpchoices="1|glob.choices.ledig;2|glob.choices.verheiratet;3|glob.choices.verwitwet;5|glob.choices.getrennt;4|glob.choices.geschieden" emptyValue={0} emptyText="--" disabled={record.extern} />
            <TextInputGrid source="heimatort" disabled={record.extern} />
            <TextInputGrid source="beruf" disabled={record.extern} />
            <TextInputGrid source="jsname" disabled={record.extern} />
          </Grid>
        </FormTab>
        <FormTab label="ew.addr.tab.emk">
          <Grid container columnSpacing={2}>
            <ReferenceInputGrid source="id_gemeinde" reference="setGemeinde" sort={{ field: 'bezeichnung', order: 'ASC' }} perPage={1000}>
              <AutocompleteInput optionText="bezeichnung" disabled={record.extern} />
            </ReferenceInputGrid>
            <SelectInputGrid source="id_status"  simpchoices="1|glob.choices.stGetauft;2|glob.choices.stBekennend;3|glob.choices.stFreund;7|glob.choices.stKind;5|glob.choices.stGruppe;9|glob.choices.stAusgetreten;11|glob.choices.stGast;13|glob.choices.stAssoziert" emptyValue={4} emptyText="--" disabled={record.extern} />
            <TextInputGrid source="mitgliednr" disabled={record.extern} />
{/*            
            <TextInputGrid source="vbpknr" disabled={record.extern} />
            <BooleanInputGrid source="untersagung" disabled={record.extern} />
            <TextInputGrid source="mitgliedsbeitrag" disabled={record.extern} />
*/}            
            <TextInputGrid source="bemerkungen" disabled={record.extern} multiline />
          </Grid>
        </FormTab>
        <FreieFelder />
        <FormTab label="ew.addr.tab.gruppen">
          <ReferenceArrayInput source="gruppen" reference="setGruppe" sort={{ field: 'lft', order: 'ASC' }} perPage={1000} >
            <AutocompleteArrayInput optionText="pfad" sx={{minWidth:'400px', '.MuiAutocomplete-inputRoot':{display:'block'}, '.MuiAutocomplete-tag':{display:'block'}, '.MuiAutocomplete-input':{width:'100% !important'}}} />
          </ReferenceArrayInput>  
        </FormTab>
        <FormTab label="ew.addr.tab.gruppenhist">
          <GruppenHist />
        </FormTab>
      </TabbedForm>
    </>    
  )
};

const CreateForm = (props) => {
  const dataProvider = useDataProvider();
  const tr = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const [found, setFound] = useState([]);

  const findPerson = (values) => {
    dataProvider.getList('addrPerson/find', { id: 0, filter: values, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(response => { setFound(response.data) });
    return {};
  }

  const verkn = (id) => {
    console.log(['id', id])
    dataProvider.update('addrPerson/verknuepfen', { id })
      .then(res => { 
        const data = res.data;
        notify(data.msg);
        if (data.aktion > 0) {
          redirect('edit', 'addrPerson', data.id);
        }
        
      });
  }
  
  return (
    <Create {...props} title={'ew.addr.person.titleCreate'} redirect="edit">
      <Stack direction="row" spacing={2} >
        <SimpleForm mode="onChange" validate={findPerson}>
            <TextInputGrid source="vorname" />
            <TextInputGrid source="name" />
        </SimpleForm>
    {found.length>0 &&
        <div>
          <h3>{tr('ew.addr.person.MoeglicheVerknuepfungen')}</h3>
          <table style={{marginBottom:'1em'}}>
            <tbody>
      {found.map((it)=>(
              <tr key={it.id}>
                <td style={{backgroundColor:it.eigener?'':'#aed461', padding:'1em'}}>
                  <div>{it.vorname} {it.name}</div>
                  <div>{it.strasse}</div>
                  <div>{it.plz} {it.ort}</div>
                </td>
                <td>
                  <Button onClick={()=>verkn(it.id)}>{tr('ew.addr.person.Verknuepfen')}</Button>
                </td>
              </tr>
      ))}
            </tbody>
          </table>
        </div>
    }
    </Stack>
    </Create>
  )
}

const authUser = {
  list: (props) => (
    <ListForm {...props} />
  ),
  
  create: (props) => (
    <CreateForm {...props} />
  ),
  
  edit: (props) => (
    <EditForm {...props} />
  )
}   
export default authUser