import React from 'react';
import { useListContext, useCreateContext, useTranslate, useNotify, CreateBase, SimpleForm, required, Toolbar, SaveButton,
  TextInput, DateInput, BooleanInput } from 'react-admin';
import { DialogContent } from '@mui/material';
 
const HandCreateForm = ({sub, count, manyIds}) => {
  const { filterValues } = useListContext();
  const { save } = useCreateContext();
  
  const transformAction = (filterValues) => data => ({
    ...data, filter: filterValues
  });
  
  const HandlungToolbar = ({count, sub}) => {
    const ta = useTranslate();      
    return(
      <Toolbar>
          <SaveButton type="button" label={ta('ew.act.handlung.exec-'+sub, {smart_count:count})} transform={transformAction(filterValues)} />
      </Toolbar>
    );
  };
  
  return (
    <SimpleForm onSubmit={save} toolbar={<HandlungToolbar count={count} sub={sub} />}>
      <DateInput source="datum" validate={[required()]} />
    {sub==='tod' &&  
      <DateInput source="beerdigung" helperText={false} />
    }
      <TextInput source="pfarrer" fullWidth helperText={false} />
      <TextInput source="ort" fullWidth helperText={false} />
      <TextInput source="vers" fullWidth helperText={false} />
      <TextInput source="bemerkung" multiline minRows={3} fullWidth helperText={false} />
    {(sub==='tauf2' || sub==='bek' || sub==='tod' ) &&  
      <BooleanInput source="mitteilung" helperText={false} />
    }
    </SimpleForm>
  )  
}

const ActHandlung = (props) => {
  const ta = useTranslate();      
  const notify = useNotify();
  const {sub, count, manyIds, closeDialog } = props;

  const onSuccess = (data) => {
    notify(data.message);
    if (data.id > 0) {
      closeDialog();
    }
  };

  return (
    <DialogContent>
      <div style={{fontWeight:'bold', fontSize:'1.2rem'}}>{ta('ew.act.handlung.'+sub)}</div>
      <CreateBase resource="addrAktion" record={{id: manyIds, sub}} mutationOptions={{ onSuccess }} >
        <HandCreateForm count={count} manyIds={manyIds} sub={sub} />
      </CreateBase>
    </DialogContent>
  );
}

export default ActHandlung
