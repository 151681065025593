import React from 'react';
import { ListBase, Datagrid, ReferenceField, TextField } from 'react-admin';
import { DialogContent } from '@mui/material';
 
const ActHistory = (props) => {
  const {manyIds } = props;
  return (
    <>
      <DialogContent>
        <ListBase resource="addrHistory/person" filter={{id_person:manyIds}} >
          <Datagrid bulkActionButtons={false} style={{ width: 'auto' }}>
            <ReferenceField source="id_benutzer" reference="authUser/username" link={false}>
              <TextField source="benutzername" />
            </ReferenceField>
            <TextField source="zeit" sortable={false}/>
            <TextField source="text" sortable={false}/>
          </Datagrid>
        </ListBase>
      </DialogContent>
    </>
  );
}
  
export default ActHistory

