import React, { useState, useEffect } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { DialogContent } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';

 
const ActVerknDisp = ({ count, manyIds }) => {
  const ta = useTranslate();      
  const [ einheiten, setEinheiten ] = useState([]);
  const [ verkn, setVerkn ] = useState([]);
  const dataProvider = useDataProvider();
  
  useEffect(() => {
    dataProvider.getList('setEinheit', { id: 0, filter: { }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(response => { if (response.data) { setEinheiten(response.data.reduce((obj,f)=>({...obj, [f.id]:f.bezeichnung}), {}))}});
  }, [dataProvider, manyIds]);
  
  useEffect(() => {
    if (manyIds) {
      dataProvider.getList('setInfoempf', { id: 0, filter: { id_person:manyIds }, sort: { }, pagination: { page: 1, perPage: 999 },})
        .then(response => { if (response.data) { setVerkn(response.data) }});
    }
  }, [dataProvider, manyIds]);
  
  if (verkn.length < 1 || einheiten.length < 1) return null;
  
  return (
    <DialogContent>
      <DialogTitle>{ta('ew.act.verkndisp.titel')}</DialogTitle>
      <p>{ta('ew.act.verkndisp.text')}</p>
  {verkn.map((k, x)=> (
        <div>{einheiten[k.id_bezirk]}</div>         
  ))}
      <p><i>{ta('ew.act.verkndisp.text2')}</i></p>
    </DialogContent>
  );
}

export default ActVerknDisp
