import React from "react";
import { useTranslate } from "react-admin";
import { Autocomplete, TextField } from "@mui/material";

const ControlledAutoComplete = ({optionLabel, value, label, error, ...rest}) => {
  const ta = useTranslate();
  if (rest.simpchoices) {
    rest.options = [];
    rest.simpchoices.split(/[;,]/).forEach((c)=>{
      if (c.includes('|')) {
        const w = c.split('|');
        rest.options.push({id:w[0], [optionLabel]:w[1]});
      }
      else {
        rest.options.push({id:c, [optionLabel]:c});
      }
    })
  }
  if (!rest.options || !rest.options.length) return null;
  var optval = rest.options[0];
  rest.options.forEach((f)=>{
    if (f.id === value) {
      optval = f;
    }
  })
  const getLabel = (option) => {
    const txt = option[optionLabel];
    if (!txt) return '';
    const m = txt.match(/\./g);
    if (m && m.length > 1) {
      return ta(txt);
    }
    return txt.replaceAll('#$', '|');
  }
  
  return (
    <Autocomplete {...rest} getOptionLabel={getLabel} value={optval} blurOnSelect 
      renderInput={(params) => <TextField {...params} label={ta(label)} error={error} />}
    />
  );
};

export default ControlledAutoComplete;