import React from 'react';
import { TextInput, List, Datagrid, TextField } from 'react-admin';
import SelectSimpleInput from '../lib/SelectSimpleInput';
import SelectSimpleField from '../lib/SelectSimpleField';

const werkOptions="94|ew.set.sperr.chVersand;95|ew.set.sperr.kuw";

const felderFilters = [
  <TextInput label="Search" source="q" alwaysOn resettable />,
  <SelectSimpleInput label="Typ" source="id_bezirk" alwaysOn resettable simpchoices={werkOptions} />,
];

const ListForm = (props) => {
  const {classes, ...rest} = props;
  return (
  <>
    <List {...rest} filters={felderFilters} bulkActionButtons={false} title={'ew.set.sperr.title'} sort={{ field: 'name', order: 'ASC' }} perPage={25} >
      <Datagrid bulkActionButtons={false} style={{ width: 'auto' }}>
        <TextField source="vollername"/>
        <TextField source="bezeichnung" label="Bezirk"/>
        <SelectSimpleField source="id_bezirk" simpchoices={werkOptions}/>
      </Datagrid>
    </List>
  </>
  )
}

const setSperr = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),
}
export default setSperr
