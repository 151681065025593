import React from 'react';
import { Datagrid, TextField, SimpleShowLayout, TextInput,
          ReferenceManyField } from 'react-admin';
import SelectSimpleField from '../lib/SelectSimpleField';
import SelectSimpleInput from '../lib/SelectSimpleInput';
import MyList from '../lib/MyList';

const privatOptions="0|glob.choices.no;1|glob.choices.yes";
const privatFilterOptions="0|glob.choices.publiconly;1|glob.choices.privateonly";
const zeitOptions="0|glob.choices.today;1|glob.choices.sinceyesterday;7|glob.choices.sevendays;31|glob.choices.onemonth;365|glob.choices.oneyear;-1|glob.choices.all";

const felderFilters = [
  <SelectSimpleInput label="Zeit" source="zeit" alwaysOn resettable simpchoices={zeitOptions} />,
  <SelectSimpleInput label="" source="privat" alwaysOn resettable simpchoices={privatFilterOptions} emptyText="ew.set.mailhist.privatepublic" />,
  <TextInput source="email" alwaysOn />
];

const ShowReceipients = () => {
  return (
    <SimpleShowLayout>
      <ReferenceManyField label="Empfänger" reference="genMailempf" target="id_nachricht" sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <TextField source="vorname" />
          <TextField source="email" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  )
}


const ListForm = (props) => {
  const {classes, ...rest} = props;
  return (
  <>
    <MyList {...rest} filters={felderFilters} bulkActionButtons={false} noCreateButton={true} title={'ew.set.mailhist.title'} sort={{ field: 'zeit', order: 'DESC' }} perPage={25} >
      <Datagrid bulkActionButtons={false} style={{ width: 'auto' }} expand={<ShowReceipients />} >
        <TextField source="zeit"/>
        <TextField source="betreff"/>
        <TextField source="benutzername"/>
        <TextField source="anz"/>
        <SelectSimpleField source="privat" simpchoices={privatOptions}/>
      </Datagrid>
    </MyList>
  </>
  )
}

const genMailhist = {
  list: (props) => (
    <ListForm {...props} exporter={false} empty={false} />
  )
}
export default genMailhist
