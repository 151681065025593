import React from 'react';
import { useTranslate, useRecordContext, Edit, SimpleForm, TextInput, PasswordInput, Toolbar, SaveButton  } from 'react-admin';
import SelectSimpleInput from '../lib/SelectSimpleInput';

export const SaveToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const TotpCode = () => {
  const record = useRecordContext();
  return (
    <img src={record.otpcode} style={{margin:"1em 0"}} />
  )
}

const EForm = (props) => {
  const translate = useTranslate();
  return (
    <>
      <SimpleForm toolbar={<SaveToolbar />} {...props}>
        <div className="GridGroupTitle" style={{width:'95%'}} >{translate('ew.auth.profil.addrTitle')}</div>
        <TextInput source="strasse" />
        <TextInput source="plz_ort" />
        <TextInput source="email" />
        <div className="GridGroupTitle" style={{width:'95%'}} >{translate('ew.auth.profil.pwdTitle')}</div>
        <PasswordInput source="passwort" />
        <PasswordInput source="passwort2" />
        <div className="GridGroupTitle" style={{width:'95%'}} >{translate('ew.auth.profil.totp')}</div>
        <TotpCode />
        <div className="GridGroupTitle" style={{width:'95%'}} >{translate('ew.auth.profil.langTitle')}</div>
        <SelectSimpleInput source="lang" simpchoices="de|glob.choices.de;en|glob.choices.en;fr|glob.choices.fr" />
      </SimpleForm>
    </>
  )
};

const AuthProfil = ({ translate, ...props }) => {
  const redir = () => {
    return 'authProfil/1'
  };
  return (
    <Edit redirect={redir} title={'ew.auth.profil.titleEdit'} {...props}>
      <EForm {...props}/>
    </Edit>
  )
}
export default AuthProfil
