import { DateInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';

const BirthDateInput = (props) => {
  const { getValues } = useFormContext();
  const dat = getValues(props.source);
  const years = moment().diff(dat, 'years');
  console.log(dat);
  return (
    <DateInput {...props} InputProps={{
      endAdornment: <InputAdornment position="start">&nbsp; &nbsp; ({years} J)</InputAdornment>,
    }}/>
  )
}

export default BirthDateInput;
