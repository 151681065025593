import { EditButton } from 'react-admin';
import { getGlobalData } from './globalData';

const CondEditButton = ({ cond, ...props}) => {
  const info = getGlobalData('info');
  if(!info || typeof info.readonly === 'undefined' || info.readonly === 1)return null;
  if (cond && !props.record[cond])return null;
  return (
    <EditButton {...props} />
  )
}

export default CondEditButton;
