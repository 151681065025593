import React/*, { useState }*/ from 'react';
import { Edit, SimpleForm, List, Datagrid, useRecordContext,
  EditButton, ReferenceInput, AutocompleteInput, TextInput, BooleanInput, TextField } from 'react-admin';
import { apiPath } from '../lib/isTest';
import { isHauptadmin, getGlobalData } from '../lib/globalData';
import CloseToolbar from '../lib/CloseToolbar';
import Button from '@mui/material/Button';
import ListAltIcon from '@mui/icons-material/ListAlt';

const PersonField = () => {
  const record = useRecordContext();
  if (!record) return null;
  if (!record.id_person) return <span>vakant</span>
  return <span>{record.person}</span>;
}

const ListForm = (props) => {
  const token = getGlobalData('token');
  return (
  <>
    <List {...props} title={'ew.addr.amt.title'} pagination={false} perPage={999} filters={<Button href={apiPath+"addrAmt/printList?token="+token} startIcon={<ListAltIcon/>} target="_blank" alwaysOn>Excel Ämterliste</Button>}  >
      <Datagrid bulkActionButtons={false} style={{ width: 'auto' }}>
        <TextField source="bezeichnung_de" />
        <PersonField/>
      {isHauptadmin() && <TextField source="id"/>}
        <EditButton label="" />
      </Datagrid>
    </List>
  </>
  )
}

const CEForm = (props) => {
  return (
    <SimpleForm {...props} toolbar={<CloseToolbar noDelete/>}>
      <TextInput source="bezeichnung_de" disabled />
      <ReferenceInput source="id_person" reference="addrPerson" perPage={999}>
        <AutocompleteInput optionText={(c)=>{return (c.vorname?c.vorname+' ':'')+c.name}} sx={{minWidth:"300px"}} emptyValue={0} emptyText="vakant" />
      </ReferenceInput>
      <BooleanInput source="add" />
    </SimpleForm>
  )
};

const addrAmt = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),

  edit: (props) => (
    <Edit title={'ew.addr.amt.titleEdit'} mutationMode="optimistic" {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default addrAmt
