import React from 'react';
import { Create, Edit, SimpleForm, required, TextInput,
  List, Datagrid, TextField
  } from 'react-admin';
import { isHauptadmin } from '../lib/globalData';
import SelectSimpleInput from '../lib/SelectSimpleInput';
import SelectSimpleField from '../lib/SelectSimpleField';
import CloseToolbar from '../lib/CloseToolbar';
import CondEditButton from '../lib/CondEditButton';

const typOptions="0|glob.choices.textfeld;1|glob.choices.datum;2|glob.choices.auswahl;3|glob.choices.mehrzeilig;4|glob.choices.janeinfeld";

const felderFilters = [
  <TextInput label="Search" source="q" alwaysOn resettable />,
  <SelectSimpleInput label="Typ" source="typ" resettable simpchoices={typOptions} />,
];

const ListForm = (props) => {
  const {classes, ...rest} = props;

  return (
  <>
    <List {...rest} filters={felderFilters} bulkActionButtons={false} title={'ew.set.felder.title'} sort={{ field: 'pos', order: 'ASC' }} perPage={25} >
      <Datagrid bulkActionButtons={false} style={{ width: 'auto' }}>
        <TextField source="bezeichnung"/>
        <SelectSimpleField source="typ"  simpchoices={typOptions} />
      {isHauptadmin() && <TextField source="id"/>}
        <CondEditButton label="" />
      </Datagrid>
    </List>
  </>
  )
}

const CEForm = (props) => {
  return (
    <SimpleForm {...props} toolbar={<CloseToolbar />}>
      <TextInput source="bezFeld" validate={required()} />
      <SelectSimpleInput source="typ" simpchoices={typOptions} validate={required()} />
      <TextInput source="optionen" fullWidth />
      <TextInput source="tip"/>
    </SimpleForm>
  )
};

const setFelder = {
  list: (props) => (
    <ListForm {...props} />
  ),

  create: (props) => (
    <Create {...props} title={'ew.set.felder.titleCreate'} redirect="list">
      <CEForm {...props}/>
    </Create>
  ),

  edit: (props) => (
    <Edit title={'ew.set.felder.titleEdit'} mutationMode="optimistic" {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default setFelder
