import React, { useState, useEffect } from 'react';
import { useDataProvider, useListContext, useTranslate, useNotify, 
  Toolbar, SaveButton, 
  CreateBase, SimpleForm, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import ControlledAutoComplete from '../lib/ControlledAutoComplete';
import { Dialog, DialogContent, Paper, Grid, TextField, Button, Stack } from '@mui/material';
import { Cancel, FilterListOff} from '@mui/icons-material';

const FilterRow = (props) => {
  const { filter, last, k, felder, fi, setFilterRow } = props;
  const ta = useTranslate();      
  const [ dbopts, setDbopts ] = useState(false);
  const dataProvider = useDataProvider();
  
  const fid = filter.feld?filter.feld:0;
  const typ = fi && fid && fi[fid]?(fi[fid].typ?parseInt(fi[fid].typ):0):0;
  var opt = fi[fid]?fi[fid].optionen:"";
  
  useEffect(() => {
    if (filter.feld===2) {
      dataProvider.getList('setGruppe', { id: 0, filter: { }, sort: { field:'lft' }, pagination: { page: 1, perPage: 999 },})
        .then(response => {
          if (response.data) {
            const o = response.data.reduce((pv,cv)=>(pv+';'+cv.id+'|'+cv.pfad.replaceAll('|','#$')), '');
            setDbopts(o);
          }
        });
    }
    else if (filter.feld===3) {
      dataProvider.getList('setAmt', { id: 0, filter: { }, sort: { }, pagination: { page: 1, perPage: 999 },})
        .then(response => {
          if (response.data) {
            const o = response.data.reduce((pv,cv)=>(pv+';'+cv.id+'|'+cv.bezeichnung_de), '');
            setDbopts(o);
          }
        });
    }
    else if (filter.feld===9) {
      dataProvider.getList('genStrukturfunk', { id: 0, filter: { }, sort: { field: 'pos', order: 'ASC'}, pagination: { page: 1, perPage: 999 },})
        .then(response => {
          if (response.data) {
            const o = response.data.reduce((pv,cv)=>(pv+';'+cv.id+'|'+cv.bezeichnung), '');
            setDbopts(o);
          }
        });
    }
    else {
     setDbopts(false); 
    }
  }, [dataProvider, filter.feld]);

  if (filter.feld===2 || filter.feld===3 || filter.feld===9) {
    opt = dbopts;
  }
  
  const cVergleich = [
    {id:"=",label:"ist"},{id:"!=", label:"ist nicht"}
  ];
  if (filter.feld<2 || filter.feld>9) {
    cVergleich.push(
      {id:">", label:"ist grösser als"},{id:"<", label:"ist kleiner als"},
      {id:"%", label:"beginnt mit"},{id:"!%", label:"beginnt nicht mit"},
      {id:"%%", label:"enthält"},{id:"!%%", label:"enthält nicht"},
      {id:"e", label:"ist leer"},{id:"!e", label:"ist nicht leer"}
    );
  }
  const setLogik = (ev, nv) => {
    const row = {...filter, logik:nv.id};
    setFilterRow(k, row);
  }
  const setFeld = (ev, nv) => {
    const row = {...filter, feld:nv.id};
    setFilterRow(k, row);
  }
  const setComp = (ev, nv) => {
    const row = {...filter, comp:nv.id};
    setFilterRow(k, row);
  }
  const setWert = (ev) => {
    const row = {...filter, wert:ev.target.value};
    setFilterRow(k, row);
  }
  
  const setSelectWert = (ev, nv) => {
    const row = {...filter, wert:nv.id};
    setFilterRow(k, row);
  }
  
  const removeRow = (ev) => {
    setFilterRow(k, false);
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={1}>
    {k>0 && 
        <ControlledAutoComplete options={[{id:0, label:'und'},{id:1, label:'oder'},]} optionLabel="label"  label="ew.addr.filter.logik" onChange={setLogik} value={filter.logik} disableClearable/>
    }
      </Grid>
      <Grid item xs={4}>
        <ControlledAutoComplete options={felder} optionLabel="bezeichnung" label="ew.addr.filter.feld" onChange={setFeld} value={filter.feld} /> 
      </Grid>
      <Grid item xs={2}>
        <ControlledAutoComplete options={cVergleich} optionLabel="label"  label="ew.addr.filter.vergleich" onChange={setComp} value={filter.comp} />
      </Grid>
      <Grid item xs={4}>
      {typ===0 &&
        <TextField label={ta("ew.addr.filter.wert")} value={filter.wert} onChange={setWert} fullWidth />
      }
      {typ===1 &&
        <TextField type="date" label={ta("ew.addr.filter.wert")} value={filter.wert} onChange={setWert} fullWidth />
      }
      {typ>1 &&
        <ControlledAutoComplete simpchoices={"|--;"+opt} optionLabel="label"  label="ew.addr.filter.wert" onChange={setSelectWert} value={String(filter.wert)} />
      }
      </Grid>
    {!last && 
      <Grid item xs={1}>
        <Button sx={{marginTop:'10px'}}><Cancel onClick={removeRow} /></Button>
      </Grid>
    }
    </Grid>
  )
}


const AdvancedFilter = ({setShowAdf}) => {
  const ta = useTranslate();      
  const [advFilter, setAdvFilter] = useState([]);
  const [loadDialog, setLoadDialog] = useState('');
  const { filterValues, setFilters } = useListContext();
  const dataProvider = useDataProvider();
  const [felder, setFelder] = useState([{id:0,bezeichnung:'--'}]);
  const [feldinfo, setFeldinfo] = useState(false);
  const notify = useNotify();
  
  
  useEffect(() => {
    dataProvider.getList('setFelder/filter', { id: 0, filter: { }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(response => {
        if (response.data) {
          setFelder([{id:0,bezeichnung:'--'}, ...response.data]);
          const fi = {};
          response.data.forEach((f)=>{fi[f.id]=f})
          setFeldinfo(fi);
        }
      });
  }, [dataProvider]);
    
  useEffect(() => {
    if (filterValues.adv) {
      setAdvFilter(filterValues.adv);
    }
  }, [filterValues]);
    
  const updateAdvFilter = (f) => {
    setAdvFilter(f);
    setFilters({...filterValues, adv:f});
  }
  
  const setFilterRow = (k, row) => {
    if (row===false) {
      const adf = advFilter.filter((t, i) => i !== k);
      updateAdvFilter(adf);  
    }
    else {
      const adf = [...advFilter];
      adf[k] = row;
      updateAdvFilter(adf);
    }
  }       
  
  const transformAction = (filterValues) => data => ({
    ...data, filter: filterValues
  });
  
  const LoadToolbar = ({button}) => {
    if (!button) return null;
    return(
      <Toolbar>
          <SaveButton type="button" label={ta(button)} transform={transformAction(filterValues)} />
      </Toolbar>
    );
  };
  
  const onSuccess = (data) => {
    if (data.id > 0) {
      notify('ew.addr.filter.geladen');
      console.log(data.filter)
      updateAdvFilter(data.filter)
      setLoadDialog('')
    }
    else {
      notify('Filter gespeichert');
      setLoadDialog('')
    }
  };

  return (
    <Paper elevation={3} sx={{padding:'4px 8px 8px 8px', marginBottom:'8px'}}>
    <Stack direction="row" >
      <div style={{width:'100%'}}>
    {advFilter.map((f, k)=>(
        <FilterRow filter={f} key={k} k={k} felder={felder} fi={feldinfo} setFilterRow={setFilterRow} />
    ))}
        <FilterRow filter={{feld:0,logik:0,comp:'=',wert:''}} last felder={felder} fi={feldinfo} k={advFilter.length} setFilterRow={setFilterRow}/>
      </div>
      <div style={{marginTop:'10px'}}>
        <Stack direction="row">
          <Button variant="contained" sx={{marginRight:'8px'}} onClick={()=>setLoadDialog('load')}>Laden</Button>
          <Button variant="contained" onClick={()=>setLoadDialog('save')}>Speichern</Button>
          <Button onClick={()=>{updateAdvFilter([]); setShowAdf(false)}} ><FilterListOff /></Button>
        </Stack>
      </div>
    </Stack>
    <Dialog onClose={()=>setLoadDialog('')} open={loadDialog!==''} fullWidth >
      <DialogContent>
        <div style={{fontWeight:'bold', fontSize:'1.2rem'}}>{ta('ew.addr.filter.title')}</div>
        <CreateBase resource={"addrFilter/"+loadDialog} mutationOptions={{ onSuccess }} >
          <SimpleForm toolbar={<LoadToolbar button={"ew.addr.filter."+(loadDialog?loadDialog:'load')} />}>
        {loadDialog==='load' &&  
            <ReferenceInput source="id_filter" reference="addrFilter" perPage={1000} sort={{}} filter={{ }} >
              <AutocompleteInput optionText="filtername" label={ta('ew.addr.filter.name')} sx={{minWidth:'300px'}} helperText={false}/>
            </ReferenceInput>
        }
        {loadDialog==='save' && 
            <TextInput source="filtername" label={ta('ew.addr.filter.name')} fullWidth helperText={false}/>
        }
          </SimpleForm>
        </CreateBase>
      </DialogContent>
    </Dialog>
    </Paper>
  )
}

export default AdvancedFilter;

