const setGlobalData = data => {
  if (!window.globalData) {
    window.globalData = {};
  }
  window.globalData = {...window.globalData, ...data};
}

const getGlobalData = key => {
  if (!window.globalData) {
    window.globalData = {};
  }
  if (!window.globalData[key]) {
    var data = {};
    if (key==='token') {
      data = localStorage.getItem('token');
      setGlobalData({token:data});
      return data;
    }
    if (key==='info') {
      data = JSON.parse(localStorage.getItem('info'));
      setGlobalData({info:data});
      return data;
    }
    return {}
  }
  return window.globalData[key];
}

const getInfoValue = key => {
  const info = getGlobalData('info');
  if(!info[key]) {
    return false;
  }
  return info[key];
}

const getRoute = () => {
  var r = window.location.hash;
  while (r[0]==='#' || r[0]==='/') {
    r = r.substr(1)
  }
  return r;
}

const isHauptadmin = () => {
  const info = getGlobalData('info');
  return info && info.id_fw_gruppe<11;
}

const textileConvert = (text) => {
  var t = window.textile(text);
  t = t.replace(/<a href=/g, '<a target="_blank" href=');
  return t;
};

function formatImg(value) {
  if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
   return { url: value };
  } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}

function checkLevel({ maxlevel, checkBezirk=false }) {
  const info = getGlobalData('info');
  if (!maxlevel || !info || !info.level || (checkBezirk && !info.bezirk_id )) {
    return false;
  }
  if (checkBezirk && info.bezirk_id < 20 && info.bezirk_id !== 1) {
    return false;
  }
  if (info.readonly === 1){
    return false;
  }
  return info.level <= maxlevel;
}

function getBezirk() {
  const info = getGlobalData('info');
  if (info && info.bezirk_id) {
    return info.bezirk_id;
  }
  return 0;
}

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
}
const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();
if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {
          [key]: {}
        });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key]
        });
      }
    }
  }
  return mergeDeep(target, ...sources);
}

export { setGlobalData, getGlobalData, getInfoValue, getRoute, isHauptadmin, textileConvert, formatImg, checkLevel, getBezirk, mergeDeep };
