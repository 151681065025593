import React, { useEffect, useState } from 'react';
import { Edit, SimpleForm, List, Datagrid, useRecordContext, useDataProvider,
  EditButton, ReferenceInput, AutocompleteInput, TextInput, SelectInput, DateInput, 
  TextField, DateField } from 'react-admin';
import { isHauptadmin } from '../lib/globalData';
import CloseToolbar from '../lib/CloseToolbar';
import SelectSimpleInput from '../lib/SelectSimpleInput';

const ListForm = (props) => {
  const [ handlungtypen, setHandlungtypen ] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('genHandlungtyp', { id: 0, filter: { }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(response => { if (response.data) { setHandlungtypen(response.data) }});
  }, [dataProvider]);

  let jahrOptions = "";
  const now = new Date();
  for (var i = now.getFullYear(); i>1900;i--) {
    jahrOptions += ';'+i;
  }
  const handlObj = handlungtypen.reduce((obj,f)=>({...obj, [f.id]:f.bezeichnung_de}), {});

  const histFilters = [
    <TextInput source="name" alwaysOn />,
    <SelectInput label="Aktion" source="id_handlungtyp" alwaysOn resettable choices={handlungtypen} optionText="bezeichnung_de" />,
    <SelectSimpleInput label="Jahr" source="jahr" alwaysOn resettable simpchoices={jahrOptions} />
  ];
  
  const PersonField = () => {
    const record = useRecordContext();
    if (!record) return null;
    if (!record.id_person) return null;
    return <span>{record.person}</span>;
  }

  const Handlungstyp = () => {
    const record = useRecordContext();
    if (!record) return null;
    if (!handlObj[99]) return null;
    return <span>{handlObj[record.id_handlungtyp]}</span>;
  }
  return (
  <>
    <List {...props} title={'ew.addr.acthist.title'} filters={histFilters} perPage={25}  >
      <Datagrid bulkActionButtons={false} style={{ width: 'auto' }}>
        <DateField source="datum" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} />
        <Handlungstyp label="Handlung" />
        <TextField source="name" />
        <TextField source="ort" />
        <PersonField/>
      {isHauptadmin() && <TextField source="id_person" />}
      {isHauptadmin() && <TextField source="id"/>}
        <EditButton label="" />
      </Datagrid>
    </List>
  </>
  )
}

const CEForm = (props) => {
  return (
    <SimpleForm {...props} toolbar={<CloseToolbar />}>
      <DateInput source="datum" />
      <ReferenceInput source="id_handlungtyp" reference="genHandlungtyp" perPage={999}>
        <AutocompleteInput optionText="bezeichnung_de" sx={{minWidth:"300px"}} />
      </ReferenceInput>
      <ReferenceInput source="id_person" reference="addrPerson" perPage={999}>
        <AutocompleteInput optionText={(c)=>{return (c.vorname?c.vorname+' ':'')+c.name}} sx={{minWidth:"300px"}} emptyValue={0} emptyText="--" />
      </ReferenceInput>
      <TextInput source="pfarrer" />
      <TextInput source="ort" />
      <TextInput source="vers" />
      <TextInput source="personen" />
      <TextInput source="bemerkung" />
    </SimpleForm>
  )
};

const addrActionhist = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),

  edit: (props) => (
    <Edit title={'ew.addr.amt.titleEdit'} mutationMode="optimistic" {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default addrActionhist
