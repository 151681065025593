import React, { useState, useEffect } from 'react';
import  dataProvider from './dataProvider';
import { getGlobalData, getInfoValue, checkLevel } from './globalData';
import SwapHorizontalCircle from '@mui/icons-material/SwapHorizontalCircle';

import { Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, Button,
  TextField, Select } from '@mui/material';

const SwitchBezirk = (props) => {
  const [open, setOpen] = useState(false);
  const [bezirkList, setBezirkList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [land, setLand] = useState(getInfoValue('id_sland'));
  const [strang, setStrang] = useState(getInfoValue('id_strang'));
  const [district, setDistrict] = useState(getInfoValue('id_oberebene'));
  const [bezirk, setBezirk] = useState(getInfoValue('bezirk_id'));
  const [straenge, setStraenge] = useState([]);
  const [districte, setDistricte] = useState([]);
  const [bezirke, setBezirke] = useState([]);
  const [bezirkWahl, setBezirkWahl] = useState(getInfoValue('bezirk_id'));
  const [userWahl, setUserWahl] = useState(getInfoValue('uid'));

  const laenderList = [
    {id:12, name: "Schweiz"},
    {id:13, name: "Österreich"},
    {id:14, name: "Frankreich"},
  ];
  const laender = [];
  const info = getGlobalData('info');
  for(var i=0;i<laenderList.length;i++) {
    if (info && info.bezirke && info.bezirke.indexOf(laenderList[i].id)<0) continue;
    laender.push(laenderList[i]);
  }

  useEffect(() => {
    dataProvider.getList('genBezirk', { filter: { switch: true }, sort: { field: 'bezeichnung', order: 'ASC' }, pagination: { page: 1, perPage: 999 }, })
      .then(res  => {
        setBezirkList(res.data)
      })
  }, [dataProvider]);

  useEffect(() => {
    dataProvider.getList('authUser/rollen', { filter: {  }, sort: { field: 'benutzername', order: 'ASC' }, pagination: { page: 1, perPage: 999 }, })
      .then(res  => {
        setUserList(res.data)
      })
  }, [dataProvider]);

  useEffect(() => {
    const list = [];
    const info = getGlobalData('info');
    for(var i=0;i<bezirkList.length;i++) {
      const bez = bezirkList[i];
      if (bez.id_sland !== land || bez.id_strang !== bez.id || info.bezirke.indexOf(bez.id)<0) continue;
      list.push(bez);
    }
    setStraenge(list);
  }, [bezirkList, land]);

  useEffect(() => {
    const list = [];
    const info = getGlobalData('info');
    for(var i=0;i<bezirkList.length;i++) {
      const bez = bezirkList[i];
      if (bez.id_strang !== strang || bez.id_oberebene !== bez.id || info.bezirke.indexOf(bez.id)<0) continue;
      list.push(bez);
    }
    setDistricte(list);
  }, [bezirkList, strang]);

  useEffect(() => {
    const list = [];
    const info = getGlobalData('info');
    for(var i=0;i<bezirkList.length;i++) {
      const bez = bezirkList[i];
      if (bez.id_oberebene !== district || bez.id_strang === bez.id || bez.id_oberebene === bez.id || bez.id_sland === bez.id || info.bezirke.indexOf(bez.id)<0) continue;
      list.push(bez);
    }
    console.log(list.length)
    setBezirke(list);
  }, [bezirkList, district]);

  const handleClickOpen = event => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const doSwitch = (id, action) => {
    dataProvider.update('authSwitch', { id: id, data: {action: action }})
      .then(res  => {
        if (res.data.info) {
          localStorage.setItem('info', JSON.stringify(res.data.info));
        }
        handleClose();
        window.location.href = '/';
      })
      .catch(response => {
        window.location.hash = '/login';
      });

  };

  const handleSwitchBezirk = event => {
    doSwitch(bezirkWahl, 'switchbezirk');
  };
  const handleResetBezirk = event => {
    doSwitch(0, 'resetbezirk');
  };
  const handleSwitchUser = event => {
    doSwitch(userWahl, 'switchuser');
  };

  return (
      <div>
          <Tooltip title="Bereich / Bezirk / Rolle wechseln">
              <IconButton
                  color="inherit"
                  onClick={handleClickOpen}
              >
                  <SwapHorizontalCircle />
              </IconButton>
          </Tooltip>
          <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>Bereich / Bezirk / Rolle wechseln</DialogTitle>
            <DialogContent>
              <form>
                { checkLevel({maxlevel:1}) && <div>
                  <TextField
                    id="standard-name"
                    label="Bezirk ID"
                    value={bezirkWahl}
                    type="number"
                    autoFocus={true}
                    onChange={e => setBezirkWahl(e.target.value)}
                  />
                </div>}
                { checkLevel({maxlevel:10}) && <div>
                  <InputLabel htmlFor="selectLand">Land</InputLabel>
                  <Select native value={land} id="selectLand" label="Land" onChange={e => { setLand(parseInt(e.target.value)); setBezirkWahl(e.target.value) }} >
                    <option value="0">Alle Länder</option>
                    {laender.map(el => (
                      <option value={el.id} key={el.id}>
                        {el.name} [{el.id}]
                      </option>
                      )
                    )}
                  </Select>
                </div>}
                { checkLevel({maxlevel:12}) && <div>
                  <InputLabel htmlFor="hostelselect2">Strang</InputLabel>
                  <Select native id="bezsel" value={strang} onChange={e => { setStrang(parseInt(e.target.value)); if (e.target.value>0) setBezirkWahl(e.target.value); else { setBezirkWahl(land) } } }
                  >
                    <option value="0">Alle Stränge</option>
                    {straenge.map(el => (
                      <option value={el.id} key={el.id}>
                        {el.bezeichnung} [{el.id}]
                      </option>
                      )
                    )}
                  </Select>
                </div>}
                { checkLevel({maxlevel:13}) && <div>
                  <InputLabel htmlFor="hostelselect2">Distrikt</InputLabel>
                  <Select native id="bezsel" value={district} onChange={e => { setDistrict(parseInt(e.target.value)); if (e.target.value>0) setBezirkWahl(e.target.value); else setBezirkWahl(strang)} } >
                    <option value="0">Alle Distrikte</option>
                    {districte.map(el => (
                      <option value={el.id} key={el.id}>
                        {el.bezeichnung} [{el.id}]
                      </option>
                      )
                    )}
                  </Select>
                </div>}
                { checkLevel({maxlevel:14}) && <div>
                  <InputLabel htmlFor="hostelselect2">Bezirk</InputLabel>
                  <Select native id="bezsel" value={bezirk} onChange={e => { setBezirk(parseInt(e.target.value)); if (e.target.value>0) setBezirkWahl(e.target.value); else setBezirkWahl(district) } }>
                    <option value="0">Alle Bezirke</option>
                    {bezirke.map(el => (
                      <option value={el.id} key={el.id}>
                        {el.bezeichnung} [{el.id}]
                      </option>
                      )
                    )}
                  </Select>
                </div>}
                <div>
                  <InputLabel htmlFor="userselect">Rolle</InputLabel>
                  <Select native id="usersel" value={userWahl} onChange={e => { setUserWahl(parseInt(e.target.value)) } }>
                    {userList.map(el => (
                      <option value={el.id} key={el.id}>
                        {el.benutzername} [{el.id}]
                      </option>
                      )
                    )}
                  </Select>
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              { checkLevel({maxlevel:14}) && <Button onClick={handleSwitchBezirk} color="primary">
                Bezirk Wechseln
              </Button>}
              <Button onClick={handleSwitchUser} color="primary">
                Rolle Wechseln
              </Button>
              <Button onClick={handleResetBezirk} color="primary">
                Zurücksetzen
              </Button>
              <Button onClick={handleClose} color="primary">
                Schliessen
              </Button>
            </DialogActions>
          </Dialog>
      </div>
  );
}
export default SwitchBezirk;

//               {info && info.back?<Button onClick={handleBack} color="primary">Switch Back</Button>:null}
