import React, { useState, useEffect } from 'react';
import { List, Create, Edit, Datagrid, TextField, ReferenceField,
  EditButton, ReferenceInput, AutocompleteInput, SearchInput,
  TabbedForm, FormTab, ArrayInput, SimpleFormIterator, required,
  useDataProvider, useListContext, useGetMany, useUpdate, useNotify, useRedirect } from 'react-admin';
import { useRecordContext, useTranslate } from 'ra-core';
import { Close, AlternateEmail, ContentCopy } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, Link, Typography, Button, TextField as MuiTextField, Stack, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SelectInputGrid, TextInputGrid, BooleanInputGrid } from '../lib/gridInput';
import { checkLevel, getGlobalData, getInfoValue } from '../lib/globalData';
import CloseToolbar from '../lib/CloseToolbar';
import { apiPath } from '../lib/isTest';
import get from 'lodash/get';
import { useWatch } from 'react-hook-form';

const BulkActionButtons = (props) => {
  const list = useListContext();
  var admin_ids = [];
  if(list.selectedIds && list.data){
    list.data.map((cv, idx)=>{
      if(list.selectedIds.indexOf(cv.id)!==-1)admin_ids.push(cv.id);
    })
  }
  const { data, isLoading, error } = useGetMany(
    'authUser/username',
    { ids: admin_ids }
  );
  if (isLoading) return null;
  if (error) return null;
  const value = data.reduce((pv,cv)=>(pv+';'+cv.email), '').substr(1);
  return (
    <>
      <EmailSendButton {...props} value={value} label="Send Email" />
    </>
  );
}

const stopPropagation = e => e.stopPropagation();

const EmailSendButton = ({className, source, value=false, label='', ...props}) => {
  const record = useRecordContext(props);
  var address = value;
  if(address === false){
    address = get(record, source);
  }
  //console.log(address);
  if(address === false){
    console.log(['noAddress',record,source, value]);
    return null;
  }
  return (
    <Link className={className} href={`mailto:${address}`} onClick={stopPropagation} {...props} label={label} >
      <div style={{ display: 'flex'}}>
        <AlternateEmail />
        { label && <Typography flex="1" variant="h6" style={{ marginLeft: '0.2em',marginTop: '-0.2em'}}>{label}</Typography>}
      </div>
    </Link>
  )
}

const validateUser = async (values) => {
  return new Promise(async (resolve, reject) => {
    var token = getGlobalData('token');
    const res = await fetch(apiPath+'authUser/validate', {
      method: 'PUT',
      body: JSON.stringify({ values: values }),
      headers: {"Content-type": "application/json; charset=UTF-8", authorization: token}
    })
    const json = await res.json();

    if (json.ok) return resolve()
    resolve(json.errors)
  })
};

const CopyUserDialog = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const [showCopyDia, setShowCopyDia] = useState(false);
  const [rolle, setRolle] = useState("Kopie von "+record.rollename);
  const tr = useTranslate();


  const showCopyDialog = () => {
    setShowCopyDia(true);
  };
  const closeCopyDialog = () => {
    setShowCopyDia(false);
  };
  const changeRolle = (ev) => {
    setRolle(ev.target.value);
  }
  const [copyEntry, { isLoading }] = useUpdate(
      'authUser/copyuser',
      { id: record.id, data: {rolle: rolle} },
      {
          mutationMode:'pessimistic',
          onSuccess: (response) => {
            console.log(response);
            closeCopyDialog();
            redirect('/authUser/'+response.id);
          },
          onError: (error) => {
            console.log('error')
            notify(`Fehler beim speichern: ${error.message}`, { type: 'warning' });
          },
      }
  );

  return (
    <>
      <Button onClick={showCopyDialog}><ContentCopy /></Button>
      <Dialog onClose={closeCopyDialog} open={showCopyDia} maxWidth="md" >
        <Close onClick={closeCopyDialog} sx={{ position:'absolute', right:'8px', top:'8px', cursor:'pointer'}} />
        <DialogTitle>{tr('ew.auth.user.copydia.title')}</DialogTitle>
        <DialogContent>
          <Stack>
            <MuiTextField label={tr("ew.auth.user.copydia.rolle")} value={rolle} onChange={changeRolle} />
            <Button onClick={()=>copyEntry()} variant="contained" disabled={isLoading}>Copy</Button>
            {isLoading && <CircularProgress sx={{display:'block', margin:'16px auto 0 auto'}} />}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

const ListForm = (props) => {
// Checkbox "Alle Personen anzeigen"
  const {classes, ...rest} = props;
  const dataProvider = useDataProvider();
  const [isLoading, setLoading] = useState(true);
  const [columns, setColumns] = useState({
    id_sland:false,
    id_strang:false,
    distrikt_label:'',
    bezirk_label:'',
  });

  useEffect(() => {
    dataProvider.getOne('authUser/getcolumns', { id: 0 } )
    .then(res  => {
      setColumns(res.data);
      setLoading(false);
    })
  }, [dataProvider]);

  if(isLoading)return null;

  var listFilters = [];
  if(getInfoValue('level')<20){
    if(checkLevel({maxlevel:10}))listFilters.push(<ReferenceInput source="id_sland" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ land: true }} alwaysOn>
      <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} />
    </ReferenceInput>);

    if(columns.id_strang)listFilters.push(<ReferenceInput source="id_strang" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ strang: true }} alwaysOn>
      <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} />
    </ReferenceInput>);

    if(checkLevel({maxlevel:13}))listFilters.push(<ReferenceInput source="id_oberebene" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ oberebene: true }} alwaysOn>
      <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} label={columns.distrikt_label} />
    </ReferenceInput>);

    listFilters.push(<ReferenceInput source="id_bezirk" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ }} alwaysOn>
      <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} label={columns.bezirk_label} />
    </ReferenceInput>);

    listFilters.push(<SearchInput source="q" alwaysOn resettable />);

    listFilters.push(<ReferenceInput source="id_fw_gruppe" reference="authFwgroup" perPage={1000} sort={{ }} filter={{ }} alwaysOn>
      <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} />
    </ReferenceInput>);
  }

  return (
  <>
    <List {...rest} bulkActionButtons={false} title={'ew.auth.user.title'} perPage={50} filters={listFilters} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={<BulkActionButtons {...props} />} >
        <TextField source="benutzername"/>
        <TextField source="vorname"/>
        <TextField source="name"/>
        <ReferenceField source="id_bezirk" reference="genBezirk" link={false} sortable={false}>
          <TextField source="bezeichnung" label={columns.bezirk_label} />
        </ReferenceField>
        <ReferenceField source="id_fw_gruppe" reference="authFwgroup" link={false} sortable={false}>
          <TextField source="bezeichnung" />
        </ReferenceField>
        <EditButton label="" />
        <CopyUserDialog />
      </Datagrid>
    </List>
  </>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const dataProvider = useDataProvider();
  const [isLoading, setLoading] = useState(true);
  const [columns, setColumns] = useState({
    id_sland:false,
    id_strang:false,
    distrikt_label:'',
    bezirk_label:'',
    rolle:false,
  });
  const record = useRecordContext(props);

  useEffect(() => {
    dataProvider.getOne('authUser/getcolumns', { id: record&&record.id?record.id:0 } )
    .then(res  => {
      setColumns(res.data);
      setLoading(false);
    })
  }, [dataProvider]);

  const Einheit = ({record=false, ...props}) => {
    const id_fw_gruppe = useWatch({ name: 'id_fw_gruppe' });
    console.log(id_fw_gruppe);
    switch(id_fw_gruppe) {
      case 12:
        return <ReferenceInput source="id_sland" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ land: true, level: id_fw_gruppe }}>
          <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} validate={required()} />
        </ReferenceInput>;
        break;
      case 13:
        return <ReferenceInput source="id_strang" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ strang: true, level: id_fw_gruppe }}>
          <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} validate={required()} />
        </ReferenceInput>;
        break;
      case 14:
        return <ReferenceInput source="id_oberebene" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ oberebene: true, level: id_fw_gruppe }}>
          <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} label={columns.distrikt_label} validate={required()} />
        </ReferenceInput>;
        break;
      case 20:
      case 31:
        return <ReferenceInput source="id_bezirk" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ level: id_fw_gruppe }}>
          <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} label={columns.bezirk_label} validate={required()} />
        </ReferenceInput>;
        break;
      case 25:
        return <ReferenceInput source="id_einheit" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ einheit: true, level: id_fw_gruppe }}>
          <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'250px'}} validate={required()} />
        </ReferenceInput>;
        break;
      default:
        break;
    }
    return null;
  }

  const GruppenEinheitInput = (props) => {
    const record = useRecordContext(props);
    return (
      <div>
        <ReferenceInput source="id_fw_gruppe" reference="authFwgroup" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} >
          <AutocompleteInput optionText="bezeichnung" fullWidth record={record} />
        </ReferenceInput>
        { checkLevel({maxlevel:19}) && <Einheit {...props} record={record} /> }
      </div>
    )
  }

  if(isLoading)return null;
  return (
    <TabbedForm {...props} toolbar={<CloseToolbar />} validate={validateUser}>
      <FormTab label="Benutzer">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} lg={4} >
            <GruppenEinheitInput />
          </Grid>
          { !columns.rolle && <TextInputGrid source="benutzername" validate={required()} />}
          <TextInputGrid source="rollename" />
          { !columns.rolle && <TextInputGrid source="email" validate={required()} />}
          { !columns.rolle && <TextInputGrid source="vorname" />}
          { !columns.rolle && <TextInputGrid source="name" />}
          { !columns.rolle && <TextInputGrid source="strasse" />}
          { !columns.rolle && <TextInputGrid source="plz_ort" />}
          { !columns.rolle && <SelectInputGrid source="lang" simpchoices="de|glob.choices.de;en|glob.choices.en;fr|glob.choices.fr" />}
          { !columns.rolle && <SelectInputGrid source="sicherheit" simpchoices="0|ew.auth.user.usrCodeYubi;1|ew.auth.user.yubico" />}
          { !columns.rolle && <TextInputGrid source="yubico" />}
          { !columns.rolle && <BooleanInputGrid source="passwort" />}
          <BooleanInputGrid source="readonly" />
        </Grid>
      </FormTab>
      <FormTab label="Gruppen">
        <ArrayInput source="gruppe">
          <SimpleFormIterator inline disableReordering>
            <ReferenceInput source="id_gruppe" reference="setGruppe" perPage={1000} sort={{}} >
              <AutocompleteInput optionText="pfad" sx={{minWidth:'300px'}} />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  )
};

const addrPerson = {
  list: (props) => (
    <ListForm {...props} />
  ),

  create: (props) => (
    <Create {...props} title={'ew.auth.user.titleCreate'}>
      <CEForm {...props}/>
    </Create>
  ),

  edit: (props) => (
    <Edit title={'ew.auth.user.titleEdit'} {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default addrPerson
