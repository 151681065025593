import React from 'react';
import { List, Datagrid, TextField, DeleteButton } from 'react-admin';


const ListForm = (props) => {
  const {classes, ...rest} = props;
  return (
  <>
    <List {...rest} title={'ew.set.mailvor.title'} sort={{ field: 'zeit', order: 'ASC' }} perPage={25} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false} >
        <TextField source="betreff"/>
        <TextField source="vorlagetitel"/>
        <TextField source="privat"/>
        <DeleteButton label="" />
      </Datagrid>
    </List>
  </>
  )
}

const genMailvor = {
  list: (props) => (
    <ListForm {...props} exporter={false} empty={false} />
  )
}
export default genMailvor
