import React from 'react';
import { useUpdate, useNotify } from 'react-admin';
import SubMenu from '../lib/SubMenu';
 

const ActSperren = ({count, manyIds, closeDialog}) => {
  const notify = useNotify();
  const [ update ] = useUpdate();  

  const clickSubMenu = (act) => {
    update('addrPerson/sperren', {id: manyIds, data: {aktion:act.id}},
      {
        mutationMode:'pessimistic',
        onSuccess: () => {
          notify('ew.act.done');
          closeDialog();
        },
        onError: (error) => {
          console.log('error')
          notify(`Fehler beim speichern: ${error.message}`, { type: 'warning' });
        },
      }
    );
  }

  return (
    <SubMenu menu={{title:'ew.act.sperren.button', action:'sperren', list:[
      {id:'kuw',text:'ew.act.sperren.kuw'},{id:'ch',text:'ew.act.sperren.ch'},{id:'all',text:'ew.act.sperren.all'},
      {id:'nkuw',text:'ew.act.sperren.nkuw'},{id:'nch',text:'ew.act.sperren.nch'},{id:'nall',text:'ew.act.sperren.nall'},
    ]}} 
    close={closeDialog} click={clickSubMenu} />
  );
}

export default ActSperren
