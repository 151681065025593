import { TextInput, NumberInput, BooleanInput, DateInput, ReferenceInput } from 'react-admin';
import Grid from '@mui/material/Grid';
import SelectSimpleInput from './SelectSimpleInput';
import BirthDateInput from './BirthDateInput';

const breiten = (compact) => {
  if (compact) {
    return {xs:6, sm:4, lg:3};
  }
    return {xs:12, sm:6, lg:4};
}

const TextInputGrid = (props) => {
  const {compact, ...rest} = props
  return (
    <Grid item {...breiten(compact)}>
      <TextInput {...rest} fullWidth />
    </Grid>
  )
}
const NumberInputGrid = (props) => {
  const {compact, ...rest} = props
  return (
    <Grid item {...breiten(compact)}>
      <NumberInput {...rest} fullWidth />
    </Grid>
  )
}
const BooleanInputGrid = (props) => {
  const {compact, ...rest} = props
  return (
    <Grid item {...breiten(compact)}>
      <BooleanInput {...rest} fullWidth />
    </Grid>
  )
}
const DateInputGrid = (props) => {
  const {compact, ...rest} = props
  return (
    <Grid item {...breiten(compact)}>
      <DateInput {...rest} fullWidth />
    </Grid>
  )
}
const BirthDateInputGrid = (props) => {
  const {compact, ...rest} = props
  return (
    <Grid item {...breiten(compact)}>
      <BirthDateInput {...rest} fullWidth />
    </Grid>
  )
}
const SelectInputGrid = (props) => {
  const {compact, ...rest} = props
  return (
    <Grid item {...breiten(compact)}>
      <SelectSimpleInput {...rest} fullWidth />
    </Grid>
  )
}

const ReferenceInputGrid = (props) => {
  const {compact, ...rest} = props
  return (
    <Grid item {...breiten(compact)}>
      <ReferenceInput {...rest} fullWidth />
    </Grid>
  )
}

export { TextInputGrid, NumberInputGrid, BooleanInputGrid, DateInputGrid, SelectInputGrid, ReferenceInputGrid, BirthDateInputGrid };
