import React, { useState } from 'react';
import { useDataProvider, useListContext, useRecordContext, useRefresh,
  Create, Edit, SimpleForm, required, TextInput,
  List, Datagrid, TextField
  } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import ActCompare from '../act/ActCompare';
import Link from '@mui/material/Link';

import CloseIcon from '@mui/icons-material/Close';
import CompareIcon from '@mui/icons-material/Compare';


const ListForm = (props) => {
  const [dialog, setDialog] = useState(false);
  const refresh = useRefresh();

  const DublActionButtons = ({noComp, duplid}) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const { selectedIds } = useListContext();
    
    const DuplAction = (action) => {
      const ids = duplid?[duplid]:(record && record.id?[record.id]:selectedIds);
      dataProvider.updateMany('/genDubletten/action', { ids: ids, data: { action }})
       .then(() => {
         setDialog(false);
         refresh();
       })
       .catch((e) => {
           console.error(e);
       });  
    }
    
    return (
      <>
        <Button onClick={() => DuplAction('a')} label = "" title="A Zuweisen">A</Button>
        <Button onClick={() => DuplAction('b')} label = "" title="B Zuweisen">B</Button>
        <Button onClick={() => DuplAction('x')} label = "" title="Verschiedene"><CloseIcon /></Button>
      {!noComp &&  
        <Button onClick={() => setDialog(record)} label = "" title="Gegenüberstellung"><CompareIcon /></Button>
      }
      </>
    )
  }

  const DuplDialog = () => {
    return (
      <Dialog onClose={()=>setDialog(false)} open={dialog!==false} maxWidth="md" >
        <CloseIcon onClick={()=>setDialog(false)} sx={{ position:'absolute', right:'8px', top:'8px', cursor:'pointer'}} />
        
        <Stack direction="row" sx={{margin:'1em 0 0 1.5em'}}>
          <DublActionButtons noComp duplid={dialog.id} />
        </Stack>
        <ActCompare count={2} manyIds={dialog.id_person1+','+dialog.id_person2} order={{field:'id!='+dialog.id_person1, order:'ASC'}} dublette />
      </Dialog>
    )
  }
  
  const DuplLink = ({pos}) => {
    const record = useRecordContext();
    return (
      <Link href={"#/addrPerson?showaction="+record['id_person'+pos]+"&name="+record['name'+pos]}>{record['info'+pos]}</Link>
    )
  }

  return (
  <>
    <List {...props} title={'ew.set.amt.title'} sort={{ field: 'level', order: 'ASC' }} perPage={25}>
      <Datagrid bulkActionButtons={<DublActionButtons noComp />} style={{ width: 'auto' }}>
        <DuplLink pos="1" label="Person A" />
        <DuplLink pos="2" label="Person B" />
        <TextField source="level"/>
        <DublActionButtons />
      </Datagrid>
    </List>
    <DuplDialog />
  </>
  )
}

const CEForm = (props) => {
  return (
    <SimpleForm {...props}>
        <TextInput source="bezeichnung" validate={required()} />
    </SimpleForm>
  )
};

const genDubletten = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),

  create: (props) => (
    <Create {...props} title={'ew.gen.dubletten.titleCreate'} redirect="list">
      <CEForm {...props}/>
    </Create>
  ),

  edit: (props) => (
    <Edit title={'ew.gen.dubletten.titleEdit'} mutationMode="optimistic" {...props}>
      <CEForm {...props}/>
    </Edit>
  )
}
export default genDubletten
