import React, { useState, useEffect } from 'react';
import { Create, Edit, SimpleForm, required, TextInput,
  List, Datagrid, TextField, EditButton, ReferenceField, 
  ReferenceInput, AutocompleteInput, SearchInput,
  useDataProvider, useListContext, useGetMany } from 'react-admin';
import { getInfoValue } from '../lib/globalData';
import SelectSimpleField from '../lib/SelectSimpleField';
import CloseToolbar from '../lib/CloseToolbar';
import { AlternateEmail } from '@mui/icons-material';
import { useRecordContext, useTranslate } from 'ra-core';
import { Link, Typography } from '@mui/material';
import get from 'lodash/get';

const itypOptions = "strang|ew.set.einheit.strang;distrikt|ew.set.einheit.distrikt;bezirk|ew.set.einheit.bezirk";

const BulkActionButtons = (props) => {
  const dataProvider = useDataProvider();
  const list = useListContext();
  var admin_ids = [];
  if(list.selectedIds && list.data){
    list.data.map((cv, idx)=>{
      if(list.selectedIds.indexOf(cv.id)!==-1)admin_ids.push(cv.id_admin);
    })
  }
  const { data, isLoading, error, refetch } = useGetMany(
    'authUser/username',
    { ids: admin_ids }
  );
  if (isLoading) return null;
  if (error) return null;
  const value = data.reduce((pv,cv)=>(pv+','+cv.email), '').substr(1);
  return (
    <>
      <EmailSendButton {...props} value={value} label="Send Email" />
    </>
  );
}

const stopPropagation = e => e.stopPropagation();


const EmailSendButton = ({className, source, value=false, label='', ...props}) => {
  const record = useRecordContext(props);
  var address = value;
  if(address === false){
    address = get(record, source);
  }
  //console.log(address);
  if(address === false){
    console.log(['noAddress',record,source, value]);
    return null;
  }
  return (
    <Link className={className} href={`mailto:${address}`} onClick={stopPropagation} {...props} label={label} >
      <div style={{ display: 'flex'}}>
        <AlternateEmail />
        { label && <Typography flex="1" variant="h6" style={{ marginLeft: '0.2em',marginTop: '-0.2em'}}>{label}</Typography>}
      </div>
    </Link>
  )
}

const ListForm = (props) => {
  const dataProvider = useDataProvider();
  const [bezirke, setBezirke] = useState({});
  const [columns, setColumns] = useState({
    id_sland:false,
    id_strang:false,
    id_oberebene_label:false,
  });

  const EinheitName = ({source}) => {
    const record = useRecordContext(props);
    console.log({record, source})    
    const id = record[source];
    if (!id) return '';
    return bezirke[id]?bezirke[id].bezeichnung:'';
  }

  useEffect(() => {
    dataProvider.getOne('setEinheit/getcolumns', { id: 0 } )
    .then(res  => {
      setColumns(res.data);
    })
    dataProvider.getList('genBezirk/find', { id: 0, filter: {all:true}, sort: { }, pagination: { page: 1, perPage: 999 },})
    .then(res  => {
      const bezlist = {};
      res.data.forEach((row)=>{ bezlist[row.id] = row;});
      setBezirke(bezlist);
    })
  }, [dataProvider]);

  if(!columns.id_oberebene_label)return null;

  var listFilters = [];

  listFilters.push(<SearchInput source="q" alwaysOn resettable />);
  if(getInfoValue('level')<=10)listFilters.push(<ReferenceInput source="id_sland" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ land: true }} alwaysOn>
    <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'300px'}} />
  </ReferenceInput>);
  if(columns.id_strang)listFilters.push(<ReferenceInput source="id_strang" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ strang: true }} alwaysOn>
    <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'300px'}} />
  </ReferenceInput>);
  listFilters.push(<ReferenceInput source="id_oberebene" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ oberebene: true }} alwaysOn>
    <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'300px'}} label={columns.id_oberebene_label} />
  </ReferenceInput>);

  return (
    <List {...props} title={'ew.set.einheit.title'} perPage={25} filters={listFilters} >
      <Datagrid bulkActionButtons={<BulkActionButtons {...props} />} style={{ width: 'auto' }}>
        <TextField source="bezeichnung"/>
        <ReferenceField source="id_admin" reference="authUser/username" link={false}>
          <TextField source="fullname" />
        </ReferenceField>
        <SelectSimpleField source="ityp" simpchoices={itypOptions} />
        { columns.id_sland && <EinheitName source="id_sland" />}
        { columns.id_strang && <EinheitName source="id_strang"  />}
        <EinheitName source="id_oberebene" label={columns.id_oberebene_label} />
        <EinheitName source="id_emkbezirk" />
        <EditButton columns={columns} label="" />
        <ReferenceField source="id_admin" reference="authUser/username" link={false}>
          <EmailSendButton {...props} source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

const CEForm = (props) => {
  const dataProvider = useDataProvider();
  const [columns, setColumns] = useState({
    id_sland:false,
    id_strang:false,
    id_oberebene_label:false,
  });

  useEffect(() => {
    dataProvider.getOne('setEinheit/getcolumns', { id: 0 } )
    .then(res  => {
      setColumns(res.data);
    })
  }, [dataProvider]);

  console.log(props)

  if(!columns.id_oberebene_label)return null;
  return (
    <SimpleForm {...props} toolbar={<CloseToolbar />}>
      { columns.id_sland && <ReferenceInput source="id_sland" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ land: true }}>
        <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'350px'}} validate={required()} />
      </ReferenceInput>}
      { columns.id_strang && <ReferenceInput source="id_strang" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ strang: true }}>
        <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'350px'}} validate={required()} />
      </ReferenceInput>}
      <ReferenceInput source="id_oberebene" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ oberebene: true }}>
        <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'350px'}} label={columns.id_oberebene_label} />
      </ReferenceInput>
      <TextInput source="bezeichnung" sx={{minWidth:'350px'}} validate={required()} />
      <ReferenceInput source="id_admin" reference="authUser/username" perPage={1000} sort={{ field: 'fullname', order: 'ASC' }}>
        <AutocompleteInput optionText="fullname_fgr" sx={{minWidth:'350px'}} />
      </ReferenceInput>
      <ReferenceInput source="id_bezirkperson" reference="setBezirkperson" perPage={1000} sort={{ field: 'vorname', order: 'ASC' }}>
        <AutocompleteInput optionText="vorname" sx={{minWidth:'350px'}} />
      </ReferenceInput>
      <ReferenceInput source="id_emkbezirk" reference="genBezirk" perPage={1000} sort={{ field: 'bezeichnung', order: 'ASC' }} filter={{ emkbezirk: true }}>
        <AutocompleteInput optionText="bezeichnung" sx={{minWidth:'350px'}} label="Bezug EMK Bezirk" />
      </ReferenceInput>
    </SimpleForm>
  )
};

const setEinheit = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),

  create: (props) => (
    <Create {...props} title={'ew.set.einheit.titleCreate'} redirect="list">
      <CEForm {...props}/>
    </Create>
  ),

  edit: (props) => (
    <Edit title={'ew.set.einheit.titleEdit'} mutationMode="optimistic" {...props} redirect="list">
      <CEForm {...props}/>
    </Edit>
  )
}
export default setEinheit
