const langFR =
{
    ew: {
        act: {
            bearbeiten: {
                button: "Traiter",
                feld: "Champ",
                start: "",
                titel: "",
                wert: "Valeur"
            },
            birthday: {
                exec: "Créer liste anniversaires",
                titel: "Liste des anniversaires",
                typ: {
                    j: "Liste par année",
                    m: "Liste par mois",
                    r: "Anniversaires ronds (dizaines, dès 60 ans aussi unités de 5)",
                    s: "Liste des aînés (dès 65 ans unités de 5 uniquement, dès 80 ans tous)"
                }
            },
            compare: {
                titel: ""
            },
            datenblatt: {
                button: "Feuille de données"
            },
            delverkn: {
                button: ""
            },
            dispverkn: {
                button: "Montrer les liens"
            },
            done: "",
            drucken: {
                button: "Imprimer / exporter",
                col: "Colonne de départ",
                form: "Forme d'édition",
                geburtsdatum: "Date de naissance",
                num: "Nombre d'étiquettes par adresse",
                numtext: "Imprimer la même étiquette plusieurs fois.",
                postext1: "",
                postext2: "",
                row: "Ligne de départ",
                start: "",
                titelfeld: "Titre"
            },
            email: {
                addr: "Adresse du destinataire",
                saved: "",
                vorlagen: "Ouvrir un modèle"
            },
            geburtstag: {
                button: "Liste des anniversaires"
            },
            handlung: {
                bek: "Renouvellement des vœux du baptême",
                button: "",
                ehe: "Mariage",
                "exec-bek": "",
                "exec-ehe": "",
                "exec-seg1": "",
                "exec-seg2": "",
                "exec-tauf1": "",
                "exec-tauf2": "",
                "exec-tod": "",
                seg1: "Présentation des enfants",
                seg2: "Cérémonie de clôture du catéchisme",
                tauf1: "Baptême d'enfants",
                tauf2: "Baptême d'adultes",
                tod: "Enterrement / décès"
            },
            handlungen: {
                button: "Casuels"
            },
            history: {
                button: "Historique"
            },
            loeschen: {
                button: "Effacer",
                titel: ""
            },
            loeschen2: {
                button: "",
                titel: ""
            },
            mail: {
                allAddr: "Toutes les adresses",
                anhaenge: "Pièces jointes",
                betreff: "Regard",
                history: "Histoire Communication",
                loadmail: "Ouverte mail enregistré",
                loadtempl: "Ouvrir un modèle",
                mailtitel: "Ouverte mail enregistré",
                optionen: "Options",
                save: "Enregistrer la mail",
                send: "",
                test: "",
                text: "Texte",
                titel: "",
                vorlagentitel: "Modèles et mails enregistrées",
                vorlagetitel: ""
            },
            mitteilung: {
                button: "Message au circuit"
            },
            msgadm: {
                confirm: "",
                exec: "",
                subtitle: "",
                title: "Message"
            },
            mutationen: {
                bemerkung: "Remarque",
                button: "Mutations",
                date: "Date",
                delete: "Supprimer",
                "exec-delete": "",
                "exec-quit": "",
                "exec-trans": "",
                freund: "",
                id_bezirk: "Circuit",
                keineintrag: "Pas d\\'entrée dans les Historique / Livre de l'église / Communiqués",
                quit: "Démission",
                trans: "",
                verknuepft: ""
            },
            nachricht: {
                button: "Message",
                clipAddr: "",
                clipMail: "",
                send: "",
                sendapp: "Mail senden aus emk-web",
                sendbcc: "",
                sendto: ""
            },
            reaktivieren: {
                button: ""
            },
            sperren: {
                all: "Verrouiller les personnes pour les deux",
                button: "Verrouiller",
                ch: "Verrouiller les personnes pour l'envoi en CH",
                kuw: "Verrouiller les personnes pour K&W",
                nall: "Déverrouiller les personnes des deux catégories",
                nch: "Déverrouiller les personnes pour l'envoi en CH",
                nkuw: "Déverrouiller les personnes pour K&W"
            },
            target: {
                all: "",
                sel: "",
                titel: ""
            },
            titel: "",
            vergleichen: {
                button: "Comparer des personnes"
            },
            verkndisp: {
                text: "",
                text2: "Veuillez noter que seules les unités auxquelles vous avez accès sont affichées.",
                titel: "Liens"
            },
            verknuepf: {
                done: "",
                exec: "Créer un lien avec la personne",
                text: "Veuillez sélectionner l'unité à laquelle la/les personne(s) doi(ven)t être liée(s).",
                titel: "Créer un lien avec la personne"
            },
            "verknüpfen": {
                button: "Créer un lien avec la personne"
            },
            zueinheit: {
                button: "Aller vers l'unité de la personne"
            }
        },
        addr: {
            acthist: {
                title: "Historique des actions"
            },
            amt: {
                title: "Fonctions"
            },
            amthist: {
                title: "Historique des fonctions",
                titleEdit: ""
            },
            filter: {
                aemter: "Fonctions",
                aktiv: "Actif",
                alle: "Tous",
                allePers: "Toutes les personnes",
                allejs: "Tous les groupes de Flambeaux",
                allewerke: "Toutes les œuvres ",
                amtstatus: "",
                eigene: "Propres uniquement",
                einheit: "Unité",
                einheiten: "Toutes les unités",
                feld: "Champ",
                geladen: "",
                gruppenfunktion: "Fonction du groupe",
                idEinheit: "",
                load: "",
                logik: "",
                name: "",
                nuraktiv: "Personnes actives uniquement",
                nurinaktiv: "Personnes inactives uniquement",
                save: "",
                title: "",
                vergleich: "",
                verknuepft: "Personnes ayant un lien",
                verknuepfte: "Liés uniquement",
                wahl: "Sélectionner le filtre par personnes",
                wert: "Valeur"
            },
            form: {
                add: "Ajouter un champ",
                title: "",
                titleCreate: "",
                titleEdit: "Modifier la forme d'édition"
            },
            haushalt: {
                select: {
                    title: "Attribuer le ménage"
                }
            },
            history: {
                title: ""
            },
            person: {
                MoeglicheVerknuepfungen: "Liens potentiels",
                Verknuepfen: "",
                aktionen: "Actions",
                erwfilter: "Filtre élargi",
                title: "Personnes",
                titleCreate: "",
                titleEdit: "Modifier la personne"
            },
            tab: {
                daten: "Données",
                emk: "EEM",
                gruppen: "Groupes",
                gruppenhist: "",
                haushalt: "Ménage",
                kontakt: "Contact",
                person: "Personne"
            }
        },
        auth: {
            profil: {
                addrTitle: "Adresse",
                langTitle: "Modifier le langue",
                pwdTitle: "Modifier le mot de passe",
                titleEdit: "",
                totp: ""
            },
            user: {
                copydia: {
                    rolle: "",
                    title: "Copier l'utilisateur"
                },
                error: {
                    deleterolls: "",
                    invalidchars: "Le nom d'utilisateur ne peut contenir que des lettres (trémas compris), des chiffres, des points, des soulignements et des tirets.",
                    noBezId: "",
                    usernameexists: "Ce nom d'utilisateur est déjà utilisé.",
                    validemail: "Veuillez saisir une adresse électronique valable.",
                    validusername: "Veuillez saisir un nom d'utilisateur."
                },
                title: "Utilisateur",
                titleCreate: "",
                titleEdit: "Modifier l'utilisateur",
                usrCodeYubi: "Nom + mot de passe + authenticator/carte de code/Yubico",
                yubico: ""
            }
        },
        set: {
            amt: {
                delerr: "",
                title: "Fonctions"
            },
            einheit: {
                bezirk: "Circuit",
                distrikt: "District",
                strang: "Branche",
                title: "Unités"
            },
            felder: {
                specialHelperText: "Visible uniquement par les utilisateurs dotés d'un droit spécial.",
                title: "Champs locaux",
                titleCreate: "",
                titleEdit: ""
            },
            gemeinde: {
                title: "Communautés",
                titleCreate: "",
                titleEdit: "Modifier le communauté"
            },
            gruppe: {
                title: "Groupes"
            },
            mailhist: {
                privatepublic: "",
                title: ""
            },
            mailvor: {
                title: "Modèles de mail"
            },
            sperr: {
                chVersand: "Envoi en CH",
                kuw: "Magazine \"Kirche & Welt\"",
                title: "Liste de verrouillage"
            }
        }
    },
    glob: {
        choice: {
            hoch: "Portrait",
            quer: "Paysage"
        },
        choices: {
            all: "Tous",
            andere: "Autres",
            auswahl: "Sélection",
            datum: "Date",
            de: "Allemand",
            en: "Anglais",
            fr: "Français",
            geschieden: "Divorcé(e)",
            getrennt: "Séparé(e)",
            haushalt: "Ménage",
            haushaltmitvornamen: "Ménage avec prénoms des personnes",
            jahreslist: "Liste par année",
            janeinfeld: "",
            ledig: "Célibataire",
            maennlich: "Masculin",
            mehrzeilig: "Lignes multiples",
            monApr: "Avril",
            monAug: "Août",
            monDez: "Décembre",
            monFeb: "Février",
            monJan: "Janvier",
            monJul: "Juillet",
            monJun: "Juin",
            monMai: "Mai",
            monMar: "Mars",
            monNov: "Novembre",
            monOkt: "Octobre",
            monSep: "Septembre",
            monatsliste: "Liste par mois",
            no: "Non",
            onemonth: "",
            oneyear: "",
            person: "Personne",
            privateonly: "",
            publiconly: "",
            rundegeburtstage: "Anniversaires ronds (dizaines, dès 60 ans aussi unités de 5)",
            seniorenliste: "Liste des aînés (dès 65 ans unités de 5 uniquement, dès 80 ans tous)",
            sevendays: "",
            sinceyesterday: "",
            stAssoziert: "Membre associé (AT)",
            stAusgetreten: "Démission",
            stBekennend: "Membre confessant",
            stFreund: "Ami",
            stGast: "Membre hôte (AT)",
            stGetauft: "Membre baptisé",
            stGruppe: "Uniquement membre d'un groupe",
            stKind: "Enfant/adolescent",
            textfeld: "Champ de texte",
            today: "",
            umA4L: "A4 pour C5, fenêtre à gauche",
            umA4R: "A4 pour C5, fenêtre à droite",
            verheiratet: "Marié(e)",
            verwitwet: "Veuf/veuve",
            weiblich: "Féminin",
            werkWaehlen: "Sélectionner l'œuvre ",
            yes: "Oui"
        },
        close: "Fermer",
        columns: "",
        confirm: "",
        copy: "",
        day: {
            short: {
                fri: "",
                mon: "",
                sat: "",
                sun: "",
                thu: "",
                tue: "",
                wed: ""
            }
        },
        error: {
            noAccess: ""
        },
        errpassword: "",
        erruser: "",
        invalidtoken: "",
        move: "",
        onlysinglebez: "",
        otp: {
            invalid: ""
        },
        validatePhone: "",
        yubico: {
            invalid: "",
            wrongkey: ""
        }
    },
    ra: {
        action: {
            select_columns: ""
        },
        configurable: {
            customize: ""
        }
    },
    resources: {
        addBirthday: {
            fields: {
                month: "Mois",
                typ: "Type",
                year: "Année"
            }
        },
        addrAktion: {
            fields: {
                bemerkung: "Remarque",
                datum: "Date",
                freund: "",
                grund: "",
                id_bezirk: "Circuit",
                keineintrag: "Pas d\\'entrée dans les Historique / Livre de l'église / Communiqués",
                mitteilung: "",
                ort: "Localité",
                pfarrer: "Pasteur",
                verknuepft: "",
                vers: ""
            }
        },
        addrAmt: {
            fields: {
                add: "",
                id_person: "Personne"
            }
        },
        addrAmthist: {
            fields: {
                bis: "à / au",
                id_ch_funktion: "Fonction",
                id_person: "Personne",
                von: "De / du"
            }
        },
        addrFilter: {
            load: {
                fields: {
                    id_filter: "Filtre"
                }
            }
        },
        addrForm: {
            fields: {
                form: {
                    af_etbreite: "Largeur de l'étiquette",
                    af_etformat: "Format de l'étiquette",
                    af_ethaushalt: "Civilité",
                    af_ethoehe: "Hauteur de l'étiquette",
                    af_etlargefont: "Taille de police plus grande",
                    af_etlinks: "Marge à gauche",
                    af_etoben: "Marge en haut",
                    af_etzusatzfeld: "Champ supplémentaire",
                    af_exformat: "Format du fichier",
                    af_exhaushalt: "Civilité",
                    af_formallefelder: "Tous les champs",
                    af_glformat: "Format du fichier",
                    af_gljahr: "Année",
                    af_glmonat: "Mois",
                    af_glpformat: "Format de la page",
                    af_gltyp: "Type",
                    af_liformat: "Format de la page",
                    af_lilogo: "Image",
                    af_lilogow: "Largeur",
                    af_lilogox: "Retrait à droite",
                    af_linetz: "Quadrillage",
                    af_sort1: "",
                    af_sort2: "",
                    af_sort3: "",
                    af_umformat: "Format de l'enveloppe",
                    af_umhaushalt: "Civilité",
                    af_umlargefont: "Taille de police plus grande",
                    af_umlogo: "Image",
                    af_umlogow: "Largeur",
                    af_umlogox: "Retrait à gauche",
                    af_umlogoy: "Retrait à droite",
                    af_umzusatzfeld: "Champ supplémentaire"
                },
                id_ausgabetyp: "Type"
            }
        },
        addrPerson: {
            empty: "",
            fields: {
                GruppenKommaGetrennt: "",
                GruppenSpalten: "Groupes (colonnes)",
                KirchlicheHandlungen: "",
                Mitgliedernummer: "No de membre",
                VornamenAllerPersonen: "Prénoms de toutes les personnes",
                active: "Active/Inactive",
                aemter: "Fonctions",
                alter: "Âge",
                anrede: "Civilité",
                bemerkungen: "Remarques",
                beruf: "Profession",
                du: "Tu",
                einheit: "Unité",
                einheiten: "",
                email_g: "E-mail (deux)",
                email_p: "E-mail",
                fax_g: "Fax professionnel",
                fax_p: "",
                geboren: "Date de naissance",
                geschlecht: "Sexe",
                gruppenfunktion: "Fonction du groupe",
                gruppentext: "Groupes",
                handy: "Tél. portable",
                haushalt: "Ménage",
                haushaltanrede: "Nom du ménage",
                haushaltname: "Nom du ménage",
                haushaltname2: "Nom 2 (Ménage)",
                haushaltvorname: "Prénom (Ménage)",
                heimatort: "Lieu d'origine",
                homepage: "Site internet",
                idEinheit: "",
                id_gemeinde: "Communauté",
                id_gruppe: "Groupe",
                id_haushalt: "Ménage ID",
                id_status: "Statut",
                inaktiv: "",
                info: "",
                jsname: "Nom de flambeau",
                landkurz: "Pays",
                ledig: "Nom de célibataire",
                mitgliednr: "No de membre",
                mitgliedsbeitrag: "Cotisation",
                name: "Nom",
                ort: "Localité",
                person_id: "Personnes ID",
                plz: "Code postal",
                q: "",
                sperr: "",
                sprache: "Langue",
                strasse: "Rue N°",
                telefon_g: "Tél. professionnel",
                telefon_p: "",
                titel: "Titre",
                untersagung: "Interdiction",
                vbpknr: "VbPK-Nr",
                verknuepft: "Personnes ayant un lien",
                vorname: "Prénom",
                weiterevornamen: "Autres prénoms",
                zivilstand: "État civil",
                zusatz: "Complément"
            },
            invite: ""
        },
        authProfil: {
            fields: {
                email: "E-mail",
                lang: "Langue",
                passwort2: "",
                plz_ort: "",
                strasse: "Rue N°"
            }
        },
        authUser: {
            fields: {
                benutzername: "Nom d'utilisateur",
                email: "E-mail",
                id_bezirk: "Unité",
                id_einheit: "Unité",
                id_fw_gruppe: "Groupe d'utilisateurs",
                id_sland: "Pays",
                id_strang: "Branche",
                lang: "Langue",
                name: "Nom",
                passwort: "Créer un mot de passe et l'envoyer à l'utilisateur",
                plz_ort: "",
                q: "",
                readonly: "Afficher / imprimer uniquement",
                rollename: "Nom du rôle",
                sicherheit: "Niveau de sécurité",
                strasse: "Rue N°",
                vorname: "Prénom"
            }
        },
        setEinheit: {
            fields: {
                bezeichnung: "Désignation",
                id_admin: "",
                id_bezirkperson: "",
                id_emkbezirk: "",
                id_sland: "Pays",
                id_strang: "Branche",
                ityp: "Unité"
            }
        }
    },
    se: {
        allgemein: {
            Adresszusatz: "Complément d'adresse",
            Alter: "Âge",
            Amt: "Fonction",
            Ausgefuehrt: "Exécuté",
            Bezirk: "Circuit",
            Domain: "emk-web.ch",
            Einheit: "Unité",
            Geboren: "Né(e)",
            Land: "Pays",
            Mitteilung: "Message",
            Name: "Nom",
            Ort: "Localité",
            Personen: "Personnes",
            Plz: "Code postal",
            StrasseNr: "Rue N°",
            Vorname: "Prénom",
            Zuweisung: "Affectation"
        },
        spezial: {
            Aemterliste: "Liste des fonctions",
            Beerdigung: "Enterrement",
            FolgenderTextWurdeVersendet: "Le texte suivant a été envoyé",
            IhreAktionWurdeAusgefuehrt: "Votre action a été exécutée. Votre message a été envoyé à la personne responsable.",
            Pfarrer: "Pasteur",
            TextStreichung: "%s a été rayée du district qui s'en occupait. Comme vous indiquez qu'elle a un lien avec vous, elle a été transférée dans votre circuit.",
            TextUeberweisung: "Une personne a été transférée du circuit %s dans le circuit %s : %s"
        }
    }
}
export default langFR;