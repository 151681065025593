import React from 'react';
import { RecordContextProvider, useListContext, useUpdate } from 'react-admin';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const DragRow = ({record, rowIndex, children, ...rest }) => (
  <Draggable key={'drag-'+record.id} draggableId={'drag-'+record.id} index={rowIndex}>
    {(provided, snapshot) => (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}>
        <RecordContextProvider value={record}>
          {React.Children.map(children, (field, index) => (
            <div key={`${record.id}-${field.props.source}`} style={{ padding: grid, display: 'table-cell' }}>
              <>
              { index === 0 && record.lft === record.rgt-1 && <span style={{ fontSize: 'medium', marginTop: '9px', marginLeft: 1+record.lvl*1.3+'em'}} />  }
              { index === 0 && record.lft !== record.rgt-1 && <KeyboardArrowDownIcon style={{ fontSize: 'medium', marginBottom: '-.1em', marginLeft: record.lvl*1.3+'em' }} /> }
              {field}
              </>
            </div>
          ))}
        </RecordContextProvider>
      </div>
    )}
  </Draggable>
)

const HeadRow = ({ children, ...rest }) => (
  <div style={{ display: 'table-row' }}>
      {React.Children.map(children, (field, index) => { console.log(field.props.label || field.props.source); return (
        <div key={`${index}-head`} style={{ padding: grid, display: 'table-cell', background: "white", borderBottom: '1px solid rgba(0,0,0,0.14)',textTransform: 'capitalize' }}>
          <>
          { field.props.label || field.props.source }
          </>
        </div>
      )} )}
  </div>
)

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * .5,
  margin: `0 0 1px 0`,
  background: isDragging ? "lightgreen" : "white",
  display: 'table-row', 
  ...draggableStyle
});

const getListStyle = (isDraggingOver, snap) => {
  return ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 1, display: 'table', flexDirection: 'column', width: '100%'})
};

const DataGridDnd = ({ children, className, expand, hasBulkActions, hover, 
  resource, rowClick, rowStyle, isRowSelectable, ...props}) => {
    const { data, isLoading, refetch } = useListContext(props);
    const [update] = useUpdate();

    const getRow = (idx) => {
      if(!idx || typeof idx.index === 'undefined' || typeof data[idx.index] === 'undefined')return {id: 0};
      return data[idx.index]
    }
    
    const DragEnd = ({...props}) => {
      const src = getRow(props.source);
      var tgt = getRow(props.destination);
      var action = '';
      if(props.combine && props.combine.draggableId){
        tgt.id = props.combine.draggableId.slice(5);
        action = 'into'
      }
      else {
        action = props.source.index > props.destination.index?'before':'after';
      }
      update(
        resource+'/dndUpdate',
        { id: src.id, data: { tgt: tgt.id, action: action } },
        {
          onSuccess: () => {
            refetch();
          },
          onError: (error) => {
            console.error(error);
          },
        }
      )
    }

    if(isLoading)return null;
    return (
      <DragDropContext onDragEnd={DragEnd}>
        <Droppable droppableId="droppable" isCombineEnabled>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver, snapshot)}>
              <>
                <HeadRow children={children} />
                {data.map((record, rowIndex) => (
                  <DragRow record={record} rowIndex={rowIndex} key={'dragC-'+record.id} children={children} />
                ))}
                {provided.placeholder}
              </>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  };

export default DataGridDnd;
