const langEN =
{
    ew: {
        act: {
            bearbeiten: {
                button: "Edit",
                feld: "Field",
                start: "",
                titel: "",
                wert: "Value"
            },
            birthday: {
                exec: "to compile Birthday List",
                titel: "Birthday List",
                typ: {
                    j: "Annual List",
                    m: "Monthly List",
                    r: "Decadal / Milestone Birthdays (10, 20…..60, thereafter 65, 70, 75 so on & so forth)",
                    s: "Senior Citizens List (from 65, 70, 75, 80, 81,82 etc)"
                }
            },
            compare: {
                titel: ""
            },
            datenblatt: {
                button: "Data Sheet"
            },
            delverkn: {
                button: ""
            },
            dispverkn: {
                button: "Show links"
            },
            done: "",
            drucken: {
                button: "Print / Export",
                col: "Startcolumn",
                form: "Output Form",
                geburtsdatum: "Date of Birth",
                num: "Number of Labels per Address",
                numtext: "Print Same Label Multiple times",
                postext1: "",
                postext2: "",
                row: "Startrow",
                start: "",
                titelfeld: "Title"
            },
            email: {
                addr: "Recipient address",
                saved: "",
                vorlagen: "Open template"
            },
            geburtstag: {
                button: "Birthday List"
            },
            handlung: {
                bek: "Profession of Faith",
                button: "",
                ehe: "Wedding",
                "exec-bek": "",
                "exec-ehe": "",
                "exec-seg1": "",
                "exec-seg2": "",
                "exec-tauf1": "",
                "exec-tauf2": "",
                "exec-tod": "",
                seg1: "Children´s Blessing",
                seg2: "Blessing",
                tauf1: "Children´s Baptism",
                tauf2: "Adult Baptism",
                tod: "Funeral (Burial) / Death"
            },
            handlungen: {
                button: "Church rite"
            },
            history: {
                button: "History"
            },
            loeschen: {
                button: "Delete",
                titel: ""
            },
            loeschen2: {
                button: "",
                titel: ""
            },
            mail: {
                allAddr: "All addresses",
                anhaenge: "Attachement",
                betreff: "Subject",
                history: "History of communication",
                loadmail: "Open saved mail",
                loadtempl: "Open template",
                mailtitel: "Open saved mail",
                optionen: "Options",
                save: "Save mail",
                send: "",
                test: "",
                text: "Text",
                titel: "",
                vorlagentitel: "Templates and saved mails",
                vorlagetitel: ""
            },
            mitteilung: {
                button: "Message to Charge"
            },
            msgadm: {
                confirm: "",
                exec: "",
                subtitle: "",
                title: "Annoucement"
            },
            mutationen: {
                bemerkung: "Remark",
                button: "Change of Status",
                date: "Date",
                delete: "Delete",
                "exec-delete": "",
                "exec-quit": "",
                "exec-trans": "",
                freund: "",
                id_bezirk: "Charge",
                keineintrag: "No entry in History / Church book / Messages",
                quit: "Withdrawal",
                trans: "",
                verknuepft: ""
            },
            nachricht: {
                button: "Message",
                clipAddr: "",
                clipMail: "",
                send: "",
                sendapp: "Send mail from emk-web",
                sendbcc: "",
                sendto: ""
            },
            reaktivieren: {
                button: ""
            },
            sperren: {
                all: "Disable Person for both",
                button: "Lock",
                ch: "Disable Person for CH Versand",
                kuw: "disable Person for KuW",
                nall: "Enable Person for both",
                nch: "Enable Person for CH Versand",
                nkuw: "Enable Person for KuW"
            },
            target: {
                all: "",
                sel: "",
                titel: ""
            },
            titel: "",
            vergleichen: {
                button: "Compare Persons"
            },
            verkndisp: {
                text: "",
                text2: "Only records you are authorized for will be shown ",
                titel: "Links"
            },
            verknuepf: {
                done: "",
                exec: "Link Person",
                text: "Please select the Unit in which the person / people should be linked to",
                titel: "Link Person"
            },
            "verknüpfen": {
                button: "Link Person"
            },
            zueinheit: {
                button: "Go to record"
            }
        },
        addr: {
            acthist: {
                title: "History Actions"
            },
            amt: {
                title: "Offices"
            },
            amthist: {
                title: "History Offices",
                titleEdit: ""
            },
            filter: {
                aemter: "Offices",
                aktiv: "Active",
                alle: "All",
                allePers: "All Persons",
                allejs: "All Young Christian Communities",
                allewerke: "All Ministry Projects",
                amtstatus: "",
                eigene: "Only their own",
                einheit: "Unit",
                einheiten: "All Units",
                feld: "Field",
                geladen: "",
                gruppenfunktion: "Group Function",
                idEinheit: "",
                load: "",
                logik: "",
                name: "",
                nuraktiv: "Only Active Persons",
                nurinaktiv: "Only Non-Active Persons",
                save: "",
                title: "",
                vergleich: "",
                verknuepft: "Linked Persons",
                verknuepfte: "Only linked",
                wahl: "Select People Filter",
                wert: "Value"
            },
            form: {
                add: "Insert Field",
                title: "",
                titleCreate: "",
                titleEdit: "Edit Output Form"
            },
            haushalt: {
                select: {
                    title: "Assign Household"
                }
            },
            history: {
                title: ""
            },
            person: {
                MoeglicheVerknuepfungen: "Possible Links",
                Verknuepfen: "",
                aktionen: "Actions",
                erwfilter: "Extended Filter  ",
                title: "Persons",
                titleCreate: "",
                titleEdit: "Edit Person"
            },
            tab: {
                daten: "Data",
                emk: "EMK",
                gruppen: "Groups",
                gruppenhist: "",
                haushalt: "Household",
                kontakt: "Contact",
                person: "Person"
            }
        },
        auth: {
            profil: {
                addrTitle: "Address",
                langTitle: "Change language",
                pwdTitle: "Change Password",
                titleEdit: "",
                totp: ""
            },
            user: {
                copydia: {
                    rolle: "",
                    title: "Copy User"
                },
                error: {
                    deleterolls: "",
                    invalidchars: "User Name may only exist of letters, numbers, points, underline and hyphen",
                    noBezId: "",
                    usernameexists: "Username already exists",
                    validemail: "A valid E-Mail Address must be entered",
                    validusername: "A Username must be given / entered"
                },
                title: "User",
                titleCreate: "",
                titleEdit: "Edit User",
                usrCodeYubi: "Name + Password + Codecard/Yubico",
                yubico: ""
            }
        },
        set: {
            amt: {
                delerr: "",
                title: "Offices"
            },
            einheit: {
                bezirk: "Charge",
                distrikt: "District",
                strang: "Branch",
                title: "Units"
            },
            felder: {
                specialHelperText: "will only be seen by Users with Special Rights",
                title: "Local Fields",
                titleCreate: "",
                titleEdit: ""
            },
            gemeinde: {
                title: "Congregations",
                titleCreate: "",
                titleEdit: "Edit Parish / Congregation"
            },
            gruppe: {
                title: "Groups"
            },
            mailhist: {
                privatepublic: "",
                title: ""
            },
            mailvor: {
                title: "Mail templates"
            },
            sperr: {
                chVersand: "CH Versand",
                kuw: "K&W",
                title: "Revocation List"
            }
        }
    },
    glob: {
        choice: {
            hoch: "Portrait ",
            quer: "Landscape "
        },
        choices: {
            all: "All",
            andere: "Others",
            auswahl: "Choice",
            datum: "Date",
            de: "German",
            en: "English",
            fr: "French",
            geschieden: "Divorced",
            getrennt: "Separated",
            haushalt: "Household",
            haushaltmitvornamen: "Household with Given names of People",
            jahreslist: "Annual List",
            janeinfeld: "",
            ledig: "Single",
            maennlich: "Male",
            mehrzeilig: "Multi-line",
            monApr: "April",
            monAug: "August",
            monDez: "December",
            monFeb: "February",
            monJan: "January",
            monJul: "July",
            monJun: "June",
            monMai: "May",
            monMar: "March",
            monNov: "November",
            monOkt: "October",
            monSep: "September",
            monatsliste: "Monthly List",
            no: "No",
            onemonth: "",
            oneyear: "",
            person: "Person",
            privateonly: "",
            publiconly: "",
            rundegeburtstage: "Decadal / Milestone Birthdays (10, 20…..60, thereafter 65, 70, 75 so on & so forth)",
            seniorenliste: "Senior Citizens List (from 65, 70, 75, 80, 81,82 etc)",
            sevendays: "",
            sinceyesterday: "",
            stAssoziert: "Associate Member",
            stAusgetreten: "Withdrawn ",
            stBekennend: "Professing Member",
            stFreund: "Friend",
            stGast: "Guest Member",
            stGetauft: "Baptised Member",
            stGruppe: "Only Group Member",
            stKind: "Child / Teenager",
            textfeld: "Text Field",
            today: "",
            umA4L: "A4 for C5 Left",
            umA4R: "A4 for C5 Right",
            verheiratet: "Married",
            verwitwet: "Widowed",
            weiblich: "Female",
            werkWaehlen: "Selcet Ministry Project",
            yes: "Yes"
        },
        close: "Close",
        columns: "Spalten",
        confirm: "Bestätigen",
        copy: "Kopieren",
        day: {
            short: {
                fri: "Fr",
                mon: "Mo",
                sat: "Sa",
                sun: "So",
                thu: "Do",
                tue: "Di",
                wed: "Mi"
            }
        },
        error: {
            noAccess: "Kein Zugang"
        },
        errpassword: "",
        erruser: "",
        invalidtoken: "",
        move: "Verschieben",
        onlysinglebez: "",
        otp: {
            invalid: ""
        },
        validatePhone: "",
        yubico: {
            invalid: "",
            wrongkey: ""
        }
    },
    ra: {
        action: {
            select_columns: ""
        },
        configurable: {
            customize: ""
        }
    },
    resources: {
        addBirthday: {
            fields: {
                month: "Month",
                typ: "Type",
                year: "Year"
            }
        },
        addrAktion: {
            fields: {
                bemerkung: "Remark",
                datum: "Date",
                freund: "",
                grund: "",
                id_bezirk: "Charge",
                keineintrag: "No entry in History / Church book / Messages",
                mitteilung: "",
                ort: "City",
                pfarrer: "Pastor ",
                verknuepft: "",
                vers: ""
            }
        },
        addrAmt: {
            fields: {
                add: "",
                id_person: "Person"
            }
        },
        addrAmthist: {
            fields: {
                bis: "to",
                id_ch_funktion: "Office",
                id_person: "Person",
                von: "from"
            }
        },
        addrFilter: {
            load: {
                fields: {
                    id_filter: "Filter"
                }
            }
        },
        addrForm: {
            fields: {
                form: {
                    af_etbreite: "Label Width",
                    af_etformat: "Label Format",
                    af_ethaushalt: "Address",
                    af_ethoehe: "Label Height",
                    af_etlargefont: "Larger Font",
                    af_etlinks: "Page Boarder Left",
                    af_etoben: "Page Boarder Top",
                    af_etzusatzfeld: "Additional Field",
                    af_exformat: "File Format",
                    af_exhaushalt: "Address",
                    af_formallefelder: "All Fields",
                    af_glformat: "File Format",
                    af_gljahr: "Year",
                    af_glmonat: "Month",
                    af_glpformat: "Page format",
                    af_gltyp: "Type",
                    af_liformat: "Page format",
                    af_lilogo: "Photo",
                    af_lilogow: "Width",
                    af_lilogox: "Distance Top",
                    af_linetz: "Grid lines",
                    af_sort1: "",
                    af_sort2: "",
                    af_sort3: "",
                    af_umformat: "Envelope Format",
                    af_umhaushalt: "Address",
                    af_umlargefont: "Larger Font",
                    af_umlogo: "Photo",
                    af_umlogow: "Width",
                    af_umlogox: "Distance Left",
                    af_umlogoy: "Distance Top",
                    af_umzusatzfeld: "Additional Field"
                },
                id_ausgabetyp: "Type"
            }
        },
        addrPerson: {
            empty: "Noch keine Person erfasst",
            fields: {
                GruppenKommaGetrennt: "",
                GruppenSpalten: "Groups (in Columns)",
                KirchlicheHandlungen: "",
                Mitgliedernummer: "Membership Number",
                VornamenAllerPersonen: "Prename of all Persons",
                active: "Actif/inactif",
                aemter: "Offices",
                alter: "Age",
                anrede: "Salutation ",
                bemerkungen: "Remarks",
                beruf: "Occupation",
                du: "you",
                einheit: "Unit",
                einheiten: "",
                email_g: "E-Mail (Second / Alternate)",
                email_p: "E-Mail",
                fax_g: "Fax (Business)",
                fax_p: "",
                geboren: "Born",
                geschlecht: "Sex",
                gruppenfunktion: "Group Function",
                gruppentext: "Groups",
                handy: "Mobile Phone",
                haushalt: "Household",
                haushaltanrede: "Household Name",
                haushaltname: "Household Name",
                haushaltname2: "Salutation (household)",
                haushaltvorname: "Salutation (household)",
                heimatort: "Country of Origin",
                homepage: "Homepage",
                idEinheit: "",
                id_gemeinde: "Congregation",
                id_gruppe: "Group",
                id_haushalt: "Household ID",
                id_status: "Status",
                inaktiv: "",
                info: "",
                jsname: "JS-Name",
                landkurz: "Country",
                ledig: "Maiden Name / Unmarried Name",
                mitgliednr: "Membership Number",
                mitgliedsbeitrag: "Membership fee",
                name: "Surname",
                ort: "City",
                person_id: "Person ID",
                plz: "Postcode",
                q: "",
                sperr: "",
                sprache: "Language",
                strasse: "Street Number",
                telefon_g: "Telephone (Business)",
                telefon_p: "",
                titel: "Title",
                untersagung: "Prohibition order",
                vbpknr: "VbPK-Nr",
                verknuepft: "Linked Persons",
                vorname: "First Name",
                weiterevornamen: "Other First Names",
                zivilstand: "Marital Status",
                zusatz: "Additional"
            },
            invite: "Eine erste erstellen?"
        },
        authProfil: {
            fields: {
                email: "E-Mail",
                lang: "Language",
                passwort2: "",
                plz_ort: "",
                strasse: "Street Number"
            }
        },
        authUser: {
            fields: {
                benutzername: "User Name",
                email: "E-Mail",
                id_bezirk: "Unit",
                id_einheit: "Unit",
                id_fw_gruppe: "User Group",
                id_sland: "Country",
                id_strang: "Branch",
                lang: "Language",
                name: "Surname",
                passwort: "Create password and send to User",
                plz_ort: "",
                q: "",
                readonly: "Only Show / Print",
                rollename: "Role Name",
                sicherheit: "Security Level",
                strasse: "Street Number",
                vorname: "First Name"
            }
        },
        setEinheit: {
            fields: {
                bezeichnung: "Identifier ",
                id_admin: "",
                id_bezirkperson: "",
                id_emkbezirk: "",
                id_sland: "Country",
                id_strang: "Branch",
                ityp: "Unit"
            }
        }
    },
    se: {
        allgemein: {
            Adresszusatz: "Additional Address",
            Alter: "Age",
            Amt: "Office",
            Ausgefuehrt: "Executed ",
            Bezirk: "Charge",
            Domain: "emk-web.ch",
            Einheit: "Unit",
            Geboren: "Born",
            Land: "Country",
            Mitteilung: "Annoucement",
            Name: "Surname",
            Ort: "City",
            Personen: "Persons",
            Plz: "Postcode",
            StrasseNr: "Street Number",
            Vorname: "First Name",
            Zuweisung: "Assignment "
        },
        spezial: {
            Aemterliste: "List of Officers",
            Beerdigung: "Burial",
            FolgenderTextWurdeVersendet: "the following text has been conveyed",
            IhreAktionWurdeAusgefuehrt: "Your action has been processed. A message to the Person in Charge has been sent. ",
            Pfarrer: "Pastor ",
            TextStreichung: "%s has been deleted by the owning Charge. As this record has been linked to your database, it is now assigned to your database.",
            TextUeberweisung: "A Person has been transferred from Charge %s to Charge %s "
        }
    }
}
export default langEN;