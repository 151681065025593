import React, { useState, useEffect } from 'react';
import { useListContext, useCreateContext, useTranslate, useNotify,
  CreateBase, SimpleForm, Toolbar, SaveButton, TextInput } from 'react-admin';
import dataProvider from '../lib/dataProvider';
import { getInfoValue } from '../lib/globalData';
import moment from 'moment';
import { DialogContent } from '@mui/material';

const ActMesAdm = ({count, manyIds, closeDialog }) => {
  const ta = useTranslate();      
  const notify = useNotify();
  const [text, setText] = useState('hallo');
 
  const MessageCreateForm = ({count, manyIds}) => {
    const { filterValues } = useListContext();
    const { save } = useCreateContext();
    
    const transformAction = (filterValues) => data => ({
      ...data, filter: filterValues
    });
    
    const MessageToolbar = ({count}) => {
      const ta = useTranslate();      
      return(
        <Toolbar>
            <SaveButton type="button" label={ta('ew.act.msgadm.exec', {smart_count:count})} transform={transformAction(filterValues)} />
        </Toolbar>
      );
    };
    
    return (
      <SimpleForm toolbar={<MessageToolbar count={count} />}>
        <TextInput source="text" defaultValue={text} multiline minRows={3} fullWidth helperText={false} style={{minWidth:'350px'}} />
      </SimpleForm>
    )  
  }
  
  const onSuccess = (data) => {
    notify(data.message);
    if (data.id > 0) {
      closeDialog();
    }
  };

  useEffect(() => {
    dataProvider.getList('addrPerson', { filter: {id: String(manyIds).split(',')}, sort: { }, pagination: { page: 1, perPage: 999 },})
    .then(response => {
      if (response.data) {
        let text ='';
        response.data.forEach(r => {
          text += 'Betrifft: '+r.vorname+' '+r.name+', '+r.haushalt2+(r.geboren?'\nGeboren: '+ moment(r.geboren).format('D.M.YYYY'):'')+'\n\n';
        });

        text += '\n'+getInfoValue('vorname')+' '+getInfoValue('name')+', '+getInfoValue('email');
        if(getInfoValue('level')<=10) text += ', Hauptadministrator emk-web';
        setText(text);
      }
    });
    }, [manyIds]);

  return (
    <DialogContent>
      <div style={{fontWeight:'bold', fontSize:'1.2rem'}}>{ta('ew.act.msgadm.title')}</div>
      <CreateBase resource="addrAktion" mutationOptions={{ onSuccess, meta : {id: manyIds, sub: 'msgadm'}  }} >
        <MessageCreateForm count={count} manyIds={manyIds} />
      </CreateBase>
    </DialogContent>
  );
}

export default ActMesAdm
