import { SelectInput } from 'react-admin';

const SelectSimpleInput = (props) => {
  var choices = [];
  if (props.simpchoices) {
    props.simpchoices.split(/[;,]/).forEach((c)=>{
      if (c.includes('|')) {
        const w = c.split('|');
        const num = parseInt(w[0]);
        if (isNaN(num)) {
          choices.push({id:w[0], name:w[1]});
        }
        else {
          choices.push({id:num, name:w[1]});
        }
      }
      else {
        choices.push({id:c, name:c});
      }
    })
    props ={...props, choices:choices };
  }
  props ={...props, choices:choices };
  return (
    <SelectInput {...props} />
  )
}

export default SelectSimpleInput;
