import React, { useState, useEffect } from 'react';
import { useDataProvider, useUpdate, useRefresh, useNotify, useListContext, useTranslate  } from 'react-admin';
import { DialogTitle, DialogContent, Stack, TextField, Button, CircularProgress } from '@mui/material';
import ControlledAutoComplete from '../lib/ControlledAutoComplete';

const ActEdit = (props) => {
  const {count, manyIds, closeDialog} = props;
  const ta = useTranslate();
  
  const dataProvider = useDataProvider();
  const [felder, setFelder] = useState([{id:0,bezeichnung:'--'}]);
  const [feld, setFeld] = useState(0);
  const [wert, setWert] = useState('');
  useEffect(() => {
    dataProvider.getList('setFelder/filter', { id: 0, filter: { }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(response => {
        if (response.data) {
          setFelder([{id:0,bezeichnung:'--'}, ...response.data]);
        }
      });
  }, [dataProvider]);
  
  const changeFeld = (ev, nv) => {
    setFeld(nv.id);
  }
  const changeWert = (ev) => {
    setWert(ev.target.value);
  }
  const refresh = useRefresh();
  const notify = useNotify();
  const { filterValues } = useListContext();
  const [update, { isLoading }] = useUpdate(
      'addrPerson/multiset',
      { id: manyIds, data: {feld, wert, filter:filterValues} },
      {
          mutationMode:'pessimistic',
          onSuccess: () => {
            closeDialog();
            refresh();
          },
          onError: (error) => {
            console.log('error')
            notify(`Fehler beim speichern: ${error.message}`, { type: 'warning' });
          },
      }
  );
  
  return (
    <>
      <DialogTitle>{ta('ew.act.bearbeiten.titel', {smart_count: count})}</DialogTitle>
      <DialogContent>
        <Stack>
          <ControlledAutoComplete options={felder} optionLabel="bezeichnung" label="ew.act.bearbeiten.feld" onChange={changeFeld} value={feld} /> 
          <TextField label="ew.act.bearbeiten.wert" value={wert} onChange={changeWert} />
          <Button variant="contained" sx={{marginTop:'12px'}} onClick={()=>update()} disabled={isLoading} >{ta('ew.act.bearbeiten.start', {smart_count: count})}</Button>
          {isLoading && <CircularProgress sx={{display:'block', margin:'16px auto 0 auto'}} />}
        </Stack>
      </DialogContent>
    </>
  );
}
  
export default ActEdit

