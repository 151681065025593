import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { apiPath } from '../lib/isTest';
import { getGlobalData } from '../lib/globalData';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const ListForm = (props) => {
  const dataProvider = useDataProvider();
  const [laender, setLaender] = useState([]);
  const [strang, setStrang] = useState([]);
  const [felder, setFelder] = useState([]);
  const [id_land, setId_land] = useState(0);
  const [id_strang1, setId_strang1] = useState(0);
  const [id_strang2, setId_strang2] = useState(0);
  const [id_feld, setFeld] = useState(0);
  const [kontonummer, setKontonummer] = useState('');
  const [versandnummer, setVersandnummer] = useState('');
  const [mitteilung, setMitteilung] = useState('');
  const [noSperr, setNoSperr] = useState(false);
  
  
  useEffect(() => {
    dataProvider.getList('genBezirk', { id: 0, filter: { land:true }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(res => { if (res.data) { setLaender([{id:0,bezeichnung:''}, ...res.data]); } });
    dataProvider.getList('setFelder', { id: 0, filter: { }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(res => { if (res.data) { setFelder([{id:0,bezeichnung:''}, ...res.data]); } });
    }, [dataProvider]
  );
  useEffect(() => {
    dataProvider.getList('genBezirk', { id: 0, filter: { strang:true, id_sland:id_land }, sort: { }, pagination: { page: 1, perPage: 999 },})
      .then(res => { if (res.data) { setStrang([{id:0,bezeichnung:''}, ...res.data]); } });
    }, [dataProvider, id_land]
  );
  
  const token = getGlobalData('token');
  return (
  <>
    <form method="post" action={apiPath+"genVersand"} target="_blank">
      <h2>Landesweiter Versand</h2>
      <Stack sx={{maxWidth:"300px"}}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Land</InputLabel>        
          <Select value={id_land} onChange={(ev)=>setId_land(ev.target.value)}>
        {laender.map((it)=><MenuItem value={it.id}>{it.bezeichnung}</MenuItem>)}
          </Select>
        </FormControl>  
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Basisstrang</InputLabel>        
          <Select value={id_strang1} onChange={(ev)=>setId_strang1(ev.target.value)}>
          {strang.map((it)=><MenuItem value={it.id}>{it.bezeichnung}</MenuItem>)}
          </Select>
        </FormControl>  
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Zusatzstrang</InputLabel>        
          <Select value={id_strang2} onChange={(ev)=>setId_strang2(ev.target.value)} label="Zusatzstrang" >
        {strang.map((it)=><MenuItem value={it.id}>{it.bezeichnung}</MenuItem>)}
          </Select>
        </FormControl>  
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Feld mit zusätzl. ESR Nummer</InputLabel>        
          <Select value={id_feld} onChange={(ev)=>setFeld(ev.target.value)}  >
        {felder.map((it)=><MenuItem value={it.id}>{it.bezeichnung}</MenuItem>)}
          </Select>
        </FormControl>  
        <TextField label="Kontonummer" value={kontonummer} onChange={(ev)=>setKontonummer(ev.target.value)} variant="filled" size="small"/>
        <TextField label="Versandnummer" value={versandnummer} onChange={(ev)=>setVersandnummer(ev.target.value)} variant="filled" size="small"/>
        <TextField label="Mitteilung" value={mitteilung} onChange={(ev)=>setMitteilung(ev.target.value)} variant="filled" size="small"/>
        <FormControlLabel control={<Switch defaultChecked checked={noSperr} onChange={(ev)=>setNoSperr(ev.target.checked)} />} label="Sperrliste nicht berücksichtigen" />
        
        <input type="hidden" name="token" value={token} />
        <input name="id_land" value={id_land} type="hidden" />
        <input name="id_strang1" value={id_strang1} type="hidden" />
        <input name="id_strang2" value={id_strang2} type="hidden" />
        <input name="id_feld" value={id_feld} type="hidden" />
        <input name="kontonummer" value={kontonummer} type="hidden" />
        <input name="versandnummer" value={versandnummer} type="hidden" />
        <input name="mitteilung" value={mitteilung} type="hidden" />
        <input name="noSperr" value={noSperr?1:0} type="hidden" />
        <Button type="submit" variant="contained">Liste erstellen</Button>
      </Stack>
    </form>
  </>
  )
}

const genVersand = {
  list: (props) => (
    <ListForm {...props} exporter={false} />
  ),
}
export default genVersand
