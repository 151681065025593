import React from 'react';
import { SaveButton, Toolbar, DeleteButton, useRedirect, useEditContext, useCreateContext, Button } from 'react-admin';
import BackspaceIcon from '@mui/icons-material/Backspace';

const CloseToolbar = (props) => {
  const redirect = useRedirect();
  let { resource } = useEditContext();
  const createcontext = useCreateContext();
  if(!resource)resource = createcontext.resource;

  const clickBack = () => {
    redirect('list', resource);
  }

  return(
    <Toolbar>
        <SaveButton label="Save" />
        <Button label="ra.action.back" onClick={clickBack} variant="contained" size="medium" sx={{marginLeft:'1em'}}>
          <BackspaceIcon />
        </Button>
      {!props.noDelete &&
        <DeleteButton style={{marginLeft: 'auto'}} mutationMode="pessimistic" />
      }
    </Toolbar>
  );
};

export default CloseToolbar;
